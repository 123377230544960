import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import {
    IUserChangePasswordPayload,
    IUserChangePasswordResponse,
    IUserForgetPasswordPayload,
    IUserLoginPayload,
    IUserLoginResponse,
    IUserRefreshResponse,
    IUserResetPasswordPayload,
    IUserResetPasswordResponse,
} from "@/api/types/auth";
import { IAxiosResponse } from "@/types/axios-response";

export const authService = {
    login(data: IUserLoginPayload) {
        return AxiosInstance.post<IAxiosResponse<IUserLoginResponse>>(API_ROUTES.AUTH.LOGIN, data);
    },
    changePassword(data: IUserChangePasswordPayload) {
        return AxiosInstance.put<IAxiosResponse<IUserChangePasswordResponse>>(API_ROUTES.AUTH.CHANGE_PASSWORD, data);
    },
    resetPassword(data: IUserResetPasswordPayload) {
        return AxiosInstance.put<IAxiosResponse<IUserResetPasswordResponse>>(API_ROUTES.AUTH.RESET_PASSWORD, data);
    },
    forgetPassword(data: IUserForgetPasswordPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.AUTH.FORGET_PASSWORD, data);
    },
    refresh() {
        return AxiosInstance.get<IAxiosResponse<IUserRefreshResponse>>(API_ROUTES.AUTH.REFRESH);
    },
    permissions() {
        return AxiosInstance.get<IAxiosResponse<string[]>>(API_ROUTES.AUTH.PERMISSIONS);
    },
};
