import { styled } from "@mui/material";

export const SeasonCardStyle = styled("div")(
    ({ theme }) => `
   
    padding: 5px 10px;
    border-radius: 16px;
    box-sizing: border-box;
    background: ${theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800]};

    span.value {
        font-size: 16px;
        font-weight: bold;
        margin-left: 10px
    }

    span.label {
        font-size: 12px;
    }
`
);

export const ContentShowStyledWrapper = styled("div")(
    ({ theme }) => `
    padding-bottom: ${theme.spacing(15)};

    .show-sessions {
        width: 100%;
        margin: 0 auto;
        max-width: 1010px;
        margin-top: 1em;
    }
`
);

export const ContentShowStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    position: relative;
    border-radius: ${theme.spacing(3)};
    overflow: hidden;
    padding: ${theme.spacing(2)};
    max-width: 1000px;
    margin: 0 auto;
`
);

export const ContentShowSidebarStyled = styled("div")(
    ({ theme }) => `
    flex: 0 0 240px;
    width: 240px;
    position: relative;
    z-index: 100;
`
);
export const ContentShowImageStyled = styled("div")(
    ({ theme }) => `
    aspect-ratio: 1/1.333333;
    height: 330px;
    width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${theme.spacing(2)}
    }
`
);
export const ContentShowImageLandscapeStyled = styled("div")(
    ({ theme }) => `
    aspect-ratio: 1.333333/1;
    height: 125px;
    width: 100%;
    margin-top: ${theme.spacing(2)};
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${theme.spacing(2)}
    }
`
);

export const ContentShowVideoButtonStyled = styled("div")(
    ({ theme }) => `
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    line-height: 40px;
    padding: 0 ${theme.spacing(2)};
    background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
    margin-top: ${theme.spacing(2)};
    border-radius: 20px;
    transition: 0.15s;
    font-weight: 500;
    &:hover {
        background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.15)"};
    }
    &:active {
        background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.3)"};
    }
`
);

export const ContentShowContentStyled = styled("div")(
    ({ theme }) => `
    flex: 0 0 calc(100% - 240px);
    width: calc(100% - 240px);
    padding-left: ${theme.spacing(4)};
    position: relative;
    z-index: 100;
`
);

export const ContentShowContentTitleStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    align-items: center;
    
    h2 {
        font-size: 28px;
        display: flex;
        align-items: center;
        
    }
    h4 {
        margin-left: auto;
        direction: rtl;
        text-align: right;
    }
`
);

export const ContentShowContentTitleBadgesStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    margin-left: ${theme.spacing(2)};
    span {
        background-color: #0F62F3;
        color: #fff;
        padding: 0 ${theme.spacing(1)};
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        border-radius: 9px;
        &.imdb {
            color: #000;
            background-color: #f5b710;
        }
        &.text {
            color: ${theme.palette.mode === "dark" ? "#fff" : "#000"};
            background-color: unset;
            padding: 0;
        }
    }
`
);

export const ContentShowContentDescriptionStyled = styled("div")(
    ({ theme }) => `
    border-top: 1px solid ${theme.palette.mode === "dark" ? "#666" : "#ccc"};
    p {
        &:last-of-type {
            direction: rtl;
            text-align: right;
        }
    }
`
);
export const ContentShowContentTagsStyled = styled("div")(
    ({ theme }) => `
    border-top: 1px solid ${theme.palette.mode === "dark" ? "#666" : "#ccc"};
    padding: ${theme.spacing(2)} 0;
    display: flex;
    gap: ${theme.spacing(1)};

    > span {
        font-weight: 600;
    }
    div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: ${theme.spacing(1)};
        
        span {
            background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
            padding: 0 ${theme.spacing(2)};
            line-height: 28px;
            border-radius: 14px;
            font-weight: 500;
        }
    }
`
);
export const ContentShowContentGenresStyled = styled("div")(
    ({ theme }) => `
    border-top: 1px solid ${theme.palette.mode === "dark" ? "#666" : "#ccc"};
    padding: ${theme.spacing(2)} 0;
    display: flex;
    gap: ${theme.spacing(1)};

    > span {
        font-weight: 600;
    }
    div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: ${theme.spacing(1)};
        
        span {
            background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
            padding: 0 ${theme.spacing(2)};
            line-height: 28px;
            border-radius: 14px;
            font-weight: 500;
        }
    }
`
);

export const ContentShowBackgroundStyled = styled("div")(
    ({ theme }) => `
    position: absolute;
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: -5px;
    z-index: 10;
    opacity: 0.15;
    filter: blur(5px);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`
);
