import { styled } from "@mui/material";

export const MainWrapperStyled = styled("main")(
    ({ theme }) => `
    padding: calc(100px + ${theme.spacing(2)}) ${theme.spacing(4)} ${theme.spacing(3)} calc(300px + ${theme.spacing(8)});
    height: 100vh;
    transition: 0.15s;
    &.minimizeMenu {
        padding-left: calc(80px + ${theme.spacing(8)})
    }
`
);

export const MainContentWrapperStyled = styled("section")(
    ({ theme }) => `
    height: 100%;
`
);

export const ContentWrapperStyled = styled("div")(
    ({ theme }) => `
    z-index: 50;
    height: 100%;
`
);
