import { useState } from "react";
import { useSnackbar } from "notistack";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // DeleteOutlineOutlined
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import ConfirmationModal from "@/components/app/modalVariants/confirmation";
import { ICountry } from "@/api/types/countries";
import { useDeleteCountry } from "@/api/hooks/useCountries";

export default function ShowCountriesTable({ list }: { list: ICountry[] }) {
    const [confirmModalId, setConfirmModal] = useState<number | null>(null);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: DeleteCountry, isPending: DeleteLoading } = useDeleteCountry();

    const handleDetails = (id: number) => navigate(ROUTE_CONSTANTS.SETTINGS.COUNTRIES.CONTENT.DETAILS.ROOT(id).ABSOLUTE);

    // const handleDelete = (id: number) => setConfirmModal(id);

    const handleClose = () => setConfirmModal(null);

    const handleConfirm = () => {
        if (confirmModalId) {
            DeleteCountry(confirmModalId, {
                onSuccess: (result) => {
                    handleClose();
                    enqueueSnackbar(result.meta.message, { variant: "success" });
                },
            });
        }
    };

    return (
        <TableContainer className="country-table-container">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Flag</TableCell>
                        <TableCell />
                        <TableCell>Title</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>currency ISO</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Updated</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((country) => {
                        const { id, title, currency, currencyIso, flagUrl, flag, createdAt, updatedAt } = country;

                        return (
                            <TableRow key={id}>
                                <TableCell>
                                    <img alt="flag" className="flag" src={flagUrl} style={{ height: "30px" }} />
                                </TableCell>
                                <TableCell>{flag}</TableCell>
                                <TableCell>{title}</TableCell>
                                <TableCell>{currency}</TableCell>
                                <TableCell>{currencyIso}</TableCell>
                                <TableCell>{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</TableCell>
                                <TableCell>{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <IconButton aria-label="edit" onClick={() => handleDetails(id)}>
                                            <ArrowForwardIcon />
                                        </IconButton>
                                        {/* <IconButton disabled aria-label="edit" onClick={() => handleDelete(id)}>
                                            <DeleteOutlineOutlined />
                                        </IconButton> */}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>

            <ConfirmationModal
                description="Are you sure to delete this country ?"
                handleClose={handleClose}
                loading={DeleteLoading}
                open={Boolean(confirmModalId)}
                onConfirm={handleConfirm}
            />
        </TableContainer>
    );
}
