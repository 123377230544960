import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    flex-direction: column;
    width: 100%;

    .plans-header {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1em;

        .main-title {
            font-weight: bold;
            font-size: 2em;
        }

        .sub-title {
            font-weight: bold;
            font-size: 1em;
        }
    }
    
    .create-plan-actions  {
        padding: 1em 0;

        .delete-btn {
            margin-inline: 10px;
        }
    }
`;
export const TableOfContentStyles = styled("div")(
    ({ theme }) => `
    max-width: 700px;
    width: 100%;
    margin: 2em auto;
    border: 1px solid #ccc;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-radius: ${theme.spacing(2)};
    > div {
        display: flex;
        align-items: center;
        line-height: 40px;
        border-bottom: 1px solid #ccc;
        padding: ${theme.spacing(1)} 0;

        &:last-child {
            border-bottom: unset
        }

        span.label {
            text-align: right;
            margin: 0;
            font-weight: 600;
            padding-right: ${theme.spacing(2)};
            width: 33.3333%;
            flex: 0 0 33.3333%;
            justify-content: flex-end;
            border-right: 1px solid #ccc;
        }

        > span {
            padding-left: ${theme.spacing(2)};
            width: 66.6666%;
            flex: 0 0 66.6666%;
            align-items: center;
            justify-content:center;

            img {
                display: inline-flex;
                width: 30px;
                height: 30px;
            }
        }
    }
`
);
