import ErrorPage from "@/pages/Error/Error";
import { Button } from "@mui/material";
import { Component, ErrorInfo, ReactNode } from "react";
import { Link } from "react-router-dom";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // Log the error to an error tracking service if needed
        console.error("Error caught by error boundary:", error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return (
                <ErrorPage
                    status="error"
                    title="Something went wrong"
                    subTitle="Please try again later or contact support if the issue persists."
                    extra={
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <Button variant="contained" color="primary">
                                Back Home
                            </Button>
                        </Link>
                    }
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
