import { ITableParams, ITableResponse } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { avatarService } from "@/api/services/avatar";
import { Button, Grid, Pagination } from "@mui/material";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IAvatarListResponse } from "@/api/types/avatar";
import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getAxiosError } from "@/utils/get-axios-error";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import GridCard from "@/components/kit/GridCard";
import { AvatarDeleteModalStyled, AvatarListGridStyled, AvatarListPaginationStyled, AvatarListStyled } from "./styled";
import InitLoading from "@/components/app/Loading/InitLoading";
import { GRID_PER_PAGE } from "@/constants/grid-view-configs";
import { routesByEntity } from "@/constants/routes-by-entity";

const AvatarList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ITableResponse<IAvatarListResponse>>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const deleteAccess = checkPermissionAccess(PERMISSIONS.AVATAR_DELETE);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await avatarService.list({ ...queryParams, take: GRID_PER_PAGE });
            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    }, [queryParams]);

    const handleDelete = (id: number) => {
        const handleAction = async () => {
            try {
                setLoading(true);
                const response = await avatarService.delete(id);
                if (response.data.data.assignedTo?.length) {
                    setTimeout(() => {
                        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                            header: "Warning: Avatar in use!",
                            text: (
                                <AvatarDeleteModalStyled>
                                    <p>
                                        You are about to delete this avatar. However, this avatar is currently being used by the following items. If you wish to
                                        delete it, you must first unassign the avatar from the content.
                                    </p>
                                    <ul>
                                        {response.data.data.assignedTo?.map((item, index) => (
                                            <li key={index}>
                                                <Link to={routesByEntity[item.entity](item.id)} target="_blank">
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </AvatarDeleteModalStyled>
                            ),
                            cancelButtonText: "Close",
                            disableSubmitButton: true,
                        });
                    }, 300);
                } else {
                    enqueueSnackbar(`avatar deleted successfully`, { variant: "success" });
                    setData((prev) => (prev ? { ...prev, items: prev.items.filter((item) => item.id !== id) } : prev));
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";
                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: "Delete Confirmation",
            text: "Are you sure to delete this avatar?",
            handleAction,
        });
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: GRID_PER_PAGE, skip: (page - 1) * GRID_PER_PAGE }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading && !data) return <InitLoading />;

    return (
        <>
            <DashboardHeader
                title="Avatar List"
                titleSuffix={
                    <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.AVATARS.ADD_NEW.ROOT.ABSOLUTE} color="primary" variant="contained">
                        Add New Avatar
                    </Button>
                }
            />
            <DashboardContent>
                <AvatarListStyled>
                    <AvatarListGridStyled loading={loading}>
                        <Grid container spacing={4}>
                            {data?.items.map((item) => (
                                <Grid item xs={6} md={3} lg={2} key={item.id}>
                                    <GridCard
                                        image={item.url}
                                        title={item.title?.length ? item.title : item.mediaId}
                                        actions={<>{deleteAccess ? <DeleteIcon onClick={() => handleDelete(item.id)} color="inherit" /> : null}</>}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </AvatarListGridStyled>
                    {data?.pagination && data.pagination?.totalItems > GRID_PER_PAGE ? (
                        <AvatarListPaginationStyled>
                            <Pagination
                                disabled={loading}
                                count={data.pagination.totalPages}
                                page={data.pagination.currentPage}
                                onChange={(e, page) => handlePaginationModelChange(page)}
                            />
                        </AvatarListPaginationStyled>
                    ) : null}
                </AvatarListStyled>
            </DashboardContent>
        </>
    );
};

export default AvatarList;
