import { PaginatedResponse } from "@/types/axios-response";
import AxiosInstance from "@/api";
import { IBaseDataItem, IListInfoItem } from "@/api/types/base-data";
import { API_ROUTES } from "@/constants/api-routes";

export const baseService = {
    getListInfos() {
        return AxiosInstance.post<PaginatedResponse<IListInfoItem>>(API_ROUTES.BASE_DATA.LIST_INFO, {
            skip: 0,
            take: 1000,
        });
    },

    getDynamicBaseData(url: string) {
        return AxiosInstance.post<PaginatedResponse<IBaseDataItem>>(`${url}`, {
            skip: 0,
            take: 1000,
        });
    },

    getListInfoByEntity(listName: string) {
        return AxiosInstance.get<PaginatedResponse<IListInfoItem>>(API_ROUTES.BASE_DATA.SHOW(listName));
    },
};
