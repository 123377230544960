import { Button, Grid, Pagination } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

import { ImageDeleteModalStyled } from "../Image/styled";

import { VideoListGridStyled, VideoListPaginationStyled, VideoListStyled } from "./styled";

import { ITableParams, ITableResponse } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { videoService } from "@/api/services/video";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IVideoListResponse } from "@/api/types/video";
import { getAxiosError } from "@/utils/get-axios-error";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import { GRID_PER_PAGE } from "@/constants/grid-view-configs";
import InitLoading from "@/components/app/Loading/InitLoading";
import GridCard from "@/components/kit/GridCard";
import { routesByEntity } from "@/constants/routes-by-entity";

const VideoList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ITableResponse<IVideoListResponse>>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const showAccess = checkPermissionAccess(PERMISSIONS.VIDEO_DETAIL);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.VIDEO_DELETE);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await videoService.list({ ...queryParams, take: GRID_PER_PAGE });

            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    }, [queryParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDelete = (id: number) => {
        const handleAction = async () => {
            try {
                setLoading(true);
                const response = await videoService.delete(id);

                if (response.data.data.assignedTo?.length) {
                    setTimeout(() => {
                        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                            header: "Warning: Video in use!",
                            text: (
                                <ImageDeleteModalStyled>
                                    <p>
                                        You are about to delete this video. However, this video is currently being used by
                                        the following items. If you wish to delete it, you must first unassign the video from
                                        the content.
                                    </p>
                                    <ul>
                                        {response.data.data.assignedTo?.map((item, index) => (
                                            <li key={index}>
                                                <Link target="_blank" to={routesByEntity[item.entity](item.id)}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </ImageDeleteModalStyled>
                            ),
                            cancelButtonText: "Close",
                            disableSubmitButton: true,
                        });
                    }, 300);
                } else {
                    enqueueSnackbar(`Image deleted successfully`, { variant: "success" });
                    setData((prev) => (prev ? { ...prev, items: prev.items.filter((item) => item.id !== id) } : prev));
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";

                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: "Delete Confirmation",
            text: "Are you sure to delete this video?",
            handleAction,
        });
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: GRID_PER_PAGE, skip: (page - 1) * GRID_PER_PAGE }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading && !data) return <InitLoading />;

    return (
        <>
            <DashboardHeader
                title="Video List"
                titleSuffix={
                    <Button
                        color="primary"
                        component={Link}
                        to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ADD_NEW.ROOT.ABSOLUTE}
                        variant="contained"
                    >
                        Add New Video
                    </Button>
                }
            />
            <DashboardContent>
                <VideoListStyled>
                    <VideoListGridStyled loading={loading}>
                        <Grid container spacing={4}>
                            {data?.items.map((item) => (
                                <Grid key={item.id} item lg={2} md={3} xs={6}>
                                    <GridCard
                                        actions={
                                            <>
                                                {showAccess ? (
                                                    <Link
                                                        to={
                                                            ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.SHOW.SHOW_BY_ID(item.id)
                                                                .ABSOLUTE
                                                        }
                                                    >
                                                        <ArrowForwardIcon color="inherit" />
                                                    </Link>
                                                ) : null}
                                                {deleteAccess ? (
                                                    <DeleteIcon color="inherit" onClick={() => handleDelete(item.id)} />
                                                ) : null}
                                            </>
                                        }
                                        image={item.thumbnail?.url}
                                        title={item.title}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </VideoListGridStyled>
                    {data?.pagination && data.pagination?.totalItems > GRID_PER_PAGE ? (
                        <VideoListPaginationStyled>
                            <Pagination
                                count={data.pagination.totalPages}
                                disabled={loading}
                                page={data.pagination.currentPage}
                                onChange={(e, page) => handlePaginationModelChange(page)}
                            />
                        </VideoListPaginationStyled>
                    ) : null}
                </VideoListStyled>
            </DashboardContent>
        </>
    );
};

export default VideoList;
