import { styled } from "@mui/material";
import { grey } from "@mui/material/colors";

export default styled("div")(
    ({ theme }) => `

    .center {
        max-width:600px;
        width: 100%;
        margin:0 auto;

        .paper {
            padding: ${theme.spacing(2)};

          
        }
    }
`
);

export const CreatePublisherStyled = styled("div")(
    ({ theme }) => `

    .title {
        font-weight: bold;
        border-bottom:1px solid ${(theme.palette, grey[200])}
    }

    .add-box {
        margin: 1em 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        .success {
            margin-inline: 5px;
        }
    }
`
);

export const ShowPublishersStyled = styled("div")(
    ({ theme }) => `
    .title {
        margin-bottom: 1em;
        font-weight: bold;
        border-bottom:1px solid ${(theme.palette, grey[200])}
    }

    .no-items-to-show {
        margin: 1em 0;
    }

    .publisher-list {
        .publisher-item {
            width: 100%;
            display: flex;
            align-items:center;
            justify-content: space-between;
            margin-bottom: 10px;
            padding: 5px 15px;
            border-radius: ${theme.shape.borderRadius}px;
            background: ${theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800]};
            border-left: 5px solid ${theme.palette.primary.main};
        }
    }
`
);
