import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TableCell, TableRow } from "@mui/material";

import headCells from "./tableHeadCells";
import { Data, EnhancedTableProps } from "./types";

export default function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead component="div">
            <TableRow component="div">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="center"
                        component="div"
                        padding="normal"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            component="div"
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align="center" component="div" padding="normal" sortDirection={false}>
                    Actions
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
