import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    flex-direction: column;
    width: 100%; padding-bottom: 2em;

    .plans-header {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1em;

        .main-title {
            font-weight: bold;
            font-size: 2em;
        }

        .sub-title {
            font-weight: bold;
            font-size: 1em;
        }
    }

    .show-plan-list {
        width: 100%;
        margin-top: 1em;
        display: flex;
        gap: 2em;
        flex-wrap: wrap;
        flex-direction: row;
    }
`;
