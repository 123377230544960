import { Typography, Chip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { SeasonCardStyle } from "./styled";

import { useContentList } from "@/api/hooks/useContents";
import { IContentListResponse } from "@/api/types/content";
import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function SeasonCard({ Season, serialId }: { serialId: number, Season: IContentListResponse }) {
    const { status, id } = Season;
    const { contentList } = useContentList(id);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.CONTENTS.UPDATE_SERIES.EDIT_BY_ID(serialId).ABSOLUTE + "#1");
    };

    return (
        <SeasonCardStyle>
            <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Chip color={status === "published" ? "info" : "secondary"} label={status} size="small" variant="outlined" />
                <Button
                    color="primary"
                    sx={{ height: "20px", fontSize: "10px", width: "30px" }}
                    variant="contained"
                    onClick={handleClick}
                >
                    Edit
                </Button>
            </Typography>
            <Typography>
                <span className="label">Season Name: </span>
                <span className="value">{Season.ml_name.en}</span>
            </Typography>

            <Typography>
                <span className="label">Season Number:</span>
                <span className="value">{Season.season}</span>
            </Typography>
            <Typography>
                <span className="label">Total Episodes:</span>
                <span className="value">{contentList.length}</span>
            </Typography>
        </SeasonCardStyle>
    );
}
