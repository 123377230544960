import { MODAL_TYPES } from "@/types/modals";
import ConfirmationModal from "./ConfirmationModal";
import SelectVideoModal from "./SelectVideoModal";
import VideoPlayerModal from "./VideoPlayerModal";
import SelectImageModal from "./SelectImageModal";
import ImagePreviewModal from "./ImagePreviewModal";

export const ModalsList = {
    [MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmationModal,
    [MODAL_TYPES.SELECT_VIDEO_MODAL]: SelectVideoModal,
    [MODAL_TYPES.SELECT_IMAGE_MODAL]: SelectImageModal,
    [MODAL_TYPES.VIDEO_PLAYER_MODAL]: VideoPlayerModal,
    [MODAL_TYPES.IMAGE_PREVIEW]: ImagePreviewModal,
};
