import { HelmetProvider } from "react-helmet-async";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { GlobalStyles } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarUtilsConfigurator } from "@/utils/stackbarUtil";
import ErrorBoundary from "./components/app/ErrorBoundry/ErrorBoundry.tsx";
import { globalStyle } from "./assets/global-style";
import { useThemeStore } from "./context/theme";
import { darkTheme, lightTheme } from "./theme";
import Routes from "./Routes";
import "./sentry.ts";
import "./app.css";

const queryClient = new QueryClient();

function App() {
    const { theme } = useThemeStore();
    const themeProp = theme === "dark" ? darkTheme : lightTheme;

    return (
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <ErrorBoundary>
                    <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                        <ThemeProvider theme={themeProp}>
                            <GlobalStyles styles={globalStyle} />
                            <CssBaseline />
                            <Routes />
                        </ThemeProvider>

                        <SnackbarUtilsConfigurator />
                    </SnackbarProvider>
                </ErrorBoundary>
            </QueryClientProvider>
        </HelmetProvider>
    );
}

export default App;
