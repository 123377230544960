import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { setPasswordValidationSchema } from "./validation";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { Helmet } from "react-helmet-async";
import FormGenerator from "@/components/kit/FormGenerator";
import { ISetPasswordFormData } from "./form-type";
import { setPasswordSchema } from "./schema";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useAuth } from "@/hooks/useAuth";

const SetPassword: React.FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { resetPassword } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);

    const token = searchParams.get("token");

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<ISetPasswordFormData>({
        defaultValues: generateDefaultValueFromFormSchema(setPasswordSchema),
        mode: "all",
        resolver: yupResolver(setPasswordValidationSchema),
    });

    const onSubmit = async (data: ISetPasswordFormData) => {
        setLoading(true);
        await resetPassword(token!, data.password);
        setLoading(false);
    };

    useEffect(() => {
        if (!token?.length) {
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        }
    }, [token, navigate]);

    const footer = (
        <>
            <FormControl fullWidth variant="standard">
                <LoadingButton type="submit" disabled={!isValid || loading} loading={loading} variant="contained">
                    Set Password
                </LoadingButton>
            </FormControl>
            <FormControl fullWidth variant="standard" margin="normal">
                <Button component={Link} to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}>
                    Back to login
                </Button>
            </FormControl>
        </>
    );

    return (
        <>
            <Helmet>
                <title>Tahseen | Set New Password</title>
            </Helmet>
            <h1>Set New Password</h1>
            <p>Set password to access admin panel</p>
            <FormGenerator schema={setPasswordSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} footer={footer} />
        </>
    );
};

export default SetPassword;
