import {
    IPlanCheckoutReponse,
    IPlanCheckoutRequestParams,
    IGetPlanReponse,
    IGetPlanRequestParams,
    IUpdatePlanRequestBody,
    IUpdatePlanRequestParams,
    IUpdatePlanReponse,
    IDeletePlanRequestParams,
    IDeleteResponse,
    ICreatePlanRequestParams,
    ICreatePlanRequestBody,
    ICreatePlanResponse,
    IGetPlansResponse,
    IGetPlansRequestParams,
} from "@/api/types/plans";
import AxiosInstance from "@/api";
import { API_ROUTES } from "@/constants/api-routes";

export const GeTPlanCheckoutById = async (params: IPlanCheckoutRequestParams) => {
    const { id, lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.get<IPlanCheckoutReponse>(API_ROUTES.PLANS.CHECKOUT(id), { headers });

    return result.data;
};

export const GeTPlanById = async (params: IGetPlanRequestParams) => {
    const { id, lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.get<IGetPlanReponse>(API_ROUTES.PLANS.GET_BY_ID(id), { headers });

    return result.data;
};

export const UpdatePlanById = async (params: IUpdatePlanRequestParams, body: IUpdatePlanRequestBody) => {
    const { id, lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.put<IUpdatePlanReponse>(API_ROUTES.PLANS.UPDATE(id), body, { headers });

    return result.data;
};

export const DeletePlanById = async (params: IDeletePlanRequestParams) => {
    const { id, lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.delete<IDeleteResponse>(API_ROUTES.PLANS.DELETE(id), { headers });

    return result.data;
};

export const CreatePlan = async (params: ICreatePlanRequestParams, body: ICreatePlanRequestBody) => {
    const { lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.post<ICreatePlanResponse>(API_ROUTES.PLANS.ADD, body, { headers });

    return result.data;
};

export const GetPlans = async (params: IGetPlansRequestParams) => {
    const { lang, currency } = params;

    const headers = {
        LANG: lang,
        CURRENCY: currency,
    };

    const result = await AxiosInstance.post<IGetPlansResponse>(API_ROUTES.PLANS.LIST, { headers });

    return result.data;
};
