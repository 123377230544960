import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NotFoundStyled } from "./styled";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <NotFoundStyled>
            <span>Oops!</span>
            <h1>404</h1>
            <h3>You seem lost!</h3>
            <h6>The page you’re looking for doesn’t exist.</h6>
            <div>
                <Button onClick={() => navigate(-1)}>Go Back</Button>
            </div>
        </NotFoundStyled>
    );
};
export default NotFound;
