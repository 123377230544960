import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import { IContentUpdatePayload, IContentCreatePayload, IContentListResponse } from "@/api/types/content";
import { contentService } from "@/api/services/content";
import { IPaginatedRequestBody } from "@/types/axios-response";

export function useContentById(id: number) {
    return useQuery({
        enabled: !!id,
        queryKey: ["contents", id],
        queryFn: () => contentService.show(Number(id)),
    });
}

export function useUpdateContent() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, body: IContentUpdatePayload }) => contentService.update(Number(id), body),
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
        },
        onSuccess: (_, { id }) => {
            queryClient.invalidateQueries({ queryKey: ["contents", id] });
            enqueueSnackbar("SuccessFully Updated", { variant: "success" });
        },
    });
}

export function useCreateContent() {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (body: IContentCreatePayload) => contentService.create(body),
        onSuccess: () => {
            enqueueSnackbar("Successfully Created", { variant: "success" });
        },
        onError: (error) => {
            enqueueSnackbar(error.message, { variant: "error" });
        },
    });
}

export function useContentList(id?: number) {
    const { enqueueSnackbar } = useSnackbar();
    const [contentList, setContentList] = useState<IContentListResponse[]>([]);

    const { mutate: getContentList, ...rest } = useMutation({
        mutationFn: (body: IPaginatedRequestBody) => contentService.list(body),
        onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    });

    const reFetch = useCallback(() => {
        getContentList(
            { filter: { parentId: id } },
            {
                onSuccess: (response) => {
                    setContentList(response.data.data.items);
                },
            }
        );
    }, [getContentList, id]);

    useEffect(() => {
        if (id) reFetch();
    }, [reFetch, id]);

    return { contentList, getContentList, reFetch, ...rest };
}

export function useDeleteContent() {
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: deleteContent, ...rest } = useMutation({
        mutationFn: (id: number) => contentService.delete(id),
        onError: (error) => enqueueSnackbar(error.message, { variant: "error" }),
    });

    return { deleteContent, ...rest };
}
