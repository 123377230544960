import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { Autocomplete, Box, FormControl, FormHelperText, Grid, Popper, TextField } from "@mui/material";
import { useState } from "react";

import { TextFieldSchema } from "@/types/form-generator-schema-type";
import { countries } from "@/constants/countries";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: TextFieldSchema["props"];
}

const FormPhone = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const srt = field?.value || "";
    const phoneNumberStr = srt.replace(/ /g, "");
    const withDash = phoneNumberStr.includes("-");

    const phoneNumber = withDash ? phoneNumberStr.split("-")[1] : phoneNumberStr.substring(4, phoneNumberStr.length);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        srt && withDash ? phoneNumberStr.split("-")[0] : srt.substring(0, 4)
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <FormControl fullWidth className={className} margin="normal" variant="outlined">
                    <Autocomplete
                        autoHighlight
                        PopperComponent={(props) => (
                            <Popper {...props} placement="bottom-start" style={{ width: "fit-content" }} />
                        )}
                        disabled={props.disabled}
                        getOptionLabel={(option) => option.phone}
                        id={`country-select-${field.name}`}
                        options={countries}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                    placeholder: "Country Code",
                                }}
                                label="Country Code"
                                required={props.required}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    alt=""
                                    loading="lazy"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    width="20"
                                />
                                {option.label} ({option.code}) {option.phone}
                            </Box>
                        )}
                        value={countries.find((item) => item.phone === countryCode)}
                        onChange={(_, value) => {
                            setCountryCode(value?.phone);
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth className={className} margin="normal" variant="outlined">
                    <TextField
                        {...field}
                        {...props}
                        fullWidth
                        defaultValue={phoneNumber}
                        label={`${label}${props.required ? " *" : ""}`}
                        placeholder={placeholder}
                        value={phoneNumber}
                        variant="outlined"
                        onChange={(e) => field.onChange(countryCode ? `${countryCode}-${e.target.value}` : e.target.value)}
                    />
                    {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default FormPhone;
