import { IModalProps } from "@/types/modals";
import { useCallback, useEffect, useState } from "react";
import {
    SelectVideoModalFooterButtonsStyled,
    SelectVideoModalFooterPaginationStyled,
    SelectVideoModalFooterStyled,
    SelectVideoModalItemContentStyled,
    SelectVideoModalItemHeaderStyled,
    SelectVideoModalItemStyled,
    SelectVideoModalListBodyLoadingStyled,
    SelectVideoModalListBodyStyled,
    SelectVideoModalListHeaderStyled,
    SelectVideoModalListWrapperStyled,
    SelectVideoModalPreviewStyled,
    SelectVideoModalStyled,
} from "./styled";
import { videoService } from "@/api/services/video";
import { IVideoListResponse, IVideoShowResponse } from "@/api/types/video";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { ITablePaginate, ITableParams } from "@/types/table";
import { stringLimiter } from "@/utils/string-limiter";
import { Button, CircularProgress, InputAdornment, OutlinedInput, Pagination } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDebounce } from "@/hooks/useDebounce";
import { VIDEO_MODAL_PER_PAGE } from "@/constants/video-modal-configs";
import { useIsMount } from "@/hooks/useIsMount";

export interface SelectVideoModalProps {
    preview?: boolean;
    onChange: (...event: any[]) => void;
}
const SelectVideoModal: React.FC<IModalProps<SelectVideoModalProps>> = ({ data: { preview = false, onChange }, closeModal }) => {
    const isMount = useIsMount();
    const [listLoading, setListLoading] = useState(true);
    const [selectVideoLoading, setSelectVideoLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [data, setData] = useState<IVideoListResponse[]>();
    const [pagination, setPagination] = useState<ITablePaginate>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const [selectedVideo, setSelectedVideo] = useState<IVideoShowResponse>();
    const [search, setSearch] = useState("");

    const fetchVideoList = async () => {
        try {
            setListLoading(true);
            const response = await videoService.list(queryParams);
            setData(response.data.data.items);
            setPagination(response.data.data.pagination);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setListLoading(false);
            setSearchLoading(false);
        }
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: VIDEO_MODAL_PER_PAGE, skip: (page - 1) * VIDEO_MODAL_PER_PAGE }));
    }, []);

    const fetchDataDebounce = useDebounce(() => {
        setQueryParams((prev) => ({ ...prev, search }));
    });

    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setSearchLoading(true);
    };

    const clearSearch = () => {
        setSearch("");
        setSearchLoading(true);
    };

    const getVideoById = async (videoRowId: number) => {
        try {
            setSelectVideoLoading(true);
            const response = await videoService.show(videoRowId);
            setSelectedVideo(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSelectVideoLoading(false);
        }
    };

    const handleSelectVideo = (videoIndexId: number) => {
        getVideoById(videoIndexId);
    };
    const handleSelectVideoForm = () => {
        onChange(selectedVideo);
        closeModal();
    };

    useEffect(() => {
        if (!isMount) fetchDataDebounce();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        fetchVideoList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams]);

    return (
        <>
            <SelectVideoModalStyled>
                <SelectVideoModalListWrapperStyled>
                    <SelectVideoModalListHeaderStyled>
                        <OutlinedInput
                            value={search}
                            onChange={onSearch}
                            size="small"
                            placeholder="Video Search ..."
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchLoading ? (
                                        <CircularProgress size={24} />
                                    ) : search.length ? (
                                        <CloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                                    ) : null}
                                </InputAdornment>
                            }
                        />
                    </SelectVideoModalListHeaderStyled>
                    {listLoading ? (
                        <SelectVideoModalListBodyLoadingStyled>
                            <CircularProgress size={24} />
                        </SelectVideoModalListBodyLoadingStyled>
                    ) : (
                        <SelectVideoModalListBodyStyled>
                            {data?.map((item) => (
                                <SelectVideoModalItemStyled active={selectedVideo?.videoId === item.videoId} onClick={() => handleSelectVideo(item.id)}>
                                    <SelectVideoModalItemHeaderStyled>
                                        {item.thumbnail?.url ? <img src={item.thumbnail.url} alt={item.title} /> : <>No Image</>}
                                    </SelectVideoModalItemHeaderStyled>
                                    <SelectVideoModalItemContentStyled>
                                        <p>{item.title}</p>
                                        <span>{stringLimiter(item.description)}</span>
                                    </SelectVideoModalItemContentStyled>
                                </SelectVideoModalItemStyled>
                            ))}
                        </SelectVideoModalListBodyStyled>
                    )}
                </SelectVideoModalListWrapperStyled>
                {preview ? (
                    <SelectVideoModalPreviewStyled>
                        {selectVideoLoading ? (
                            <SelectVideoModalListBodyLoadingStyled>
                                <CircularProgress size={24} />
                            </SelectVideoModalListBodyLoadingStyled>
                        ) : selectedVideo ? (
                            <video controls preload="false" src={selectedVideo.playableUrl} poster={selectedVideo.thumbnail?.url} />
                        ) : (
                            <span>No video has been selected yet</span>
                        )}
                    </SelectVideoModalPreviewStyled>
                ) : null}
            </SelectVideoModalStyled>
            <SelectVideoModalFooterStyled>
                {pagination && pagination?.totalItems > VIDEO_MODAL_PER_PAGE ? (
                    <SelectVideoModalFooterPaginationStyled>
                        <Pagination
                            count={pagination.totalPages}
                            disabled={listLoading}
                            page={pagination.currentPage}
                            onChange={(e, page) => handlePaginationModelChange(page)}
                        />
                    </SelectVideoModalFooterPaginationStyled>
                ) : null}
                <SelectVideoModalFooterButtonsStyled>
                    <Button disabled={!selectedVideo} onClick={handleSelectVideoForm} variant="contained">
                        Select Video
                    </Button>
                </SelectVideoModalFooterButtonsStyled>
            </SelectVideoModalFooterStyled>
        </>
    );
};

export default SelectVideoModal;
