import React, { useState, MouseEvent } from "react";
import { Button, Box } from "@mui/material";
import { FilterList } from "@mui/icons-material";

import { SortAndFiltersProvider, useSortAndFilters } from "./SortAndFiltersContext";
import Filters from "./Filters";
import { ISortAndFiltersProps } from "./types";

import { IListItem } from "@/api/types/base-data";

const SortAndFiltersContent: React.FC = () => {
    const { filterItems, setActiveSortItem, filtersIsOpen, setFiltersIsOpen, filters, setFilters, applyChanges } =
        useSortAndFilters();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleSortClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSortClose = (selectedItem: IListItem | null) => {
        setAnchorEl(null);
        if (selectedItem) {
            handleSortChange(selectedItem);
        }
    };

    const handleSortChange = (selectedItem: IListItem) => {
        setActiveSortItem(selectedItem);
        const updatedFilters = { ...filters, sort: selectedItem.key };

        setFilters(updatedFilters);
        applyChanges(updatedFilters);
    };

    const toggleFilters = () => {
        setFiltersIsOpen(!filtersIsOpen);
    };

    return (
        <Box>
            <div style={{ textAlign: "right", marginBottom: "2em" }}>
                {filterItems.length > 0 && (
                    <Button className="m-2" startIcon={<FilterList />} variant="contained" onClick={toggleFilters}>
                        Filters
                    </Button>
                )}
            </div>
            {filtersIsOpen && <Filters />}
        </Box>
    );
};

const SortAndFilters: React.FC<ISortAndFiltersProps> = ({ listName, onChange, ignoreFilterItem }) => (
    <SortAndFiltersProvider ignoreFilterItem={ignoreFilterItem} listName={listName} onChange={onChange}>
        <SortAndFiltersContent />
    </SortAndFiltersProvider>
);

export default SortAndFilters;
