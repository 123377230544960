export enum CATEGORY {
    ENTERTAINMENT = "entertainment",
    EDUCATION = "education",
}
export enum UI_SECTION {
    HORIZoNTAL = "Horizontal",
    SPOTLIGHT = "Spotlight",
    CONTINUE = "Continue",
    VERTICAL = "Vertical",
}

export enum Tag {
    Spotlight = 1,
    LatestMovies = 2,
    PopularMovies = 3,
    LatestSeries = 4,
    FunDocumentariesForLearning = 5,
}

export const tagMapping: { [key in Tag]: string } = {
    [Tag.Spotlight]: "spotlight",
    [Tag.LatestMovies]: "latest-movies",
    [Tag.PopularMovies]: "popular-movies",
    [Tag.LatestSeries]: "latest-series",
    [Tag.FunDocumentariesForLearning]: "fun-documentaries-for-learning",
};
