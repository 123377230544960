import { getAxiosError } from "../utils/get-axios-error";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { videoService } from "@/api/services/video";
import { VideoUploader, VideoUploadResponse } from "@api.video/video-uploader";

export const useVideo = () => {
    const [loading, setLoading] = useState(false);
    const [uploadedPercent, setUploadedPercent] = useState<number>();

    const getToken = async () => {
        try {
            setLoading(true);
            const response = await videoService.token();
            return response.data.data;
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const uploadVideo = async (file: File): Promise<VideoUploadResponse> => {
        try {
            const { token } = await getToken();
            const uploader = new VideoUploader({
                file,
                uploadToken: token!,
            });

            uploader.onProgress((event) => {
                const percentComplete = (event.uploadedBytes / event.totalBytes) * 100;
                setUploadedPercent(percentComplete);
            });

            return uploader.upload();
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
            throw err;
        }
    };

    return {
        loading,
        uploadedPercent,
        uploadVideo,
    };
};
