/* eslint-disable no-unused-vars */
export enum PERMISSIONS {
    // Users Permissions
    USER_LIST = "user|list",
    USER_CREATE = "user|create",
    USER_UPDATE = "user|update",
    USER_DELETE = "user|delete",
    USER_DETAIL = "user|detail",

    // Customers Permissions
    CUSTOMER_LIST = "customer|list",
    CUSTOMER_CREATE = "customer|create",
    CUSTOMER_UPDATE = "customer|update",
    CUSTOMER_DELETE = "customer|delete",
    CUSTOMER_DETAIL = "customer|detail",

    // Contents Permissions
    CONTENT_LIST = "content|list",
    CONTENT_CREATE = "content|create",
    CONTENT_UPDATE = "content|update",
    CONTENT_DELETE = "content|delete",
    CONTENT_DETAIL = "content|detail",

    // Tags Permissions
    TAG_LIST = "tag|list",
    TAG_CREATE = "tag|create",
    TAG_UPDATE = "tag|update",
    TAG_DELETE = "tag|delete",
    TAG_DETAIL = "tag|detail",

    // Genres Permissions
    GENRE_LIST = "genre|list",
    GENRE_CREATE = "genre|create",
    GENRE_UPDATE = "genre|update",
    GENRE_DELETE = "genre|delete",
    GENRE_DETAIL = "genre|detail",

    // Videos Permissions
    VIDEO_LIST = "video|list",
    VIDEO_CREATE = "video|create",
    VIDEO_UPDATE = "video|update",
    VIDEO_DELETE = "video|delete",
    VIDEO_DETAIL = "video|detail",

    // Images Permissions
    IMAGE_LIST = "image|list",
    IMAGE_CREATE = "image|create",
    IMAGE_UPDATE = "image|update",
    IMAGE_DELETE = "image|delete",
    IMAGE_DETAIL = "image|detail",

    // Images Permissions
    AVATAR_LIST = "avatar|list",
    AVATAR_CREATE = "avatar|create",
    AVATAR_UPDATE = "avatar|update",
    AVATAR_DELETE = "avatar|delete",
    AVATAR_DETAIL = "avatar|detail",

    // Home Permissions
    HOME_LIST = "home|list",
    HOME_CREATE = "home|create",
    HOME_UPDATE = "home|update",
    HOME_DELETE = "home|delete",
    HOME_DETAIL = "home|detail",

    // Static Permissions
    STATIC_LIST = "static|list",
    STATIC_CREATE = "static|create",
    STATIC_UPDATE = "static|update",
    STATIC_DELETE = "static|delete",
    STATIC_DETAIL = "static|detail",

    // Countries Permissions
    COUNTRIES_LIST = "countries|list",
    COUNTRIES_CREATE = "countries|create",
    COUNTRIES_UPDATE = "countries|update",
    COUNTRIES_DELETE = "countries|delete",
    COUNTRIES_DETAIL = "countries|detail",

    // Countries Permissions
    PLANS_LIST = "plans|list",
    PLANS_CREATE = "plans|create",
    PLANS_UPDATE = "plans|update",
    PLANS_DELETE = "plans|delete",
    PLANS_DETAIL = "plans|detail",
}
