import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { IStaticCreatePayload, IStaticListResponse, IStaticUpdatePayload } from "@/api/types/static";

export const staticService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IStaticListResponse>>>(API_ROUTES.STATIC.LIST, params);
    },
    create(data: IStaticCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.STATIC.CREATE, data);
    },
    update(id: number, data: IStaticUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.STATIC.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<ITableResponse<IStaticListResponse>>>(API_ROUTES.STATIC.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IStaticListResponse>>(API_ROUTES.STATIC.SHOW(id));
    },
};
