import * as qs from "qs";

import { apiBaseUrl } from "@/constants/api-base-urls";

const paramsSerializer = {
    serialize: (params: Record<string, any>) => qs.stringify(params, { indices: false, arrayFormat: "indices" }),
};

export const ApiConfig = {
    baseURL: apiBaseUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
    timeout: 10 * 1000,
    paramsSerializer,
    withCredentials: false,
};
