import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { GenreHeaderStyle } from "./style";

import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function GenreHeader() {
    return (
        <GenreHeaderStyle>
            <Typography className="page-title">Genre List</Typography>
            <Button
                color="primary"
                component={Link}
                to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ADD_NEW.ROOT.ABSOLUTE}
                variant="contained"
            >
                Add New Genre
            </Button>
        </GenreHeaderStyle>
    );
}
