import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import { FilterBody } from "../types";
import { IGenreUpdatePayload } from "../types/genre";

import { errorCallback } from "@/api/hooks/error";
import { GetGenreList, DeleteGenre, UpdateGenre, CreateGenre } from "@/api/services/genre";

export function useGenreList(params: FilterBody) {
    return useQuery({
        queryKey: ["Genres", params],
        queryFn: () => GetGenreList({ params }),
    });
}

export function useDeleteGenre() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (id: number) => DeleteGenre({ id }),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Genres"] });
            enqueueSnackbar("The Genre Deleted Successfully", { variant: "success" });
        },
    });
}

export function useUpdateGenre() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body }: { id: number, lang?: string, body: IGenreUpdatePayload }) => UpdateGenre({ id, body }),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Genres"] });
            enqueueSnackbar("The Genre Updated Successfully", { variant: "success" });
        },
    });
}

export function useCreateGenre() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body }: { lang?: string, body: IGenreUpdatePayload }) => CreateGenre({ body }),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["Genres"] });
            enqueueSnackbar("The Genre Updated Successfully", { variant: "success" });
        },
    });
}
