import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type StaticFormSchemaFields = "en_title" | "ar_title" | "slug" | "en_html_content" | "ar_html_content";

export type ITagFormSchema = Record<StaticFormSchemaFields, IFormGeneratorSchemaType>;

export const staticSchema: ITagFormSchema = {
    en_title: {
        label: "English Title",
        placeholder: "English Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
        gridCol: 6,
    },
    ar_title: {
        label: "Arabic Title",
        placeholder: "Arabic Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            dir: "rtl",
        },
        gridCol: 6,
    },
    en_html_content: {
        label: "English HTML Content",
        placeholder: "Enter HTML content in English",
        type: FORM_INPUTS.RICH_TEXT_EDITOR,
        props: {
            required: true,
        },
        gridCol: 12,
    },
    ar_html_content: {
        label: "Arabic HTML Content",
        placeholder: "Enter HTML content in Arabic",
        type: FORM_INPUTS.RICH_TEXT_EDITOR,
        props: {
            required: true,
            dir: "rtl",
        },
        gridCol: 12,
    },
    slug: {
        label: "Slug",
        placeholder: "Slug",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
        gridCol: 6,
    },
};
