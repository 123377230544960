export enum FORM_INPUTS {
    FIELD_SET = "filed_set",
    TEXT = "text",
    TEXTAREA = "textarea",
    PASSWORD = "password",
    PHONE = "phone",
    SELECT = "select",
    MULTI_SELECT = "multi_select",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    VIDEO = "video",
    SELECT_VIDEO = "select_video",
    IMAGE = "image",
    SELECT_IMAGE = "select_image",
    RICH_TEXT_EDITOR = "rich_text_editor", // New type added
}
