import { styled } from "@mui/material";

export const VideoPlayerModalStyled = styled("div")(
    ({ theme }) => `
    width: 600px;
    height: 400px;
    flex: 0 0 600px;
    video {
        width: 100%;
        height: 400px;
        border-radius: ${theme.spacing(1)};
    }
`
);

export const VideoPlayerModalLoadingStyled = styled("div")(
    ({ theme }) => `
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing(2)};
`
);
