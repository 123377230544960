import { ReactNode } from "react";
import { DashboardHeaderStyled, DashboardHeaderSuffixStyled, DashboardHeaderTitleStyled } from "./styled";
import { Helmet } from "react-helmet-async";

interface IProps {
    title?: string;
    subTitle?: string;
    titleSuffix?: ReactNode;
}

const DashboardHeader: React.FC<IProps> = ({ title = "", subTitle = "", titleSuffix }) => {
    return (
        <>
            <Helmet>
                <title>Tahseen | {title}</title>
            </Helmet>
            <DashboardHeaderStyled>
                <DashboardHeaderTitleStyled>
                    {title ? <h1>{title}</h1> : null}
                    {subTitle ? <p>{subTitle}</p> : null}
                </DashboardHeaderTitleStyled>
                {titleSuffix ? <DashboardHeaderSuffixStyled>{titleSuffix}</DashboardHeaderSuffixStyled> : null}
            </DashboardHeaderStyled>
        </>
    );
};

export default DashboardHeader;
