import { styled } from "@mui/material";

export const DashboardHeaderStyled = styled("div")(
    ({ theme }) => `
    margin-bottom: ${theme.spacing(4)};
    display: flex;
    align-items: center;
`
);

export const DashboardHeaderTitleStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    
    h1 {
        line-height: 36px;
        margin: 0;
    }
`
);

export const DashboardHeaderSuffixStyled = styled("div")(
    ({ theme }) => `
    margin-left: auto;
`
);
