import { Box, Button, Chip } from "@mui/material";
import dayjs from "dayjs";

import { EpisodeHeaderStyle } from "./style";

import { IContentListResponse } from "@/api/types/content";

interface IEpisodeCard {
    data: IContentListResponse;
    // eslint-disable-next-line no-unused-vars
    onEdit: (id: number) => void;
    // eslint-disable-next-line no-unused-vars
    onDelete: (id: number) => void;
}

export default function EpisodeCard({ data, onEdit, onDelete }: IEpisodeCard) {
    if (!data) return null;

    const { ml_name, status, thumbnailLandscape, createdAt, updatedAt, id: episodeId } = data;

    return (
        <Box className="episode-card">
            <EpisodeHeaderStyle className="episode-header" src={thumbnailLandscape?.url || ""} />
            <Box className="middle">
                <ul>
                    <li>
                        <span className="label">Status</span>
                        <span className="value">
                            <Chip
                                color={status === "published" ? "info" : "secondary"}
                                label={status}
                                size="small"
                                variant="outlined"
                            />
                        </span>
                    </li>
                    <li>
                        <span className="label">Name:</span>
                        <span className="value">{ml_name.en || ""}</span>
                    </li>
                    <li>
                        <span className="label">Created At:</span>
                        <span className="value">{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</span>
                    </li>
                    <li>
                        <span className="label">Updated At:</span>
                        <span className="value">{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </li>
                </ul>
            </Box>
            <Box className="footer">
                <Button color="error" size="small" variant="contained" onClick={() => onDelete(episodeId)}>
                    Delete
                </Button>
                <Button color="primary" size="small" variant="contained" onClick={() => onEdit(episodeId)}>
                    Edit
                </Button>
            </Box>
        </Box>
    );
}
