import { Helmet } from "react-helmet-async";
import DashboardHeader from "@/components/app/DashboardHeader";

const Dashboard: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Tahseen | Dashboard </title>
            </Helmet>
            <DashboardHeader title="Welcome To Dashboard" />
        </>
    );
};

export default Dashboard;
