import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";

interface Schema {
    [key: string]: IFormGeneratorSchemaType;
}

export const generateDefaultValueFromFormSchema = (schema: Schema) => {
    return Object.entries(schema).reduce((prev: any, [key, value]) => {
        return { ...prev, [key]: value.defaultValue || "" };
    }, {});
};
