const ROUTE_CONSTANTS = {
    ROOT: {
        RELATIVE: "/",
        ABSOLUTE: "/",
    },
    ROOT_STAR: {
        RELATIVE: "/*",
        ABSOLUTE: "/*",
    },
    AUTH: {
        ROOT: {
            RELATIVE: "auth",
            ABSOLUTE: "/auth",
        },
        LOGIN: {
            RELATIVE: "login",
            ABSOLUTE: "/auth/login",
        },
        SET_PASSWORD: {
            RELATIVE: "set-password",
            ABSOLUTE: "/auth/set-password",
        },
        FORGET_PASSWORD: {
            RELATIVE: "forget-password",
            ABSOLUTE: "/auth/forget-password",
        },
    },
    DASHBOARD: {
        ROOT: {
            RELATIVE: "dashboard",
            ABSOLUTE: "/dashboard",
        },
    },
    PROFILE: {
        ROOT: {
            RELATIVE: "profile",
            ABSOLUTE: "/profile",
        },
    },
    USER_MANAGEMENT: {
        ROOT: {
            RELATIVE: "user-management",
            ABSOLUTE: "/user-management",
        },
        USERS: {
            ROOT: {
                RELATIVE: "users",
                ABSOLUTE: "/user-management/users",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "users/create",
                    ABSOLUTE: "/user-management/users/create",
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "users/:id",
                    ABSOLUTE: "/user-management/users/:id",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `users/${id}`,
                    ABSOLUTE: `/user-management/users/${id}`,
                }),
            },
        },
        CUSTOMERS: {
            ROOT: {
                RELATIVE: "customers",
                ABSOLUTE: "/user-management/customers",
            },
            SHOW: {
                ROOT: {
                    RELATIVE: "customers/:id",
                    ABSOLUTE: "/user-management/customers/:id",
                },
                SHOW_BY_ID: (id: number) => ({
                    RELATIVE: `customers/${id}`,
                    ABSOLUTE: `/user-management/customers/${id}`,
                }),
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "customers/:id/edit",
                    ABSOLUTE: "/user-management/customers/:id/edit",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `customers/${id}/edit`,
                    ABSOLUTE: `/user-management/customers/${id}/edit`,
                }),
            },
        },
    },
    CONTENT_MANAGEMENT: {
        ROOT: {
            RELATIVE: "content-management",
            ABSOLUTE: "/content-management",
        },
        CONTENTS: {
            ROOT: {
                RELATIVE: "contents",
                ABSOLUTE: "/content-management/contents",
            },
            SHOW_PUBLISHER: {
                ROOT: {
                    RELATIVE: "contents/publisher",
                    ABSOLUTE: "/content-management/contents/publisher",
                },
            },
            ADD_NEW_MOVIE: {
                ROOT: {
                    RELATIVE: "contents/create-movie",
                    ABSOLUTE: "/content-management/contents/create-movie",
                },
            },
            UPDATE_MOVIE: {
                ROOT: {
                    RELATIVE: "contents/:id/edit-movie",
                    ABSOLUTE: "/content-management/contents/:id/edit-movie",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `contents/${id}/edit-movie`,
                    ABSOLUTE: `/content-management/contents/${id}/edit-movie`,
                }),
            },
            ADD_NEW_SERIES: {
                ROOT: {
                    RELATIVE: "contents/create-series",
                    ABSOLUTE: "/content-management/contents/create-series",
                },
            },
            UPDATE_SERIES: {
                ROOT: {
                    RELATIVE: "contents/:id/edit-series",
                    ABSOLUTE: "/content-management/contents/:id/edit-series",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `contents/${id}/edit-series`,
                    ABSOLUTE: `/content-management/contents/${id}/edit-series`,
                }),
            },

            SHOW: {
                ROOT: {
                    RELATIVE: "contents/:id",
                    ABSOLUTE: "/content-management/contents/:id",
                },
                SHOW_BY_ID: (id: number) => ({
                    RELATIVE: `contents/${id}`,
                    ABSOLUTE: `/content-management/contents/${id}`,
                }),
            },
        },
        TAGS: {
            ROOT: {
                RELATIVE: "tags",
                ABSOLUTE: "/content-management/tags",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "tags/create",
                    ABSOLUTE: "/content-management/tags/create",
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "tags/:id",
                    ABSOLUTE: "/content-management/tags/:id",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `tags/${id}`,
                    ABSOLUTE: `/content-management/tags/${id}`,
                }),
            },
        },
        GENRES: {
            ROOT: {
                RELATIVE: "genres",
                ABSOLUTE: "/content-management/genres",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "genres/create",
                    ABSOLUTE: "/content-management/genres/create",
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "genres/:id",
                    ABSOLUTE: "/content-management/genres/:id",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `genres/${id}`,
                    ABSOLUTE: `/content-management/genres/${id}`,
                }),
            },
        },
    },
    MEDIA_MANAGEMENT: {
        ROOT: {
            RELATIVE: "media-management",
            ABSOLUTE: "/media-management",
        },
        VIDEOS: {
            ROOT: {
                RELATIVE: "videos",
                ABSOLUTE: "/media-management/videos",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "videos/create",
                    ABSOLUTE: "/media-management/videos/create",
                },
            },
            SHOW: {
                ROOT: {
                    RELATIVE: "videos/:id",
                    ABSOLUTE: "/media-management/videos/:id",
                },
                SHOW_BY_ID: (id: number) => ({
                    RELATIVE: `customers/${id}`,
                    ABSOLUTE: `/media-management/videos/${id}`,
                }),
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "videos/:id/edit",
                    ABSOLUTE: "/media-management/videos/:id/edit",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `videos/${id}/edit`,
                    ABSOLUTE: `/media-management/videos/${id}/edit`,
                }),
            },
        },
        IMAGES: {
            ROOT: {
                RELATIVE: "images",
                ABSOLUTE: "/media-management/images",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "images/create",
                    ABSOLUTE: "/media-management/images/create",
                },
            },
        },
        AVATARS: {
            ROOT: {
                RELATIVE: "avatars",
                ABSOLUTE: "/media-management/avatars",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "avatars/create",
                    ABSOLUTE: "/media-management/avatars/create",
                },
            },
        },
    },
    APP_UI_MANAGEMENT: {
        ROOT: {
            RELATIVE: "app-ui-management",
            ABSOLUTE: "/app-ui-management",
        },
        HOME: {
            ROOT: {
                RELATIVE: "home",
                ABSOLUTE: "/app-ui-management/home",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "home/create",
                    ABSOLUTE: "/app-ui-management/home/create",
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "home/:id",
                    ABSOLUTE: "/app-ui-management/home/:id",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `home/${id}`,
                    ABSOLUTE: `/app-ui-management/home/${id}`,
                }),
            },
            ADD_NEW_SECTION: {
                ROOT: {
                    RELATIVE: "home/section/create",
                    ABSOLUTE: "/app-ui-management/home/section/create",
                },
                EDIT_BY_ID: () => ({
                    RELATIVE: `home/section/create`,
                    ABSOLUTE: `/app-ui-management/home/section/create`,
                }),
            },
            UPDATE_SECTION: {
                ROOT: {
                    RELATIVE: `home/section/:id`,
                    ABSOLUTE: `/app-ui-management/home/section/:id}`,
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `home/section/${id}`,
                    ABSOLUTE: `/app-ui-management/home/section/${id}`,
                }),
            },
        },
    },
    SETTINGS: {
        ROOT: {
            RELATIVE: "settings",
            ABSOLUTE: "/settings",
        },
        STATIC: {
            ROOT: {
                RELATIVE: "static-page",
                ABSOLUTE: "/settings/static-page",
            },
            ADD_NEW: {
                ROOT: {
                    RELATIVE: "static-page/create",
                    ABSOLUTE: "/settings/static-page/create",
                },
            },
            UPDATE: {
                ROOT: {
                    RELATIVE: "static-page/:id",
                    ABSOLUTE: "/settings/static-page/:id",
                },
                EDIT_BY_ID: (id: number) => ({
                    RELATIVE: `static-page/${id}`,
                    ABSOLUTE: `/settings/static-page/${id}`,
                }),
            },
        },
        COUNTRIES: {
            ROOT: {
                RELATIVE: "countries",
                ABSOLUTE: "/settings/countries",
            },
            CONTENT: {
                ADD_NEW: {
                    ROUTE: {
                        RELATIVE: "countries/create",
                        ABSOLUTE: "/settings/countries/create",
                    },
                    ADD: {
                        RELATIVE: "countries/create",
                        ABSOLUTE: "/settings/countries/create",
                    },
                },
                DETAILS: {
                    ROUTE: {
                        RELATIVE: "countries/:id/details",
                        ABSOLUTE: "/settings/countries/:id/details",
                    },
                    ROOT: (id: number) => ({
                        RELATIVE: `countries/${id}/details`,
                        ABSOLUTE: `/settings/countries/${id}/details`,
                    }),
                },
            },
        },
        PLANS: {
            ROOT: {
                RELATIVE: "plans",
                ABSOLUTE: "/settings/plans",
            },
            ADD_NEW: {
                RELATIVE: "plans/create",
                ABSOLUTE: "/settings/plans/create",
            },
            EDIT: {
                ROUTE: {
                    RELATIVE: `plans/:planId/edit`,
                    ABSOLUTE: `/settings/plans/:planId/edit`,
                },
                ROOT: (id: number) => ({
                    RELATIVE: `plans/${id}/edit`,
                    ABSOLUTE: `/settings/plans/${id}/edit`,
                }),
            },
            DETAILS: {
                ROUTE: {
                    RELATIVE: `plans/:planId`,
                    ABSOLUTE: `/settings/plans/:planId`,
                },
                ROOT: (id: number) => ({
                    RELATIVE: `plans/${id}`,
                    ABSOLUTE: `/settings/plans/${id}`,
                }),
            },
        },
    },
    NOT_FOUND: {
        RELATIVE: "not-found",
        ABSOLUTE: "/not-found",
    },
    FORBIDDEN: {
        RELATIVE: "forbidden",
        ABSOLUTE: "/forbidden",
    },
};

export default ROUTE_CONSTANTS;
