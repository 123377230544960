import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAuthenticatedUser } from "@/api/types/auth";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { IUserActivationPayload, IUserCreatePayload, IUserListResponse, IUserUpdatePayload } from "@/api/types/user";

export const userService = {
    profile() {
        return AxiosInstance.get<IAxiosResponse<IAuthenticatedUser>>(API_ROUTES.USER.PROFILE);
    },
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IUserListResponse>>>(API_ROUTES.USER.LIST, params);
    },
    create(data: IUserCreatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.USER.CREATE, data);
    },
    update(id: number, data: IUserUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.USER.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse>(API_ROUTES.USER.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IUserListResponse>>(API_ROUTES.USER.SHOW(id));
    },
    activation(id: number, data: IUserActivationPayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.USER.ACTIVATION(id), data);
    },
};
