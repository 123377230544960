import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import { errorCallback } from "@/api/hooks/error";
import { tagService } from "@/api/services/tag";
import { ITableParams } from "@/types/table";
import { ITagCreatePayload, ITagListResponse, ITagUpdatePayload } from "@/api/types/tag";

export function useTags() {
    const [tags, setTags] = useState<ITagListResponse[]>([]);

    const { mutate, ...restProps } = useMutation({
        mutationFn: (body: ITableParams) => tagService.list(body),
        onSuccess: (result) => setTags(result.data.data.items),
        onError: errorCallback,
    });

    useEffect(() => mutate({} as ITableParams, {}), [mutate]);

    return { tags, mutate, ...restProps };
}
