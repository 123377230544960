import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type ImageFormSchemaFields = "image" | "title";

export type IImageFormSchema = Record<ImageFormSchemaFields, IFormGeneratorSchemaType>;

export const imageSchema: IImageFormSchema = {
    image: {
        label: "Image",
        placeholder: "(Max Size: 3Mb)",
        type: FORM_INPUTS.IMAGE,
        props: {
            required: true,
            accept: "image/*",
        },
    },
    title: {
        label: "Title",
        placeholder: "Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
};
