import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type SetPasswordFormSchemaFields = "password" | "passwordConfirmation";

export type ISetPasswordFormSchema = Record<SetPasswordFormSchemaFields, IFormGeneratorSchemaType>;

export const setPasswordSchema: ISetPasswordFormSchema = {
    password: {
        label: "Password",
        placeholder: "Password",
        type: FORM_INPUTS.PASSWORD,
        props: {
            required: true,
            autoFocus: true,
        },
    },
    passwordConfirmation: {
        label: "Password Confirmation",
        placeholder: "Password Confirmation",
        type: FORM_INPUTS.PASSWORD,
        props: {
            required: true,
        },
    },
};
