import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useSnackbar } from "notistack";

import { ICreateVideoCaptionsParams } from "../types/videoCaptions";

import {
    GetVideoCaption,
    GetAllVideoCaptions,
    SetDefaultVideoCaptions,
    CreateVideoCaption,
    DeleteVideoCaption,
} from "@/api/services/videoCaptions";
import { errorCallback } from "@/api/hooks/error";

interface IParams {
    id: number;
    lang: string;
}

export function useGetVideoCaptions(id: number) {
    return useQuery({
        queryKey: ["subtitles"],
        queryFn: () => GetAllVideoCaptions(id),
        enabled: id === 0 || !!id,
    });
}

export function useGetVideoCaption(id: number, lang: string) {
    return useQuery({
        queryKey: ["subtitle", id],
        queryFn: () => GetVideoCaption(id, lang),
        enabled: id === 0 || (!!id && !!lang),
    });
}

export function useDefaultVideoCaption() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, lang }: IParams) => SetDefaultVideoCaptions(id, lang),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["subtitles"] });
        },
    });
}

export function useDeleteVideoCaptions() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, lang }: IParams) => DeleteVideoCaption(id, lang),
        onError: errorCallback,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["subtitles"] });
        },
    });
}

export function useCreateVideoCaption() {
    const [progress, setProgress] = useState<number>(0);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const data = useMutation({
        mutationFn: (params: ICreateVideoCaptionsParams) =>
            CreateVideoCaption({
                ...params,
                progress: (p) => {
                    const { loaded, total } = p;

                    if (total && loaded) setProgress((loaded / total) * 100);
                },
            }),
        onError: errorCallback,
        onSuccess: () => {
            setProgress(0);
            enqueueSnackbar("Subtitle Uploaded", { variant: "success" });
            queryClient.invalidateQueries({ queryKey: ["subtitles"] });
        },
    });

    return { progress, ...data };
}
