import { styled } from "@mui/material";

export const VideoListStyled = styled("div")(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    
`
);
export const VideoListGridStyled = styled("div")<{ loading: boolean }>(
    ({ theme, loading }) => `
    filter: ${loading ? "blur(5px)" : "unset"};
    opacity: ${loading ? "0.6" : "unset"};
`
);
export const VideoListPaginationStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-top; ${theme.spacing(1)};
`
);
