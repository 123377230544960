import { styled } from "@mui/material";

export const SelectImageModalStyled = styled("div")(
    ({ theme }) => `
    display: flex;
`
);

export const SelectImageModalListWrapperStyled = styled("div")(
    ({ theme }) => `
    width: 600px;
    flex: 0 0 600px;
`
);

export const SelectImageModalListHeaderStyled = styled("div")(
    ({ theme }) => `
margin-bottom: ${theme.spacing(2)};
`
);
export const SelectImageModalListBodyStyled = styled("div")(
    ({ theme }) => `
    height: 400px;
    overflow: auto;
    margin-bottom: ${theme.spacing(2)};
`
);
export const SelectImageModalListBodyLoadingStyled = styled("div")(
    ({ theme }) => `
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing(2)};
`
);
export const SelectImageModalListFooterStyled = styled("div")(
    ({ theme }) => `

`
);

export const SelectImageModalPreviewStyled = styled("div")(
    ({ theme }) => `
    width: 450px;
    flex: 0 0 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    img {
        width: auto;
        height: auto;
        max-height: 400px;
        max-width: 100%;
        border-radius: ${theme.spacing(1)};
    }
`
);

export const SelectImageModalFooterStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    align-items: center;
`
);
export const SelectImageModalFooterPaginationStyled = styled("div")(
    ({ theme }) => `
    
`
);
export const SelectImageModalFooterButtonsStyled = styled("div")(
    ({ theme }) => `
    margin-left: auto;
`
);

export const SelectImageModalItemStyled = styled("div")<{ active: boolean }>(
    ({ theme, active }) => `
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.mode === "dark" ? "#666" : "#ccc"};
    padding: ${theme.spacing(1)};
    background-color: ${active ? (theme.palette.mode === "dark" ? "#323232" : "#f5f5f5") : "transparent"};
    &:hover {
        background-color: ${theme.palette.mode === "dark" ? "#323232" : "#f5f5f5"};
    }
`
);

export const SelectImageModalItemHeaderStyled = styled("div")(
    ({ theme }) => `
    width: 50px;
    height: 70px;
    flex: 0 0 50px;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: ${theme.spacing(1)};
    }
`
);

export const SelectImageModalItemContentStyled = styled("div")(
    ({ theme }) => `
    flex: 0 0 calc(100% - 50px);
    padding-left: ${theme.spacing(2)};
    p {
        line-height: 22px;
        font-weight: 600;
        margin: 0;
    }
    span {
        margin-top: ${theme.spacing(1)};
        font-size: 12px;
}]

    .label {
        margin-inline-end: 5px;
        font-weight: bold;
    }
`
);
