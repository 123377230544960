import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectOptionItem } from "@/types/select-option";
import { SelectFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: SelectFieldSchema["props"];
}

const FormSelect = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    return (
        <FormControl fullWidth variant="outlined" className={className} margin="normal">
            <InputLabel id={`select-label-${field.name}`}>
                {label} {props.required ? "*" : ""}
            </InputLabel>
            <Select {...field} labelId={`select-label-${field.name}`} {...props} placeholder={placeholder} label={label} defaultValue={field.value}>
                {data?.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormSelect;
