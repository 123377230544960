import {
    IGetCountriesListBody,
    IGetCountriesListResponse,
    IGetCountriesMiniListBody,
    IGetCountriesMiniListResponse,
    IGetCountryByIdResponse,
    IPutCountryByIdBody,
    IPutCountryByIdResponse,
    IAddCountryByIdBody,
    IAddCountryByIdResponse,
    IDeleteCountryByIdResponse,
} from "../types/countries";

import AxiosInstance from "@/api";

export const GetCountryById = async (id: number) => {
    const result = await AxiosInstance.get<IGetCountryByIdResponse>(`/countries/${id}`);

    return result.data;
};

export const UpdateCountryById = async (id: number, body: IPutCountryByIdBody) => {
    const result = await AxiosInstance.put<IPutCountryByIdResponse>(`/countries/${id}`, body);

    return result.data;
};

export const DeleteCountry = async (id: number) => {
    const result = await AxiosInstance.delete<IDeleteCountryByIdResponse>(`/countries/${id}`);

    return result.data;
};

export const CreateCountry = async (body: IAddCountryByIdBody) => {
    const result = await AxiosInstance.post<IAddCountryByIdResponse>(`/countries/create`, body);

    return result.data;
};

export const GetCountries = async (body: IGetCountriesListBody) => {
    const result = await AxiosInstance.post<IGetCountriesListResponse>(`/countries`, body);

    return result.data;
};

export const GetCountriesMiniList = async (body: IGetCountriesMiniListBody) => {
    const result = await AxiosInstance.post<IGetCountriesMiniListResponse>(`/countries/mini-list`, body);

    return result.data;
};
