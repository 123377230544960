import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { FilterBody } from "@/api/types";
import {
    IGenreCreatePayload,
    IGenreListResponse,
    IGenreUpdateResponse,
    IGenreUpdatePayload,
    IGenreDeleteResponse,
    IGenreCreateResponse,
    IGenreResponse,
} from "@/api/types/genre";
import { serializeObject } from "@/utils/serializer";

export async function GetGenreSlug({ slug, lang }: { lang?: string, slug: string }) {
    const result = await AxiosInstance.get<IGenreListResponse>(API_ROUTES.GENRE.SLUG(slug), { headers: { LANG: lang } });

    return result.data.data;
}

// TODO: dot it for later
export async function GetMiniGenres() {}

export async function GetGenre({ id, lang }: { id: number, lang?: string }) {
    const result = await AxiosInstance.get<IGenreResponse>(API_ROUTES.GENRE.SHOW(id), { headers: { LANG: lang } });

    return result.data.data;
}

export async function GetGenreList({ params }: { params: FilterBody }) {
    const result = await AxiosInstance.get<IGenreListResponse>(`${API_ROUTES.GENRE.LIST}?${serializeObject(params)}`);

    return result.data.data;
}

export async function UpdateGenre({ id, lang, body }: { id: number, lang?: string, body: IGenreUpdatePayload }) {
    const result = await AxiosInstance.put<IGenreUpdateResponse>(API_ROUTES.GENRE.UPDATE(id), body, {
        headers: { LANG: lang },
    });

    return result.data.data;
}

export async function DeleteGenre({ id, lang }: { id: number, lang?: string }) {
    const result = await AxiosInstance.delete<IGenreDeleteResponse>(API_ROUTES.GENRE.DELETE(id), {
        headers: { LANG: lang },
    });

    return result.data.data;
}

export async function CreateGenre({ lang, body }: { lang?: string, body: IGenreCreatePayload }) {
    const result = await AxiosInstance.post<IGenreCreateResponse>(API_ROUTES.GENRE.CREATE, body, {
        headers: { LANG: lang },
    });

    return result.data.data;
}
