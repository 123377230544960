import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";

import { VideoFormSchemaFields, videoSchema } from "./schema";
import { IVideoCreateFormType } from "./form-type";
import { createVideoValidationSchema } from "./validation";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { videoService } from "@/api/services/video";
import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { IFormGeneratorGeneralSchemaType } from "@/types/form-generator-schema-type";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const VideoForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const hasEditId = Boolean(id);
    const navigate = useNavigate();

    const [formFieldsSchema, setFormFieldsSchema] =
        useState<Record<VideoFormSchemaFields, IFormGeneratorGeneralSchemaType>>(videoSchema);
    const [loading, setLoading] = useState(hasEditId);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.VIDEO_LIST);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<IVideoCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(formFieldsSchema),
        mode: "all",
        resolver: yupResolver(createVideoValidationSchema),
    });

    const getEditData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await videoService.show(Number(id));

            const { videoId, thumbnail: Thumbnail, description, title } = response.data.data;

            reset({ title, videoId, description, thumbnailId: Thumbnail?.id });

            const thumbnail = response.data.data.thumbnail;
            const previewName = response.data.data.title;

            setFormFieldsSchema((prev) => ({
                ...prev,
                ...(thumbnail
                    ? {
                          thumbnailId: {
                              ...prev.thumbnailId,
                              props: {
                                  ...prev.thumbnailId.props,
                                  previewImage: thumbnail.url,
                                  previewSize: thumbnail.size,
                                  previewName: thumbnail.url.substring(thumbnail.url.lastIndexOf("/") + 1),
                              },
                          },
                      }
                    : undefined),
                ...(thumbnail
                    ? {
                          videoId: {
                              ...prev.videoId,
                              props: {
                                  ...prev.videoId.props,
                                  previewImage: thumbnail,
                                  previewName: previewName.substring(previewName.lastIndexOf("/") + 1),
                              },
                          },
                      }
                    : undefined),
            }));
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    }, [id, reset]);

    const onSubmit = async (_val: IVideoCreateFormType) => {
        try {
            setSubmitLoading(true);
            if (hasEditId) {
                await videoService.update(Number(id), _val);
            } else {
                await videoService.create(_val);
            }
            enqueueSnackbar(`Video ${hasEditId ? "updated " : "created"} successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        hasEditId && getEditData();
    }, [hasEditId, getEditData]);

    const footer = (
        <Stack direction={"row"} paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                {hasEditId ? "Update" : "Create"} Video
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${hasEditId ? "Update" : "Create"} Video`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Stack direction={"row"} spacing={2}>
                                  <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE}>
                                      Back To Video List
                                  </Button>
                              </Stack>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={formFieldsSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default VideoForm;
