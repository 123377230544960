import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import { SelectFieldSchema } from "@/types/form-generator-schema-type";
import { SelectOptionItem } from "@/types/select-option";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: SelectFieldSchema["props"];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const FormMultiSelect = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const handleChange = (event: SelectChangeEvent<typeof field.value>) => {
        const {
            target: { value },
        } = event;

        field.onChange(typeof value === "string" ? value.split(",") : value);
    };

    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            <InputLabel id={`select-label-${field.name}`}>
                {label} {props.required ? "*" : ""}
            </InputLabel>
            <Select
                multiple
                id={`select-label-${field.name}`}
                labelId={`select-label-${field.name}`}
                {...field}
                {...props}
                MenuProps={MenuProps}
                defaultValue={field.value || []}
                input={<OutlinedInput label={label} />}
                label={label}
                placeholder={placeholder}
                renderValue={(selected) =>
                    data
                        ?.filter((item) => selected.includes(item.value as string))
                        .map((item) => item.label)
                        .join(", ")
                }
                value={field.value || []}
                onChange={handleChange}
            >
                {data?.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        <Checkbox checked={field.value?.indexOf(value as string) > -1} />
                        <ListItemText primary={label} />
                    </MenuItem>
                ))}
            </Select>

            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormMultiSelect;
