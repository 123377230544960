import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { PasswordFieldSchema } from "@/types/form-generator-schema-type";
import { MouseEvent, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: PasswordFieldSchema["props"];
}
const FormPassword = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl fullWidth variant="outlined" className={className} margin="normal">
            <InputLabel htmlFor={`outlined-adornment-password-${field.name}`}>
                {label} {props.required ? "*" : ""}
            </InputLabel>
            <OutlinedInput
                id={`outlined-adornment-password-${field.name}`}
                type={showPassword ? "text" : "password"}
                fullWidth
                placeholder={placeholder}
                label={label}
                {...field}
                {...props}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormPassword;
