import { styled } from "@mui/material";

export const GenreHeaderStyle = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .page-title {
        font-size: 25px;
        font-weight: bold;
    }
`;

export const GenreFiltersStyle = styled("div")`
    margin: 1em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .filter-fields-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .actions {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .action-btn {
                margin-inline:5px ;
            }
        }

        .input {
            min-width: 240px;
        }

        .select {
            min-width: 180px;
        }

        .field {
            margin-inline: 5px;
        }
    }
`;

export const TableWrapperStyle = styled("div")`

    .table-value {
        width: 100%;
        height: 100%;
        max-height: 600px;
        display: flex;
        padding: 15px;

        &:hover {
            background-color: transparent;
        }
        
    }

    .table-row {

        &:hover {
            background-color: transparent;
        }

        .table-cell {
            padding:0;
            border:none;
            margin: 2px 0;

            &:first-of-type {
                .table-value {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
            }

            &:last-of-type {
                .table-value { 
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }

        .table-value {
            margin: 6px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#f5f5f5" : theme.palette.grey[800])};
        }

        /* &:nth-of-type(even) {
            .table-value {
                background-color: ${({ theme }) => (theme.palette.mode === "light" ? "#f5f5f5" : theme.palette.grey[800])};
            }
        } */
    }
`;
