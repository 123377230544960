import { styled } from "@mui/material";

export default styled("div")`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .header  {
        margin: 1em 0;
    }

    .title {
        font-weight: bold;
        margin: 1em 0;
    }
    

    .episode-card {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-inline-end: 1em;
        height: auto;
        max-width: 350px;
        min-height: 150px;
        border-radius: 16px;
        overflow: hidden;
        border: 1px solid ${({ theme }) => theme.palette.grey[300]};

        .middle, .footer {
            padding: 1em;
        }

        .header {

            img {
                width: 100%;
            }
        }

        .middle {

            /* background-color: ${({ theme }) => (theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[100])}; */

            ul {
                li {
                    width: 100%;

                    &:first-child {
                        margin-bottom: 10px;
                    }

                    .label {
                        font-size: 13px;
                    }

                    
                    .value {
                        margin-inline-start: 10px;
                        font-weight: bold;
                        font-size: 13px;
                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: space-between;
        }
    }

    .show-episode-list {
        width: 100%;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: flex-start;
    }
`;

type background = { src: string };
export const EpisodeHeaderStyle =
    styled("div") <
    background >
    `
    background: url(${({ src }) => src || ""}) no-repeat;
    background-size: cover;
    height: 150px;
`;
