import { userService } from "@/api/services/user";
import Table from "@/components/kit/Table";
import { ITableParams } from "@/types/table";
import { userListColumnSchema } from "./column-schema";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { Button, Chip, Switch } from "@mui/material";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { GridActionsCellItem, GridRenderCellParams, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { IUserListResponse } from "@/api/types/user";
import { Dispatch, SetStateAction, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useUser } from "@/context/authContext";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { USER_ROLE } from "@/enums/user-role";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { PERMISSIONS } from "@/enums/permissions";

const service = async (queryParams?: ITableParams) => userService.list(queryParams);

const UserList: React.FC = () => {
    const user = useUser();
    const currentUserId = user?.id;
    const [loading, setLoading] = useState(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const isAdministrator = user?.role === USER_ROLE.ADMINISTRATOR;
    const createAccess = checkPermissionAccess(PERMISSIONS.USER_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.USER_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.USER_DELETE);

    const suffixCols = (tableData: IUserListResponse[], setTableData: Dispatch<SetStateAction<IUserListResponse[]>>): GridColDef[] => {
        const handleDelete = (id: number) => {
            const handleAction = async () => {
                try {
                    setLoading(true);
                    await userService.delete(id);
                    enqueueSnackbar(`User deleted successfully`, { variant: "success" });
                    setTableData((prev) => (prev ? prev.filter((item) => item.id !== id) : prev));
                } catch (err) {
                    const error = getAxiosError(err);
                    const message = error?.meta.message || "Server Error";
                    enqueueSnackbar(message, { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Delete Confirmation",
                text: "Are you sure to delete this user?",
                handleAction,
            });
        };

        const handleActive = async (id: number, checked: boolean) => {
            try {
                setLoading(true);
                await userService.activation(id, { active: checked });
                enqueueSnackbar(`User ${checked ? "activated" : "inactivated"} successfully`, { variant: "success" });
                setTableData((prev) => (prev ? prev.map((item) => (item.id === id ? { ...item, active: checked } : item)) : prev));
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";
                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        return [
            {
                field: "active",
                headerName: "Status",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                headerAlign: "center",
                sortable: false,
                display: "flex",
                renderCell(params: GridRenderCellParams<IUserListResponse>) {
                    const isCurrentUser = params.row.id === currentUserId;
                    const statusIcon = (
                        <Chip
                            label={params.row.active ? "Active" : "DeActive"}
                            color={params.row.active ? "success" : "error"}
                            icon={params.row.active ? <CheckCircleIcon /> : <CancelIcon />}
                            size="small"
                            variant="outlined"
                        />
                    );

                    return isAdministrator ? (
                        isCurrentUser ? (
                            statusIcon
                        ) : (
                            <Switch
                                size="small"
                                color="success"
                                checked={params.row.active}
                                onChange={(e) => handleActive(params.row.id, e.currentTarget.checked)}
                            />
                        )
                    ) : (
                        statusIcon
                    );
                },
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                sortable: false,
                getActions: (params: GridRowParams<IUserListResponse>) => {
                    const isCurrentUser = params.row.id === currentUserId;
                    const editRule = editAccess || (!editAccess && isCurrentUser);

                    return [
                        ...(editRule
                            ? [
                                  <Link to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.UPDATE.EDIT_BY_ID(params.row.id).ABSOLUTE}>
                                      <GridActionsCellItem icon={<EditIcon color="action" />} label="Edit" color="inherit" />
                                  </Link>,
                              ]
                            : [<GridActionsCellItem disabled icon={<EditIcon color="disabled" />} label="Edit" color="inherit" />]),
                        ...(deleteAccess
                            ? [
                                  <GridActionsCellItem
                                      disabled={isCurrentUser}
                                      icon={<DeleteIcon color={isCurrentUser ? "disabled" : "action"} />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                      color="inherit"
                                  />,
                              ]
                            : []),
                    ];
                },
            },
        ];
    };

    return (
        <>
            <DashboardHeader
                title="User List"
                titleSuffix={
                    createAccess ? (
                        <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.USERS.ADD_NEW.ROOT.ABSOLUTE} color="primary" variant="contained">
                            Add New User
                        </Button>
                    ) : null
                }
            />
            <DashboardContent>
                <Table columnsSchema={userListColumnSchema} service={service} loading={loading} suffixCols={suffixCols} />
            </DashboardContent>
        </>
    );
};

export default UserList;
