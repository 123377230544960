import dayjs from "dayjs";
import { useState } from "react";
import { BorderColor, Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { TableOfContentStyles } from "./style";
import RateInput from "./rateInput";

import { useUpdateCountry } from "@/api/hooks/useCountries";
import { ICountry } from "@/api/types/countries";

export default function TableOfContent({ result }: { result: ICountry }) {
    const [editMode, setEditModeState] = useState<boolean>(false);
    const { mutate: updateCountry } = useUpdateCountry();

    const handleEditMode = () => setEditModeState((prevState) => !prevState);

    const handleUpdateCountry = (rateInput: string) => {
        const newDate = {
            ...result,
            rate: Number(rateInput),
        };

        updateCountry({ id: Number(result.id), body: newDate }, { onSuccess: () => setEditModeState(false) });
    };

    const {
        code2Char,
        code3Char,
        createdAt,
        currency,
        currencyIso,
        decimal,
        dialCode,
        flag,
        flagUrl,
        gmtOffset,
        isActive,
        rate,
        timeZone,
        updatedAt,
    } = result;

    return (
        <TableOfContentStyles className="table-of-content">
            <div>
                <span className="label">createdAt</span>
                <span>{dayjs(createdAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div>
                <span className="label">updatedAt</span>
                <span>{dayjs(updatedAt).format("YYYY-MM-DD HH:mm")}</span>
            </div>
            <div>
                <span className="label">code2Char</span>
                <span>{code2Char}</span>
            </div>
            <div>
                <span className="label">code3Char</span>
                <span>{code3Char}</span>
            </div>
            <div>
                <span className="label">decimal</span>
                <span>{decimal}</span>
            </div>
            <div>
                <span className="label">currency</span>
                <span>{currency}</span>
            </div>
            <div>
                <span className="label">Rate</span>
                <span>
                    {!editMode ? (
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <span style={{ marginInline: "3px" }}>{rate}</span>
                            <IconButton onClick={handleEditMode}>{editMode ? <Close /> : <BorderColor />}</IconButton>
                        </span>
                    ) : (
                        <RateInput value={String(rate)} onSubmit={handleUpdateCountry} />
                    )}
                </span>
            </div>
            <div>
                <span className="label">currencyIso</span>
                <span>{currencyIso}</span>
            </div>
            <div>
                <span className="label">dialCode</span>
                <span>{dialCode}</span>
            </div>
            <div>
                <span className="label">flag</span>
                <span>{flag}</span>
            </div>
            <div>
                <span className="label">gmtOffset</span>
                <span>{gmtOffset}</span>
            </div>
            <div>
                <span className="label">Image</span>
                <span>
                    <img alt="flag" src={flagUrl} />
                </span>
            </div>
            <div>
                <span className="label">isActive</span>
                <span>{isActive ? " Active " : "Not Active"}</span>
            </div>
            <div>
                <span className="label">timeZone</span>
                <span>{timeZone}</span>
            </div>
        </TableOfContentStyles>
    );
}
