import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type CustomerFormSchemaFields = "firstName" | "lastName" | "email" | "phoneNumber";

export type ICustomerFormSchema = Record<CustomerFormSchemaFields, IFormGeneratorSchemaType>;

export const customerSchema: ICustomerFormSchema = {
    firstName: {
        label: "First Name",
        placeholder: "First Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    lastName: {
        label: "Last Name",
        placeholder: "Last Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    email: {
        label: "Email",
        placeholder: "Email",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "email",
        },
    },
    phoneNumber: {
        label: "Phone Number",
        placeholder: "Phone Number",
        type: FORM_INPUTS.PHONE,
        props: {
            type: "number",
            disabled: true,
        },
    },
};
