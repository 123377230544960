import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type ForgetPasswordFormSchemaFields = "email";

export type IForgetPasswordFormSchema = Record<ForgetPasswordFormSchemaFields, IFormGeneratorSchemaType>;

export const forgetPasswordSchema: IForgetPasswordFormSchema = {
    email: {
        label: "Email",
        placeholder: "Email",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "email",
            autoFocus: true,
        },
    },
};
