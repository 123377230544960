import React, { useEffect } from "react";

import { useAuth } from "@/hooks/useAuth";
import InitLoading from "@/components/app/Loading/InitLoading";
import { useUser } from '@/context/authContext'
import { useGeneralLoading } from "@/context/global-context";
import { getAuthorization } from "@/utils/storageFactory";
import { IFunctionalComponentProps } from "@/types/functional-component";

const GeneralLayoutWrapper: React.FC<IFunctionalComponentProps> = ({ children }) => {
    const user = useUser();
    const { getProfile } = useAuth();
    const loading = useGeneralLoading();


    useEffect(() => {
        if (!user) {
            const jwt = getAuthorization();
            if (jwt)  getProfile();
        }
    }, [user, getProfile]);

    return (
        <>
            {loading && <InitLoading height="100vh" />}
            {!loading && children}
        </>
    );
};

export default GeneralLayoutWrapper;
