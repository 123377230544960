import { IModalProps } from "@/types/modals";
import { ImagePreviewModalStyled } from "./styled";

export interface ImagePreviewModalProps {
    imageUrl: string;
}
const ImagePreviewModal: React.FC<IModalProps<ImagePreviewModalProps>> = ({ data: { imageUrl }, closeModal }) => {
    return (
        <ImagePreviewModalStyled>
            <img src={imageUrl} alt="imagePreview" />
        </ImagePreviewModalStyled>
    );
};

export default ImagePreviewModal;
