import { HeadCell } from "./types";

const headCells: readonly HeadCell[] = [
    {
        id: "id",
        numeric: true,
        disablePadding: true,
        label: "ID",
    },
    {
        id: "en_title",
        numeric: false,
        disablePadding: true,
        label: "English Title",
    },
    {
        id: "ar_title",
        numeric: false,
        disablePadding: true,
        label: "Arabic Title",
    },
    {
        id: "slug",
        numeric: false,
        disablePadding: true,
        label: "Slug",
    },
    {
        id: "category",
        numeric: false,
        disablePadding: true,
        label: "Category",
    },
    {
        id: "updated_at",
        numeric: false,
        disablePadding: true,
        label: "Updated At",
    },
];

export default headCells;
