import { CATEGORY } from "@/enums/home-page-ui";
import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

export const homeListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "name",
        headerName: "UI Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "category",
        headerName: "Category",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams) {
            if (params.value === CATEGORY.EDUCATION) return <Chip label="Education" color="success" size="small" variant="outlined" />;
            if (params.value === CATEGORY.ENTERTAINMENT) return <Chip label="Entertainment" color="info" size="small" variant="outlined" />;
        },
    },
];
