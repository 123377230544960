import { styled } from "@mui/material";

export default styled("div")`

    width: 100%;
    margin: 0 auto;
    max-width: 800px;
    padding-bottom: 2em;

    form {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
`;

export const FeatureComponentStyle = styled("div")`
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 16px;
    background-color: ${({ theme }) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800])};

    .content {
        display: flex;
        padding: 0 1em;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .inputs {
            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-direction: row;
        }
    }


    .features-list {
        width: 100%;
        padding: 0 1em;

        .features-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 10px;
            border-radius: 16px;
            margin-bottom: 10px;
            overflow: hidden;
            position: relative;
            background-color: ${({ theme }) => (theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[600])};


            .content {
                display: flex;
                flex-direction: column;
                overflow:hidden;
                max-width: 100%;
                padding-right: 100px;

                .text {
                    display: inline-flex;
                    width: 100%;
                    max-width: 100%;
                    text-overflow:ellipsis;
                    font-size: 13px;
                    margin: 0;
                    padding: 5px 15px
                }
            }

            .actions {
                max-width: 100px;
                min-width: 100px;
                width: 100px;
                position: absolute;
                right:0;
                top:0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon-btn {
                    font-size: 13px;
                    padding: 0;
                    padding: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

`;
