export function serializeObject(obj: Record<string, any>): string {
    const queryString: string[] = [];

    for (const [key, value] of Object.entries(obj)) {
        // Skip keys with undefined, null, or empty values
        if (value === null || value === undefined || value === "") {
            continue;
        }

        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            // Serialize nested objects with encoding
            const nestedString = Object.entries(value)
                .map(
                    ([nestedKey, nestedValue]) =>
                        `${encodeURIComponent(nestedKey)}='${encodeURIComponent(String(nestedValue))}'`
                )
                .join(",");

            queryString.push(`${key}=${encodeURIComponent(nestedString)}`);
        } else {
            queryString.push(`${key}=${encodeURIComponent(String(value))}`);
        }
    }

    return queryString.join("&");
}
