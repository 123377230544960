import * as yup from "yup";

export default yup.object().shape({
    en_title: yup
        .string()
        .min(2, "English title must be at most 2 characters")
        .max(80, "English title must be at least 80 characters")
        .required("English title is required"),
    ar_title: yup
        .string()
        .required("Arabic title is required")
        .min(2, "Arabic title must be at most 2 characters")
        .max(80, "Arabic title must be at least 80 characters"),
    en_description: yup.string().max(600, "English description must be at least 600 characters").required("required"),
    ar_description: yup.string().max(600, "Arabic description must be at least 600 characters").required("required"),
    ml_features: yup
        .array(
            yup.object({
                en: yup.string().required("required"),
                ar: yup.string().required("required"),
            })
        )
        .min(1, "at least 1 item is required")
        .required(),
    showInWeb: yup.boolean().required("required"),
    showInApp: yup.boolean(),
    googleProductId: yup.string(),
    appleProductId: yup.string(),
    isActive: yup.boolean().required("required"),
    hasTrial: yup.boolean().required("required"),
    currency: yup.string().oneOf(["KWD", "BHD", "OMR", "AED", "SAR", "USD"]).required("required"),
    price: yup.number().positive("the number must be positive").required("required"),
    period: yup.number().required("required"),
    trialPeriod: yup.number().required("required"),
});
