import { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import ShowEpisodes from "./showEpisodes";
import EachSessionStyle from "./style";
import FormModal from "./formModal";

import { useContentList, useDeleteContent } from "@/api/hooks/useContents";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

interface IEachSession {
    sessionId: number;
}

export default function EachSession(props: IEachSession) {
    const { sessionId } = props;
    const [open, setOpen] = useState(false);
    const [editEpisodeId, setEditEpisodeId] = useState<number | null>(null);
    const { deleteContent } = useDeleteContent();

    const { enqueueSnackbar } = useSnackbar();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    // const createAccess = checkPermissionAccess(PERMISSIONS.CONTENT_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.CONTENT_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.CONTENT_DELETE);

    const handleDelete = (id: number) => {
        if (!deleteAccess) {
            enqueueSnackbar("You don't have access for deleting an episode");

            return;
        }
        deleteContent(id, { onSuccess: () => reFetch() });
    };
    const { contentList: episodeList, reFetch } = useContentList(Number(sessionId));

    const maxSessionNumber = episodeList.sort(function (a, b) {
        if (Number(a.id) < Number(b.id)) return -1;
        if (Number(a.id) > Number(b.id)) return 1;

        return 0;
    });

    const maxNumber = maxSessionNumber[maxSessionNumber.length - 1];

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        if (editEpisodeId) setEditEpisodeId(null);
        setOpen(false);
    };

    const handleEdit = (episodeId: number) => {
        if (!editAccess) {
            enqueueSnackbar("You don't have access for editing an episode");

            return;
        }
        setEditEpisodeId(episodeId);
    };

    return (
        <EachSessionStyle className="each-session">
            <Box className="header">
                <LoadingButton className="episode-btn" type="submit" variant="contained" onClick={handleClickOpen}>
                    Create Episode
                </LoadingButton>
            </Box>

            <Divider />
            {episodeList.length === 0 ? (
                <Typography sx={{ margin: "1em 0" }}>You did not define any Episodes yet</Typography>
            ) : (
                <>
                    <Typography className="title">Episodes List</Typography>
                    <ShowEpisodes data={episodeList} onDelete={handleDelete} onEdit={handleEdit} />
                </>
            )}

            <FormModal
                episodeId={editEpisodeId}
                maxNumber={maxNumber?.episode || 0}
                open={open || !!editEpisodeId}
                parentId={sessionId}
                refetch={reFetch}
                onClose={handleClose}
            />
        </EachSessionStyle>
    );
}
