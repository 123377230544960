import dayjs from "dayjs";

import { Data, Order } from "./table/types";

import { IGenre } from "@/api/types/genre";

export function createData(data: IGenre): Data {
    const { id, ml_title, isEducation, updatedAt, slug } = data;
    const { en, ar } = ml_title;

    return {
        id,
        en_title: en,
        ar_title: ar,
        slug,
        category: isEducation ? "Education" : "Entertainment",
        updated_at: dayjs(updatedAt).format("YYYY-MM-DD HH:mm"),
    };
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
    // eslint-disable-next-line no-unused-vars
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
