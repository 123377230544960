import { IStaticListResponse } from "@/api/types/static";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

export const staticListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "ml_title.en",
        headerName: "English Title",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IStaticListResponse>) {
            return params.row.ml_title.en;
        },
    },
    {
        field: "ml_title.ar",
        headerName: "Arabic Title",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IStaticListResponse>) {
            return params.row.ml_title.ar;
        },
    },
    {
        field: "slug",
        headerName: "Slug",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IStaticListResponse>) {
            return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
    },
];
