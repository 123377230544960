import { styled } from "@mui/material";

export default styled("div")(
    () => `
    width: 100%;
    display: flex;
    flex-direction: column;

    
`
);
