import { Controller, FieldError, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";

import { ICreateSeriesForm } from "./type";
import Validation from "./validation";

import FormTextarea from "@/components/kit/FormGenerator/FormTextarea";
import FormText from "@/components/kit/FormGenerator/FormText";
import { useContentById } from "@/api/hooks/useContents";
import FormCheckbox from "@/components/kit/FormGenerator/FormCheckbox";

const defaultValues = {
    en_title: "",
    ar_title: "",
    en_description: "",
    ar_description: "",
    season: 1,
    autoId: false,
};

export default function AddSessions({
    onSubmit,
    id,
}: {
    id?: number | null,
    // eslint-disable-next-line no-unused-vars
    onSubmit: (formData: ICreateSeriesForm) => void,
}) {
    const { data } = useContentById(Number(id));

    const content = data?.data.data;

    const {
        control,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm<ICreateSeriesForm>({
        defaultValues,
        mode: "all",
        resolver: yupResolver(Validation),
    });

    useEffect(() => {
        if (!id) reset(defaultValues);
    }, [reset, id]);

    // update form data in Edit mode
    useEffect(() => {
        // make sure genre & tags & ageRange are fetched before
        if (content) {
            const { ml_description, ml_name, season } = content;

            reset({
                season,
                ar_title: ml_name.ar,
                en_title: ml_name.en,
                ar_description: ml_description.ar,
                en_description: ml_description.en,
            });
        }
    }, [content, reset]);

    const autoId = watch("autoId");

    return (
        <Box className="add-series-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography sx={{ fontSize: "12px" }}>
                    If you want the Session numbers to be consecutive, you must enter the episode number yourself.
                </Typography>
                <Controller
                    key="autoId"
                    control={control}
                    name="autoId"
                    render={({ field }) => (
                        <FormCheckbox
                            className="textarea"
                            errorMessage={errors["autoId"] as FieldError}
                            field={{ ...field }}
                            label="Add Session number automatically (Do not care about the order)"
                            placeholder="Add Session number automatically (Do not care about the order)"
                        />
                    )}
                />

                <Controller
                    key="season"
                    control={control}
                    disabled={autoId}
                    name="season"
                    render={({ field }) => (
                        <FormText
                            className="textarea"
                            errorMessage={errors["season"] as FieldError}
                            field={{ ...field }}
                            label="Enter Season No"
                            placeholder="Enter Season No"
                            props={{ type: "number" }}
                        />
                    )}
                />
                <Controller
                    key="en_title"
                    control={control}
                    name="en_title"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["en_title"] as FieldError}
                            field={{ ...field }}
                            label="English Content Name *"
                            placeholder="Video File"
                        />
                    )}
                />
                <Controller
                    key="ar_title"
                    control={control}
                    name="ar_title"
                    render={({ field }) => (
                        <FormText
                            errorMessage={errors["ar_title"] as FieldError}
                            field={{ ...field }}
                            label="Arabic Content Name *"
                            placeholder="Video File"
                        />
                    )}
                />
                <Controller
                    key="en_description"
                    control={control}
                    name="en_description"
                    render={({ field }) => (
                        <FormTextarea
                            className="textarea"
                            errorMessage={errors["en_description"] as FieldError}
                            field={{ ...field }}
                            label="English Description"
                            placeholder="Video File"
                        />
                    )}
                />
                <Controller
                    key="ar_description"
                    control={control}
                    name="ar_description"
                    render={({ field }) => (
                        <FormTextarea
                            className="textarea"
                            errorMessage={errors["ar_description"] as FieldError}
                            field={{ ...field }}
                            label="Arabic Description"
                            placeholder="Enter Text"
                        />
                    )}
                />
                <LoadingButton className="loading-btn" type="submit" variant="contained">
                    {id ? "Update" : "Create"} Content
                </LoadingButton>
            </form>
        </Box>
    );
}
