import { alpha, styled } from "@mui/material";

export const SidebarMenuItemsStyled = styled("aside")(
    ({ theme }) => `
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    >ul {
        >li {
            position: relative;
            margin-top: ${theme.spacing(0.5)};

            >svg {
                position: absolute;
                right: ${theme.spacing(2)};
                top: ${theme.spacing(1.5)};
                color: ${alpha(theme.palette.common.white, 0.8)};
                cursor: pointer;
                z-index: 100
            }

            >a {
                display: flex;
                align-items: center;
                line-height: 48px;
                min-height: 48px;
                padding: 0 ${theme.spacing(2)};
                color: ${alpha(theme.palette.common.white, 0.85)};
                font-size: 14px;
                font-weight: 600;
                border-radius: ${theme.spacing(6)};
                transition: 0.15s;

                >span {
                    margin-left: ${theme.spacing(2)};
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                >svg {
                    flex: 0 0 20px;
                }

                &.active {
                    background: ${alpha(theme.palette.common.white, 0.15)};
                    color: ${theme.palette.common.white};

                    >svg {
                        stroke: ${theme.palette.common.white};
                    }
                }
            }
            &:hover {
                > a {
                    background: ${alpha(theme.palette.common.white, 0.15)};
                    color: ${theme.palette.common.white};

                    >svg {
                        stroke: ${theme.palette.common.white};
                    }
                }
            }

            >span {
                cursor: pointer;
            }

            ul {
                margin-left: ${theme.spacing(4)};

                >li {
                    position: relative;
                    margin-top: ${theme.spacing(0.5)};

                    >a {
                        display: flex;
                        align-items: center;
                        line-height: 40px;
                        padding: 0 ${theme.spacing(2)};
                        color: ${theme.palette.common.white};
                        font-size: 14px;
                        font-weight: 400;
                        border-radius: ${theme.spacing(5)};
                        transition: 0.15s;

                        >span {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        &.active {
                            background: ${alpha(theme.palette.primary.main, 0.15)};
                            color: ${theme.palette.common.white};
                        }
                    }
                    &:hover {
                        > a {
                            background: ${alpha(theme.palette.primary.main, 0.15)};
                            color: ${theme.palette.common.white};
        
                            >svg {
                                stroke: ${theme.palette.common.white};  
                            }
                        }
                    }

                    >svg {
                        position: absolute;
                        right: ${theme.spacing(2)};
                        top: ${theme.spacing(2)};
                        cursor: pointer;
                        z-index: 100
                    }
                }
            }

            >ul {
                >li {

                    ul {
                        margin-left: ${theme.spacing(4)};

                        li {
                            margin-top: ${theme.spacing(0.5)};
                        }
                    }
                }
            }
        }

        ul {
            display: none;
        }

        li {
            &.active {
                >ul {
                    display: block;
                }
            }
        }
    }

    &.minimized {
        >ul {
            >li {
                >ul {
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: calc(100% + 45px);
                    top: 0;
                    box-shadow: 0px 0px 32px 0px rgba(15, 98, 243, 0.15);
                    background: ${theme.palette.common.white};
                    border-radius: ${theme.spacing(2)};
                    padding: ${theme.spacing(2)};
                    width: 228px;
                    margin: 0;
                    margin-left: -10px;
                    transition: 0.15s 0.1s;

                    &:before {
                        content: attr(data-title);
                        line-height: 34px;
                        margin-bottom: ${theme.spacing(1)};
                        padding: 0 ${theme.spacing(2)};
                        color: ${theme.palette.common.black};
                        font-size: 14px;
                        font-weight: 600;
                        display: block;
                    }
                    > li {
                        > a {
                            color: ${theme.palette.common.black};
                            &.active {
                                color: ${theme.palette.common.black};
                            }
                        }
                        &:hover {
                            > a {
                                color: ${theme.palette.common.black};
            
                                >svg {
                                    stroke: ${theme.palette.common.black};  
                                }
                            }
                        }
                    }
                }

         
                
                &:hover {

                    &:after {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 45px;
                        bottom: 0;
                        content: '';
                    }
                    >ul {
                        opacity: 1;
                        visibility: visible;
                        margin-left: 0;
                    }
                }
            }

            >li {
                >svg {
                    display: none;
                }

                >a {
                    padding: 0;
                    justify-content: center;

                    span {
                        display: none;
                    }
                }
            }
        }
    }

`
);
