import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { CircularProgress, FormHelperText } from "@mui/material";
import { VideoFieldSchema } from "@/types/form-generator-schema-type";
import { DeleteIconStyled, FormVideoInputStyled, FormVideoStyled, FormVideoUploadStyled, FormVideoWrapperStyled } from "./styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef, useState } from "react";
import { useVideo } from "@/hooks/useVideo";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: VideoFieldSchema["props"];
}
const FormVideo = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewName, ...inputProps } = props || {};
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { uploadVideo, uploadedPercent } = useVideo();

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string | undefined>(previewName || "");

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const { videoId, title, assets } = await uploadVideo(event.target.files[0]);

            setFileName(title);
            field.onChange(videoId);
            assets?.thumbnail && setImageSource(assets.thumbnail);
        }
    };

    const handleRemove = () => {
        setFileName("");
        field.onChange(undefined);
        setImageSource("");
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    };

    return (
        <>
            <FormVideoStyled className={className}>
                <label>
                    {label} {props?.required ? "*" : ""}
                </label>
                <FormVideoWrapperStyled>
                    {uploadedPercent && uploadedPercent <= 100 ? (
                        <FormVideoUploadStyled>
                            <CircularProgress />
                            {uploadedPercent ? <span>{uploadedPercent.toFixed(2)}% uploaded</span> : null}
                        </FormVideoUploadStyled>
                    ) : (
                        <>
                            {imageSource.length ? <img src={imageSource} alt={label} /> : null}
                            {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                            {!fileName?.length ? (
                                <FormVideoUploadStyled>
                                    <CloudUploadIcon />
                                    <span>Choose Video ...</span>
                                    {placeholder ? <small>{placeholder}</small> : null}
                                </FormVideoUploadStyled>
                            ) : null}
                        </>
                    )}
                    <FormVideoInputStyled type="file" ref={inputFileRef} onChange={handleOnChange} {...inputProps} />
                </FormVideoWrapperStyled>
                {fileName ? <span>{fileName?.length ? fileName : null}</span> : null}
            </FormVideoStyled>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </>
    );
};

export default FormVideo;
