import React from "react";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import { useMenuActions, useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/enums/menu";
import NizekLogo from "@/assets/svg/nizek-logo";
import LogoWhite from "@/components/app/LogoWhite";
import { SidebarLogoStyled, SidebarMenuStyled, SidebarNizekLogoStyled, SidebarStyled, SidebarToggleStyled } from "./styled";
import SidebarMenu from "./SidebarMenu";

const Sidebar: React.FC = () => {
    const { toggleMenuView } = useMenuActions();
    const menuView= useMenuView();
    const ToggleIcon = menuView === MENU_VIEW.MAXIMIZE ? KeyboardDoubleArrowLeftIcon : KeyboardDoubleArrowRightIcon;
    const isMinimizedMenu = menuView === MENU_VIEW.MINIMIZE;
    const minimizedClassName = isMinimizedMenu ? "minimize" : "";

    return (
        <SidebarStyled className={minimizedClassName}>
            <SidebarLogoStyled className={minimizedClassName}>
                <LogoWhite />
            </SidebarLogoStyled>
            <SidebarMenuStyled className={minimizedClassName}>
                <SidebarMenu />
            </SidebarMenuStyled>
            <SidebarToggleStyled className={minimizedClassName}>
                <ToggleIcon onClick={toggleMenuView} />
            </SidebarToggleStyled>
            <SidebarNizekLogoStyled className={minimizedClassName}>
                <span>With ❤️ By</span>
                <a href="https://nizek.com" target="_blank">
                    <NizekLogo height={12} />
                </a>
            </SidebarNizekLogoStyled>
        </SidebarStyled>
    );
};

export default Sidebar;
