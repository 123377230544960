import { styled } from "@mui/material";

export default styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: column;
    alignItems: center;
    justify-content:center;
    max-width: 500px;
`
);

export const ModalHeaderStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.palette.mode === "dark" ? "#494949" : "#eee"};
    h5 {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
    }
    svg {
        margin-left: auto;
        cursor: pointer
    }
`
);
export const ModalContentStyled = styled("div")(
    ({ theme }) => `
    padding: ${theme.spacing(2)};
    max-height: calc(100vh - 200px);
    overflow: auto;
    > p {
        margin-top: 0;
    }
`
);

export const ModalFooterStyled = styled("div")(
    ({ theme }) => `
     padding: ${theme.spacing(2)};
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
`
);
