import React, { Fragment } from "react";
import { IMenuData } from "./types";
import MenuItem from "./SidebarMenuItem";
import menuData from "./menu-data";
import { MenuProps } from "@/types/menu";
import { SidebarMenuItemsStyled } from "./styled";
import { MENU_VIEW } from "@/enums/menu";
import { useMenuView } from "@/context/menu";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

const SidebarMenu: React.FC = () => {
    const menuView = useMenuView();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const recursivelyRenderMenuItem = (childrenData: IMenuData) => {
        const { children, permission, ...menuItemProps } = childrenData;
        const hasValidPermission = permission?.length && permission.some((permission) => checkPermissionAccess(permission));
        if (!hasValidPermission) return <></>;

        const componentProps = {
            ...menuItemProps,
            ...(children && {
                children: children.map((innerChildrenData) => <Fragment key={innerChildrenData.id}>{recursivelyRenderMenuItem(innerChildrenData)}</Fragment>),
            }),
        } as MenuProps;

        return <MenuItem {...componentProps} />;
    };

    return (
        <SidebarMenuItemsStyled className={menuView === MENU_VIEW.MINIMIZE ? "minimized" : ""}>
            <ul>
                {menuData.map((menuItems: IMenuData) => (
                    <Fragment key={menuItems.id}>{recursivelyRenderMenuItem(menuItems)}</Fragment>
                ))}
            </ul>
        </SidebarMenuItemsStyled>
    );
};

export default SidebarMenu;
