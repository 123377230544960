import { styled } from "@mui/material";

export const AvatarListStyled = styled("div")(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex-direction: column;
    
`
);
export const AvatarListGridStyled = styled("div")<{ loading: boolean }>(
    ({ theme, loading }) => `
    filter: ${loading ? "blur(5px)" : "unset"};
    opacity: ${loading ? "0.6" : "unset"};
`
);
export const AvatarListPaginationStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-top; ${theme.spacing(1)};
`
);
export const AvatarDeleteModalStyled = styled("div")(
    ({ theme }) => `
    ul {
        padding-left: ${theme.spacing(3)};
        li {
            list-style: circle
        }
    }
    a {
        color: ${theme.palette.mode === "dark" ? theme.palette.primary.dark : theme.palette.primary.light};
        font-weight: 600;
    }
`
);
