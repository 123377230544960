import { Button, Grid, Pagination } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";

import { ImageDeleteModalStyled, ImageListGridStyled, ImageListPaginationStyled, ImageListStyled } from "./styled";

import { ITableParams, ITableResponse } from "@/types/table";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import { imageService } from "@/api/services/image";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IImageListResponse } from "@/api/types/image";
import { getAxiosError } from "@/utils/get-axios-error";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import GridCard from "@/components/kit/GridCard";
import InitLoading from "@/components/app/Loading/InitLoading";
import { GRID_PER_PAGE } from "@/constants/grid-view-configs";
import { routesByEntity } from "@/constants/routes-by-entity";

const ImageList: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ITableResponse<IImageListResponse>>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const deleteAccess = checkPermissionAccess(PERMISSIONS.IMAGE_DELETE);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await imageService.list({ ...queryParams, take: GRID_PER_PAGE });

            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    }, [queryParams]);

    const handleDelete = (id: number) => {
        const handleAction = async () => {
            try {
                setLoading(true);
                const response = await imageService.delete(id);

                if (response.data.data.assignedTo?.length) {
                    setTimeout(() => {
                        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                            header: "Warning: Image in use!",
                            text: (
                                <ImageDeleteModalStyled>
                                    <p>
                                        You are about to delete this image. However, this image is currently being used by
                                        the following items. If you wish to delete it, you must first unassign the image from
                                        the content.
                                    </p>
                                    <ul>
                                        {response.data.data.assignedTo?.map((item, index) => (
                                            <li key={index}>
                                                <Link target="_blank" to={routesByEntity[item.entity](item.id)}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </ImageDeleteModalStyled>
                            ),
                            cancelButtonText: "Close",
                            disableSubmitButton: true,
                        });
                    }, 300);
                } else {
                    enqueueSnackbar(`Image deleted successfully`, { variant: "success" });
                    setData((prev) => (prev ? { ...prev, items: prev.items.filter((item) => item.id !== id) } : prev));
                }
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";

                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
            header: "Delete Confirmation",
            text: "Are you sure to delete this image?",
            handleAction,
        });
    };

    const handleOpen = (imageUrl: string) => {
        modalActions.addModal(MODAL_TYPES.IMAGE_PREVIEW, {
            header: "Image Preview",
            width: "832px",
            imageUrl,
        });
    };

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: GRID_PER_PAGE, skip: (page - 1) * GRID_PER_PAGE }));
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading && !data) return <InitLoading />;

    return (
        <>
            <DashboardHeader
                title="Image List"
                titleSuffix={
                    <Button
                        color="primary"
                        component={Link}
                        to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.IMAGES.ADD_NEW.ROOT.ABSOLUTE}
                        variant="contained"
                    >
                        Add New Image
                    </Button>
                }
            />
            <DashboardContent>
                <ImageListStyled>
                    <ImageListGridStyled loading={loading}>
                        <Grid container spacing={4}>
                            {data?.items.map((item) => (
                                <Grid key={item.id} item lg={2} md={3} xs={6}>
                                    <GridCard
                                        actions={
                                            <>
                                                <PreviewIcon color="inherit" onClick={() => handleOpen(item.url)} />
                                                {deleteAccess ? (
                                                    <DeleteIcon color="inherit" onClick={() => handleDelete(item.id)} />
                                                ) : null}
                                            </>
                                        }
                                        image={item.url}
                                        title={item.title.length ? item.title : item.originalName}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </ImageListGridStyled>
                    {data?.pagination && data.pagination?.totalItems > GRID_PER_PAGE ? (
                        <ImageListPaginationStyled>
                            <Pagination
                                count={data.pagination.totalPages}
                                disabled={loading}
                                page={data.pagination.currentPage}
                                onChange={(e, page) => handlePaginationModelChange(page)}
                            />
                        </ImageListPaginationStyled>
                    ) : null}
                </ImageListStyled>
            </DashboardContent>
        </>
    );
};

export default ImageList;
