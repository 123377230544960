import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@mui/material";

import { SelectOptionItem } from "@/types/select-option";
import { CheckboxFieldSchema } from "@/types/form-generator-schema-type";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    data?: SelectOptionItem[];
    className?: string;
    errorMessage: FieldError;
    props?: CheckboxFieldSchema["props"];
}

const FormCheckbox = ({ field, label, placeholder, data, className, errorMessage, props = {} }: IProps) => {
    const handleCheckboxChange = (value: string | number) => {
        if (Array.isArray(field.value)) {
            const newValue = field.value.includes(value)
                ? field.value.filter((v) => v !== value)
                : [...field.value, value];

            field.onChange(newValue);
        } else {
            field.onChange(value);
        }
    };

    return (
        <FormControl fullWidth className={className} margin="normal" variant="outlined">
            {data?.length ? (
                <FormLabel id={`radio-buttons-group-label-${field.name}`}>
                    {label || placeholder} {props.required ? "*" : ""}
                </FormLabel>
            ) : (
                ""
            )}
            <FormGroup>
                {data?.map((checkboxItem) => (
                    <FormControlLabel
                        key={checkboxItem.label}
                        control={
                            <Checkbox
                                {...props}
                                checked={
                                    Array.isArray(field.value)
                                        ? field.value.includes(checkboxItem.value)
                                        : field.value === checkboxItem.value
                                }
                                required={false}
                                onChange={() => handleCheckboxChange(checkboxItem.value)}
                            />
                        }
                        label={checkboxItem.label}
                    />
                )) || (
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...field}
                                {...props}
                                checked={!!field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                            />
                        }
                        label={label || placeholder}
                    />
                )}
            </FormGroup>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </FormControl>
    );
};

export default FormCheckbox;
