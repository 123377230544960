import { MenuItem, CircularProgress, Grid, InputLabel, FormControl, OutlinedInput, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useSortAndFilters } from "../SortAndFiltersContext";
import { enqueueSnackbar } from "notistack";
import { baseService } from "@/api/services/base";
import { getAxiosError } from "@/utils/get-axios-error";
import { FiltersContainer, FilterSelect, ButtonContainer, GridContainer, ButtonStyled, SearchInput, StyledFormControl } from "./styled";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useDebounce } from "@/hooks/useDebounce";

const Filters: React.FC = () => {
    const { filterItems, setFilterItems, applyChanges, resetAll, filters, setFilters, setSearchQuery, searchQuery, resetSearch } = useSortAndFilters();
    const [loadingItems, setLoadingItems] = useState<Record<string, boolean>>({});
    const [hasActiveFilters, setHasActiveFilters] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const handleSearch = useCallback(() => {
        applyChanges();
        setSearchLoading(false);
    }, [searchQuery]);

    const debouncedSearch = useDebounce(handleSearch);

    useEffect(() => {
        if (searchQuery) {
            setSearchLoading(true);
            debouncedSearch();
        } else {
            setSearchLoading(false);
        }
    }, [searchQuery]);

    const handleFilterChange = useCallback(
        async (key: string, index: number) => {
            const updatedFilterItems = [...filterItems];
            if (!updatedFilterItems[index].preparedItems?.length) {
                if (updatedFilterItems[index].listItems) {
                    updatedFilterItems[index].preparedItems = updatedFilterItems[index].listItems.map((lItem: any) => ({
                        id: lItem.value as never,
                        title: lItem.title,
                    }));
                    setFilterItems(updatedFilterItems);
                } else {
                    try {
                        setLoadingItems((prev) => ({ ...prev, [key]: true }));
                        const itemResponse = await baseService.getDynamicBaseData(updatedFilterItems[index].listUrl!);
                        updatedFilterItems[index].preparedItems = itemResponse.data.data?.items;
                        setFilterItems(updatedFilterItems);
                    } catch (err) {
                        const error = getAxiosError(err);
                        enqueueSnackbar(error.meta?.message!, { variant: "error" });
                    } finally {
                        setLoadingItems((prev) => ({ ...prev, [key]: false }));
                    }
                }
            }
        },
        [filterItems, setFilterItems]
    );

    const handleSelectChange = useCallback(
        (event: { target: { value: any } }, index: number, filterItemKey: any) => {
            const selectedValue = event.target.value;
            const updatedFilterItems = [...filterItems];
            const isDirectValue = updatedFilterItems[index]?.object === "@value";
            updatedFilterItems[index].selectedValue = selectedValue;
            setFilterItems(updatedFilterItems);
            setFilters((prevFilters) => {
                const newFilters = { ...prevFilters };
                if (isDirectValue) {
                    selectedValue["isEntertainment"] === true ? delete newFilters.isEducation : delete newFilters.isEntertainment;
                }
                return {
                    ...newFilters,
                    ...(isDirectValue ? selectedValue : { [filterItemKey]: selectedValue }),
                };
            });
            const anyActive = updatedFilterItems.some((item) => item.selectedValue);
            setHasActiveFilters(anyActive);
        },
        [filterItems, setFilterItems, setFilters]
    );

    const onApplyClicked = useCallback(() => {
        applyChanges();
    }, [applyChanges]);

    const onResetClicked = useCallback(() => {
        resetAll();
        setHasActiveFilters(false);
    }, [resetAll]);

    const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchLoading(true);
        const query = e.target.value.trim();
        setSearchQuery(query);
        if (!query) {
            resetSearch();
        }
    };

    const onSearchKeyUp = (e: React.KeyboardEvent) => {
        if (e.key.toLowerCase() === "enter") {
            applyChanges();
        }
    };

    const clearSearch = () => {
        setSearchQuery("");
        resetSearch();
    };

    return (
        <FiltersContainer>
            <GridContainer container spacing={1}>
                <SearchInput
                    placeholder="Search"
                    value={searchQuery}
                    onChange={onSearchQueryChanged}
                    onKeyUp={onSearchKeyUp}
                    size="small"
                    endAdornment={
                        <InputAdornment position="end">
                            {searchLoading ? (
                                <CircularProgress size={24} />
                            ) : searchQuery.length ? (
                                <GridCloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                            ) : null}
                        </InputAdornment>
                    }
                />
                {filterItems.map((filterItem: any, index: number) => (
                    <StyledFormControl fullWidth variant="outlined" margin="normal" size="small" key={filterItem.key}>
                        <InputLabel id={`select-label-${filterItem.title}`} size="small">
                            {filterItem.title}
                        </InputLabel>
                        <FilterSelect
                            labelId={`select-label-${filterItem.title}`}
                            label={filterItem.title}
                            value={(filters && filters[filterItem.key]) || filterItem.selectedValue || ""}
                            onFocus={() => handleFilterChange(filterItem.key, index)}
                            onChange={(event) => handleSelectChange(event, index, filterItem.key)}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200,
                                        overflowY: "auto",
                                    },
                                },
                            }}
                        >
                            {loadingItems[filterItem.key] ? (
                                <MenuItem disabled>
                                    <CircularProgress size={20} />
                                </MenuItem>
                            ) : (
                                filterItem.preparedItems?.map((item: any) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.title}
                                    </MenuItem>
                                ))
                            )}
                        </FilterSelect>
                    </StyledFormControl>
                ))}
            </GridContainer>
            <ButtonContainer>
                <ButtonStyled variant="contained" color="primary" onClick={onApplyClicked} disabled={!hasActiveFilters}>
                    Apply
                </ButtonStyled>
                <ButtonStyled variant="contained" color="primary" onClick={onResetClicked} disabled={!hasActiveFilters}>
                    Reset
                </ButtonStyled>
            </ButtonContainer>
        </FiltersContainer>
    );
};

export default Filters;
