export function toCamelCase(str: string): string {
    if (str.toLowerCase() === "spotlight") {
        return "spotLight";
    }
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
