/* eslint-disable no-unused-vars */
import { create } from "zustand";

import { getAuthorization } from "@/utils/storageFactory";

interface IPageState {
    generalLoading: boolean;
    currentDynamicDirectoryName: string;
    actions: {
        setGeneralLoading: (value: boolean) => void,
        setCurrentDynamicDirectoryName: (value: string) => void,
    };
}

const useStore = create<IPageState>((set) => ({
    generalLoading: !!getAuthorization(),
    currentDynamicDirectoryName: "",
    actions: {
        setGeneralLoading: (value) => set({ generalLoading: value }),
        setCurrentDynamicDirectoryName: (value) => set({ currentDynamicDirectoryName: value }),
    },
}));

export const useGeneralLoading = () => useStore((state) => state.generalLoading);
export const useCurrentDynamicDirectoryName = () => useStore((state) => state.currentDynamicDirectoryName);
export const useGlobalActions = () => useStore((state) => state.actions);
