import { videoService } from "@/api/services/video";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import InitLoading from "@/components/app/Loading/InitLoading";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { IVideoShowResponse } from "@/api/types/video";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { VideoHeaderStyled, VideoShowStyled } from "./styled";
import dayjs from "dayjs";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const VideoShow = () => {
    const { id } = useParams<IParams>();
    const hasShowId = Boolean(id);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IVideoShowResponse>();

    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.VIDEO_LIST);
    const editAccess = checkPermissionAccess(PERMISSIONS.VIDEO_UPDATE);

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await videoService.show(Number(id));
            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        hasShowId && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasShowId]);

    if (loading) return <InitLoading />;

    if (!data) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <>
            <DashboardHeader
                title={`Show Video`}
                titleSuffix={
                    <Stack spacing={2} direction={"row"}>
                        {editAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.UPDATE.EDIT_BY_ID(data.id).ABSOLUTE} variant="contained">
                                Edit Video
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.MEDIA_MANAGEMENT.VIDEOS.ROOT.ABSOLUTE}>
                                Back To Video List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <VideoHeaderStyled>
                    <video controls preload="false" src={data.playableUrl} poster={data.thumbnail?.url} />
                    <h1>{data.title}</h1>
                    <p>{data.description}</p>
                </VideoHeaderStyled>
                <VideoShowStyled>
                    <div>
                        <label>Created At</label>
                        <span>{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <label>Published At</label>
                        <span>{dayjs(data.publishedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <label>Updated At</label>
                        <span>{dayjs(data.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                </VideoShowStyled>
            </DashboardContent>
        </>
    );
};

export default VideoShow;
