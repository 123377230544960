import { AxiosError } from "axios";
import { IAxiosErrorResponse } from "@/types/axios-response";

export const getAxiosError = (err: any): IAxiosErrorResponse => {
    const error = err as AxiosError<{ meta: { code: number; message: string; messageType: string } }>;
    if (error.isAxiosError) {
        if (error.response?.data?.meta?.message) {
            return error.response.data as IAxiosErrorResponse;
        }
        return {
            meta: {
                message: error.message,
                code: Number(error.code),
                messageType: "error",
            },
        };
    } else {
        return {
            meta: {
                message: error.message,
                code: Number(error.code),
                messageType: "error",
            },
        };
    }
};
