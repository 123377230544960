// import * as Sentry from "@sentry/react";

// const ENV = !!import.meta.env.REACT_APP_SENTRY_URL ? "development" : "production";
// export const SENTRY_DSN_URL =
//     import.meta.env.REACT_APP_SENTRY_URL ||
//     "https://4ee7c4e3303b5249f4b8f369088354b3@o4508115527860224.ingest.de.sentry.io/4508233782853712";

// if (SENTRY_DSN_URL) {
//     Sentry.init({
//         dsn: SENTRY_DSN_URL,
//         environment: ENV,
//         integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//         tracesSampleRate: 1.0,
//         tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//         replaysSessionSampleRate: 0.1,
//         replaysOnErrorSampleRate: 1.0,
//     });
// }

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://83e6618f4be5b2ccd245e85f25da240b@o4508115527860224.ingest.de.sentry.io/4508317951197264",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
