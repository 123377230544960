import { styled } from "@mui/material";

export const ImagePreviewModalStyled = styled("div")(
    ({ theme }) => `
    width: 800px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
        border-radius: ${theme.spacing(1)};
    }
`
);
