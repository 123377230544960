import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ILoginFormData } from "./form-type";
import { loginValidationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, Link, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { Helmet } from "react-helmet-async";
import FormGenerator from "@/components/kit/FormGenerator";
import { loginSchema } from "./schema";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useAuth } from "@/hooks/useAuth";

const Login: React.FC = () => {
    const { login } = useAuth();

    const [loading, setLoading] = useState<boolean>(false);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<ILoginFormData>({
        defaultValues: generateDefaultValueFromFormSchema(loginSchema),
        mode: "all",
        resolver: yupResolver(loginValidationSchema),
    });

    const onSubmit = async (data: ILoginFormData) => {
        setLoading(true);
        await login(data);
        setLoading(false);
    };

    const footer = (
        <>
            <FormControl fullWidth variant="standard" margin="normal">
                <LoadingButton type="submit" disabled={!isValid || loading} loading={loading} variant="contained">
                    Sign In
                </LoadingButton>
            </FormControl>
            <FormControl variant="standard" margin="normal">
                <Stack justifyContent="flex-end" direction="row">
                    <Link href={ROUTE_CONSTANTS.AUTH.FORGET_PASSWORD.ABSOLUTE}>Forgot your password?</Link>
                </Stack>
            </FormControl>
        </>
    );

    return (
        <>
            <Helmet>
                <title>Tahseen | Login</title>
            </Helmet>
            <h1>Welcome back!</h1>
            <p>Login to access admin panel</p>
            <FormGenerator schema={loginSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} footer={footer} />
        </>
    );
};

export default Login;
