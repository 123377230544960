import { Section } from "@/api/types/home";
import { PaletteMode } from "@mui/material";
import { create } from "zustand";

interface SectionState {
    uiSections: Section[];
    setUiSections: (newSection: Section) => void;
    resetUiSections: () => void;
    setAllUiSections: (newSection: Section[]) => void;
    updateSection: (index: number, updatedSection: Section) => void; // Update by index
}

const initialState: Section[] = [];

export const useUiSectionStore = create<SectionState>((set) => ({
    uiSections: initialState,
    setUiSections: (newSection) =>
        set((state) => {
            return { uiSections: [...state.uiSections, newSection] };
        }),
    setAllUiSections: (newSections) =>
        set(() => {
            return { uiSections: newSections };
        }),
    resetUiSections: () => set(() => ({ uiSections: [] })),
    updateSection: (index, updatedSection) =>
        set((state) => ({
            uiSections: state.uiSections.map((section, i) => (i === index ? updatedSection : section)),
        })),
}));
