import { USER_ROLE } from "@/enums/user-role";
import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type UserFormSchemaFields = "firstName" | "lastName" | "email" | "phoneNumber" | "role";

export type IUserFormSchema = Record<UserFormSchemaFields, IFormGeneratorSchemaType>;

export const userSchema: IUserFormSchema = {
    firstName: {
        label: "First Name",
        placeholder: "First Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    lastName: {
        label: "Last Name",
        placeholder: "Last Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    email: {
        label: "Email",
        placeholder: "Email",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "email",
        },
    },
    phoneNumber: {
        label: "Phone Number",
        placeholder: "Phone Number",
        type: FORM_INPUTS.PHONE,
        props: {
            type: "number",
        },
    },
    role: {
        label: "Select Roles",
        placeholder: "Select Roles",
        type: FORM_INPUTS.SELECT,
        data: Object.entries(USER_ROLE).map(([, label]) => ({ label, value: label })),
        props: {
            required: true,
        },
    },
};
