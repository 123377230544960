import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";

import { IFunctionalComponentProps } from "@/types/functional-component";

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
});

interface Props extends IFunctionalComponentProps {
    dir: "rtl" | "ltr";
}

const DirectionProvider: React.FC<Props> = ({ children, dir = "ltr" }) => {
    if (dir === "ltr") return children;

    return (
        <CacheProvider value={cacheRtl}>
            <div dir="rtl">{children}</div>
        </CacheProvider>
    );
};

export default DirectionProvider;
