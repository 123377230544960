import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React, { useReducer } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MenuProps } from "@/types/menu";

const MenuItem: React.FC<MenuProps> = ({ href, icon, children, title }) => {
    const { pathname } = useLocation();
    const initState = pathname?.includes(href || "");

    const [showSubMenu, toggleSubMenu] = useReducer((prev) => !prev, initState);

    const ArrowIcon = showSubMenu ? ExpandLess : ExpandMore;

    return (
        <li className={showSubMenu ? "active" : ""}>
            <NavLink to={href || "#"} onClick={children?.length ? toggleSubMenu : undefined} className={({ isActive }) => (isActive ? "active" : "")}>
                {icon}
                <span>{title}</span>
            </NavLink>
            {children?.length && (
                <>
                    <ArrowIcon onClick={toggleSubMenu} />
                    <ul data-title={title}>{children}</ul>
                </>
            )}
        </li>
    );
};

export default MenuItem;
