import { customerService } from "@/api/services/customer";
import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import InitLoading from "@/components/app/Loading/InitLoading";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { ICustomerListResponse } from "@/api/types/customer";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Chip, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CustomerShowStyled } from "./styled";
import dayjs from "dayjs";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const CustomerShow = () => {
    const { id } = useParams<IParams>();
    const hasShowId = Boolean(id);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ICustomerListResponse>();
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.CUSTOMER_LIST);
    const editAccess = checkPermissionAccess(PERMISSIONS.CUSTOMER_UPDATE);

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await customerService.show(Number(id));
            setData(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        hasShowId && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasShowId]);

    if (loading) return <InitLoading />;

    if (!data) return <h5>Oops! Something Went Wrong!</h5>;

    return (
        <>
            <DashboardHeader
                title={`${data.firstName} ${data.lastName}`}
                titleSuffix={
                    <Stack spacing={2} direction={"row"}>
                        {editAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.UPDATE.EDIT_BY_ID(data.id).ABSOLUTE} variant="contained">
                                Edit Customer
                            </Button>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE}>
                                Back To Customer List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <CustomerShowStyled>
                    <div>
                        <label>First Name</label>
                        <span>{data.firstName}</span>
                    </div>
                    <div>
                        <label>Last Name</label>
                        <span>{data.lastName}</span>
                    </div>
                    <div>
                        <label>Email</label>
                        <span>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <span>{data.email}</span>
                                {data.emailVerified ? <Chip label="Verified" color="success" variant="outlined" /> : null}
                            </Stack>
                        </span>
                    </div>
                    <div>
                        <label>Phone Number</label>
                        <span>{data.phoneNumber}</span>
                    </div>
                    <div>
                        <label>State</label>
                        <span>
                            {data.state === "Enable" ? (
                                <Chip label="Enable" color="success" variant="outlined" />
                            ) : (
                                <Chip label="Blocked" color="error" variant="outlined" />
                            )}
                        </span>
                    </div>
                    <div>
                        <label>Created At</label>
                        <span>{dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                    <div>
                        <label>Updated At</label>
                        <span>{dayjs(data.updatedAt).format("YYYY-MM-DD HH:mm")}</span>
                    </div>
                </CustomerShowStyled>
            </DashboardContent>
        </>
    );
};

export default CustomerShow;
