import { DEFAULT_PAGE_SIZE } from "@/constants/paginations";
import { IGetListBody } from "@/api/types/base-data";

export function getListQueryItems(query: any): IGetListBody {
    let result: IGetListBody = {};
    const { sort, ...rest } = query;
    if (sort) {
        result.sort = query.sort;
    }
    if (rest && Object.keys(rest).length) {
        result.filter = {};
        for (let key in rest) {
            if (Array.isArray(rest[key])) {
                if (rest[key].length) {
                    result.filter[key] = rest[key];
                }
            } else {
                result.filter[key] = rest[key];
            }
        }
    }
    result.skip = 0;
    result.take = DEFAULT_PAGE_SIZE;
    return result;
}
