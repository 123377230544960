import { Box } from "@mui/material";

import ShowEpisode from "./episodeCard";

import { IContentShowResponse } from "@/api/types/content";

interface IShowEpisodes {
    data: IContentShowResponse[];
    // eslint-disable-next-line no-unused-vars
    onEdit: (id: number) => void;
    // eslint-disable-next-line no-unused-vars
    onDelete: (id: number) => void;
}

export default function ShowEpisodes({ data, onEdit, onDelete }: IShowEpisodes) {
    return (
        <Box className="show-episode-list">
            {data.map((episode) => {
                return <ShowEpisode key={episode.id} data={episode} onDelete={onDelete} onEdit={onEdit} />;
            })}
        </Box>
    );
}
