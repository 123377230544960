// import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import ROUTE_CONSTANTS from "./constants/route-constants";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    <BrowserRouter basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
        <App />
    </BrowserRouter>
    // </React.StrictMode>
);
