import React from "react";

const LogoWhite: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 428.15 295.77">
            <defs>
                <linearGradient id="linear-gradient" x1="162.45" y1="105.69" x2="265.7" y2="105.69" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#298ef4" />
                    <stop offset="1" stopColor="#0f62f3" />
                </linearGradient>
            </defs>
            <g>
                <g>
                    <g>
                        <path
                            fill="#fff"
                            d="M297.89,63.33l-44.5-36.29-27.71-22.6c-7.32-5.97-17.84-5.91-25.1,.13l-26.34,21.96-44.15,36.8c-4.5,3.75-7.1,9.3-7.1,15.15v96.44c0,10.89,8.83,19.73,19.73,19.73h142.7c10.89,0,19.73-8.83,19.73-19.73V78.62c0-5.93-2.66-11.54-7.26-15.29Z"
                        />
                        <path
                            fill="url(#linear-gradient)"
                            d="M234.54,60.91h-8.73l4.55-5.01c1.93-2.12,1.77-5.41-.35-7.34-2.12-1.93-5.41-1.77-7.34,.35l-8.59,9.46-8.59-9.46c-1.93-2.12-5.21-2.28-7.34-.35-2.12,1.93-2.28,5.21-.35,7.34l4.55,5.01h-8.73c-17.19,0-31.17,13.98-31.17,31.17v40.93c0,17.19,13.98,31.17,31.17,31.17h40.93c17.19,0,31.17-13.98,31.17-31.17v-40.93c0-17.19-13.98-31.17-31.17-31.17Zm20.78,72.09c0,11.46-9.32,20.78-20.78,20.78h-40.93c-11.46,0-20.78-9.32-20.78-20.78v-40.93c0-11.46,9.32-20.78,20.78-20.78h40.93c11.46,0,20.78,9.32,20.78,20.78v40.93Z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#fff"
                            d="M18.94,250.17H1.68c-.93,0-1.68-.75-1.68-1.68v-6.4c0-.93,.75-1.68,1.68-1.68H49.92c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-17.26c-.93,0-1.68,.75-1.68,1.68v41.14c0,.93-.75,1.68-1.68,1.68h-6.99c-.93,0-1.68-.75-1.68-1.68v-41.14c0-.93-.75-1.68-1.68-1.68Z"
                        />
                        <path
                            fill="#fff"
                            d="M74.47,240.4h13.15c.69,0,1.32,.43,1.57,1.07l19.82,50.91c.43,1.1-.38,2.29-1.57,2.29h-7.49c-.7,0-1.32-.43-1.57-1.08l-4.51-11.71c-.25-.65-.87-1.08-1.57-1.08h-22.82c-.7,0-1.33,.43-1.57,1.09l-4.44,11.69c-.25,.65-.87,1.09-1.57,1.09h-7.31c-1.18,0-2-1.19-1.57-2.3l19.89-50.91c.25-.65,.87-1.07,1.57-1.07Zm14.52,28.33l-6.66-16.35c-.57-1.4-2.56-1.4-3.12,.01l-6.48,16.35c-.44,1.1,.38,2.3,1.56,2.3h13.14c1.2,0,2.01-1.21,1.56-2.32Z"
                        />
                        <path
                            fill="#fff"
                            d="M122.44,240.4h6.99c.93,0,1.68,.75,1.68,1.68v18.85c0,.93,.75,1.68,1.68,1.68h27.53c.93,0,1.68-.75,1.68-1.68v-18.85c0-.93,.75-1.68,1.68-1.68h6.99c.93,0,1.68,.75,1.68,1.68v50.91c0,.93-.75,1.68-1.68,1.68h-6.99c-.93,0-1.68-.75-1.68-1.68v-18.93c0-.93-.75-1.68-1.68-1.68h-27.53c-.93,0-1.68,.75-1.68,1.68v18.93c0,.93-.75,1.68-1.68,1.68h-6.99c-.93,0-1.68-.75-1.68-1.68v-50.91c0-.93,.75-1.68,1.68-1.68Z"
                        />
                        <path
                            fill="#fff"
                            d="M187.01,278.4h7.02c.76,0,1.44,.5,1.62,1.23,.96,3.89,6.49,6.37,14.3,6.37,9.35,0,14.45-2.84,14.45-7.93,0-4.34-4.51-6.18-14.86-6.18-16.28,0-23.3-4.59-23.3-15.53s8.27-17.04,23.72-17.04c13.35,0,22.56,6.13,23.61,15.54,.11,.98-.69,1.83-1.68,1.83h-7.01c-.77,0-1.45-.52-1.62-1.26-.91-3.87-6.04-6.34-13.3-6.34-8.68,0-13.36,2.5-13.36,7.26,0,4.09,4.09,5.76,13.94,5.76,16.95,0,24.22,4.84,24.22,15.95s-8.68,17.7-24.8,17.7c-13.9,0-23.51-6.13-24.61-15.53-.12-.98,.69-1.84,1.68-1.84Z"
                        />
                        <path
                            fill="#fff"
                            d="M249.29,240.4h47.15c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-36.8c-.93,0-1.68,.75-1.68,1.68v9.08c0,.93,.75,1.68,1.68,1.68h26.53c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-26.53c-.93,0-1.68,.75-1.68,1.68v9.16c0,.93,.75,1.68,1.68,1.68h36.8c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-47.15c-.93,0-1.68-.75-1.68-1.68v-50.91c0-.93,.75-1.68,1.68-1.68Z"
                        />
                        <path
                            fill="#fff"
                            d="M313.76,240.4h47.15c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-36.8c-.93,0-1.68,.75-1.68,1.68v9.08c0,.93,.75,1.68,1.68,1.68h26.53c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-26.53c-.93,0-1.68,.75-1.68,1.68v9.16c0,.93,.75,1.68,1.68,1.68h36.8c.93,0,1.68,.75,1.68,1.68v6.4c0,.93-.75,1.68-1.68,1.68h-47.15c-.93,0-1.68-.75-1.68-1.68v-50.91c0-.93,.75-1.68,1.68-1.68Z"
                        />
                        <path
                            fill="#fff"
                            d="M378.23,240.4h6.81c.49,0,.96,.22,1.28,.59l28.52,33.36c1.02,1.19,2.96,.47,2.96-1.09v-31.17c0-.93,.75-1.68,1.68-1.68h6.99c.93,0,1.68,.75,1.68,1.68v50.91c0,.93-.75,1.68-1.68,1.68h-6.89c-.49,0-.96-.22-1.28-.59l-28.43-33.42c-1.01-1.19-2.97-.48-2.97,1.09v31.24c0,.93-.75,1.68-1.68,1.68h-6.99c-.93,0-1.68-.75-1.68-1.68v-50.91c0-.93,.75-1.68,1.68-1.68Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LogoWhite;
