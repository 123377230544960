import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IForgetPasswordFormData } from "./form-type";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { Helmet } from "react-helmet-async";
import FormGenerator from "@/components/kit/FormGenerator";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { forgetPasswordSchema } from "./schema";
import { forgetPasswordValidationSchema } from "./validation";
import { useAuth } from "@/hooks/useAuth";
import { Link } from "react-router-dom";

const ForgetPassword: React.FC = () => {
    const { forgetPassword } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [sentEmail, setSentEmail] = useState<boolean>(false);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
    } = useForm<IForgetPasswordFormData>({
        defaultValues: generateDefaultValueFromFormSchema(forgetPasswordSchema),
        mode: "all",
        resolver: yupResolver(forgetPasswordValidationSchema),
    });

    const onSubmit = async (data: IForgetPasswordFormData) => {
        setLoading(true);
        await forgetPassword(data);
        setSentEmail(true);
        setLoading(false);
    };

    const footer = (
        <>
            <FormControl fullWidth variant="standard" margin="normal">
                <LoadingButton type="submit" disabled={!isValid || loading} loading={loading} variant="contained">
                    Send Link
                </LoadingButton>
            </FormControl>
            <FormControl fullWidth variant="standard" margin="normal">
                <Button component={Link} to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE}>
                    Back to login
                </Button>
            </FormControl>
        </>
    );

    return (
        <>
            <Helmet>
                <title>Tahseen | Forget Password</title>
            </Helmet>
            {sentEmail ? (
                <>
                    <h1>Email sent</h1>
                    <p>We sent you an email. Follow the instructions to get back into your account.</p>
                    <FormControl fullWidth variant="standard" margin="normal">
                        <Button component={Link} to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} variant="contained">
                            Back to login
                        </Button>
                    </FormControl>
                    <FormControl fullWidth variant="standard" margin="normal">
                        <Button onClick={() => setSentEmail(false)}>Edit email</Button>
                    </FormControl>
                </>
            ) : (
                <>
                    <h1>Forget Password</h1>
                    <p>Enter your email address, and we'll send you a link to get back into your account.</p>
                    <FormGenerator schema={forgetPasswordSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} footer={footer} />
                </>
            )}
        </>
    );
};

export default ForgetPassword;
