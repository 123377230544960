import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

import {
    GeTPlanCheckoutById,
    GeTPlanById,
    UpdatePlanById,
    DeletePlanById,
    CreatePlan,
    GetPlans,
} from "@/api/services/plans";
import { IRequestParams, IRequestPramsNoId, IUpdatePlanRequestBody } from "@/api/types/plans";
import { errorCallback } from "@/api/hooks/error";

export function usePlansCheckout({ id, lang, currency }: IRequestParams) {
    return useQuery({
        queryKey: ["plans", `plan${id}`, `plan${id}-checkout`],
        queryFn: () => GeTPlanCheckoutById({ id, lang, currency }),
    });
}

export function usePlanById({ id, lang, currency }: IRequestParams) {
    return useQuery({
        queryKey: ["plans", `plan${id}`],
        queryFn: () => GeTPlanById({ id, lang, currency }),
        enabled: !!id,
    });
}

export function useUpdatePlan() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ params, body }: { params: IRequestParams, body: IUpdatePlanRequestBody }) =>
            UpdatePlanById(params, body),
        onError: errorCallback,
        onSuccess: () => {
            enqueueSnackbar("Edit the plan was successful.", { variant: "success" });
            queryClient.invalidateQueries({ queryKey: [`plans`] });
        },
    });
}

export function useDeletePlan() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (params: IRequestParams) => DeletePlanById(params),
        onError: errorCallback,
        onSuccess: () => {
            enqueueSnackbar("Plan Deleted", { variant: "success" });
            queryClient.invalidateQueries({ queryKey: [`plans`] });
        },
    });
}

export function useCreatePlan() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ params, body }: { params: IRequestPramsNoId, body: IUpdatePlanRequestBody }) =>
            CreatePlan(params, body),
        onError: errorCallback,
        onSuccess: () => {
            enqueueSnackbar("Plan Created", { variant: "success" });
            queryClient.invalidateQueries({ queryKey: ["plans"] });
        },
    });
}

export function usePlansList(params: IRequestPramsNoId) {
    return useQuery({
        queryKey: ["plans", params],
        queryFn: () => GetPlans(params),
    });
}
