import { useState } from "react";
import { Paper, Box, Typography } from "@mui/material";

import PublisherPageStyle from "./style";
import ShowPublishers from "./show";
import AddPubLishers from "./add";

import { IPublisherListReqBody } from "@/api/types/publisher";
import { usePublisherList } from "@/api/hooks/publisher";
// import { useScrollBottom } from "@/hooks/useScrollBottom";

export default function Publisher() {
    const lang = "en";
    const [params] = useState<IPublisherListReqBody>({
        skip: 0,
        take: 50,
        search: "",
        sort: "",
        filter: "",
    });

    const { publishers, mutate, hasMore } = usePublisherList({ lang, body: params });

    const refetch = () => hasMore && mutate({ lang, body: params });

    // useScrollBottom(() => {
    //     setParams((prev) => {
    //         return {
    //             ...prev,
    //             skip: hasMore ? prev.skip + 10 : prev.skip,
    //         };
    //     });
    // }, [params.skip]);

    return (
        <PublisherPageStyle>
            <Typography component="h1" sx={{ fontSize: "25px", fontWeight: "bold" }}>
                Publisher
            </Typography>
            <Box className="center">
                <Paper className="paper">
                    <AddPubLishers onCreated={refetch} />
                    {/* {isPending && (
                        <Typography style={{ color: "rgb(28, 99, 170)", margin: "1em 0" }}>Loading - Please wait</Typography>
                    )} */}
                    <ShowPublishers data={publishers} onDelete={refetch} />
                    {!hasMore && <Typography>No More Data</Typography>}
                </Paper>
            </Box>
            <br />
            <br />
        </PublisherPageStyle>
    );
}
