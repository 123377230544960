import { styled } from "@mui/material";

export default styled("div")(
    ({ theme }) => `

    width: 100%;
    min-height: 100%;
    display: flex;
    margin-top: 32px;
    flex-direction: column;

    .country-table-container {
        min-height: 100%;
    }

    .MuiTable-root  {
        .MuiTableHead-root  {
            .MuiTableRow-root  {
                .MuiTableCell-root  {
                    padding: 8px;
                }
            }
        }

        .MuiTableBody-root  {
            .MuiTableRow-root  {
                .MuiTableCell-root  {
                    padding: 8px;
                }
            }
        }
    }

    .table-card {
        display: flex;
        width: 100%;
        height: auto;
        position: relative;
        overflow: visible;
        padding-top: 32px;

        .title {
            top: -32px;
            left: 1em;
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            
            .svg-img {
                width: 64px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: ${theme.palette.background.paper};
                border-radius: ${theme.shape.borderRadius}px;
                background-color:  ${theme.palette.success.dark};
            }

            .text {
                font-size:1rem;
                font-weight:bold;
                margin-left: 10px;
            }
        }


        .table-container {
            padding: 1em;
        }
    }

`
);
