import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { HomeUiSectionFormSchemaFields, homeUiSectionSchema } from "./schema";
import { IHomeUiSectionCreateFormType } from "./form-type";
import { createUiSectionValidationSchema } from "./validation";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { IParams } from "@/types/params";
import { getAxiosError } from "@/utils/get-axios-error";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import { IFormGeneratorGeneralSchemaType } from "@/types/form-generator-schema-type";
import { tagService } from "@/api/services/tag";
import { capitalizeFirstLetter, toCamelCase } from "@/utils/global";
import { Section } from "@/api/types/home";
import { useUiSectionStore } from "@/context/ui-section";

const HomeUiSectionForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [formFieldsSchema, setFormFieldsSchema] =
        useState<Record<HomeUiSectionFormSchemaFields, IFormGeneratorGeneralSchemaType>>(homeUiSectionSchema);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.HOME_LIST);
    const { setUiSections, uiSections, updateSection } = useUiSectionStore();
    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        watch,
    } = useForm<IHomeUiSectionCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(homeUiSectionSchema),
        mode: "all",
        resolver: yupResolver(createUiSectionValidationSchema),
    });

    const tagValue = watch("tag");

    useEffect(() => {
        if (Number(tagValue) === 1) {
            setFormFieldsSchema((prev) => ({
                ...prev,
                ml_title_en: { ...prev.ml_title_en, props: { ...prev.ml_title_en.props, disabled: true } },
            }));
        } else {
            setFormFieldsSchema((prev) => ({
                ...prev,
                ml_title_en: { ...prev.ml_title_en, props: { ...prev.ml_title_en.props, disabled: false } },
            }));
        }
    }, [tagValue]);

    useEffect(() => {
        const fetchData = async () => {
            await getTagData();
        };

        fetchData();
    }, [isEditingRule]);

    useEffect(() => {
        if (isEditingRule && id) {
            const sectionToEdit = uiSections[Number(id)];

            if (sectionToEdit) {
                reset({
                    type: capitalizeFirstLetter(sectionToEdit.type),
                    ml_title_en: sectionToEdit.ml_title.en,
                    ml_title_ar: sectionToEdit.ml_title.ar,
                    itemCount: sectionToEdit.itemCount,
                    tag: sectionToEdit.tag,
                });
            }
            setLoading(false);
        }
    }, [isEditingRule, id, reset]);

    const getTagData = async () => {
        try {
            setFormFieldsSchema((prev) => ({
                ...prev,
                tag: { ...prev.tag, props: { ...prev.tag.props, disabled: true } },
            }));
            const response = await tagService.list();
            const tagData = response.data.data.items.map((item) => ({
                label: item.ml_title.en,
                value: item.id,
            }));

            setFormFieldsSchema((prev) => ({
                ...prev,
                tag: { ...prev.tag, data: tagData },
            }));
        } catch (err) {
            handleError(err);
        } finally {
            setFormFieldsSchema((prev) => ({
                ...prev,
                tag: { ...prev.tag, props: { ...prev.tag.props, disabled: false } },
            }));
        }
    };

    const handleError = (err: any) => {
        const error = getAxiosError(err);
        const message = error?.meta.message || "Server Error";

        enqueueSnackbar(message, { variant: "error" });
    };

    const onSubmit = async (_val: IHomeUiSectionCreateFormType) => {
        try {
            setSubmitLoading(true);
            const section: Section = {
                type: toCamelCase(_val.type),
                ml_title: {
                    en: _val.ml_title_en ?? "",
                    ar: _val.ml_title_ar ?? "",
                },
                itemCount: _val.itemCount,
                tag: _val.tag,
                data: [],
            };

            isEditingRule ? updateSection(Number(id), section) : setUiSections(section);
            enqueueSnackbar(`${isEditingRule ? "App UI Section updated" : "New App UI Section created"} successfully`, {
                variant: "success",
            });
            navigate(-1);
        } catch (err) {
            handleError(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    const footer = (
        <Stack direction="row" paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                {isEditingRule ? "Update" : "Create"} Home UI Section
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${isEditingRule ? "Update" : "Create "} Home page UI section`}
                {...(listAccess
                    ? { titleSuffix: <Button onClick={() => navigate(-1)}>Back To Home UI</Button> }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={formFieldsSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default HomeUiSectionForm;
