import { styled } from "@mui/material";

export const ForbiddenStyled = styled("section")(
    ({ theme }) => `
    height: 100vh;
    padding: ${theme.spacing(5)};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    span {
        margin-bottom: ${theme.spacing(3)}
    }
    h1 {
        font-size: 100px;
        line-height: 80px;
        margin: 0 0 ${theme.spacing(3)}
    }
    h3 {
        font-size: 30px;
        line-height: 40px;
        margin: 0 0 ${theme.spacing(3)}
    }
    h6 {
        font-size: 20px;
        line-height: 80px;
        font-weight: 300;
        margin: 0 0 ${theme.spacing(3)}
    }
`
);
