import { ModalsList } from "@/components/app/Modal/modal-list";

export enum MODAL_TYPES {
    CONFIRMATION_MODAL = "confirmationModal",
    SELECT_VIDEO_MODAL = "selectVideoModal",
    SELECT_IMAGE_MODAL = "selectImageModal",
    VIDEO_PLAYER_MODAL = "videoPlayerModal",
    IMAGE_PREVIEW = "ImagePreviewModal",
}

export interface Modal {
    type: MODAL_TYPES;
    data: any;
}

export interface ModalGeneralProps {
    header?: string;
    width?: string;
}

export type ModalState = Modal[];

export interface IModalProps<D = any> {
    closeModal: (clearModals?: boolean) => void;
    data: D & ModalGeneralProps;
}

// @ts-expect-error this type throws error if ModalList is empty
export type ModalData<T extends ModalTypes> = Parameters<(typeof ModalsList)[T]>["0"]["data"];

export type ModalList = {
    [key in MODAL_TYPES]: React.FC<IModalProps>;
};
