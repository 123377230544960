import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type HomeFormSchemaFields = "name" | "category";

export type IHomeFormSchema = Record<HomeFormSchemaFields, IFormGeneratorSchemaType>;

export const homeSchema: IHomeFormSchema = {
    name: {
        label: "Name",
        placeholder: "Name",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },

    category: {
        label: "Category",
        placeholder: "Category",
        type: FORM_INPUTS.RADIO,
        defaultValue: 1,
        data: [
            { value: 1, label: "Education" },
            { value: 2, label: "Entertainment" },
        ],
        props: {
            required: true,
        },
    },
};
