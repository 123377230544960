import { MENU_VIEW } from "@/enums/menu";
import { create } from "zustand";

type MenuState = {
    menuView: MENU_VIEW;
    actions: {
        toggleMenuView: () => void;
    };
};

const initialState = (localStorage.getItem("menuView") as MENU_VIEW) || MENU_VIEW.MAXIMIZE;

export const useMenuContext = create<MenuState>((set) => ({
    menuView: initialState,
    actions: {
        toggleMenuView: () =>
            set((prev) => {
                const newMenuViewState = prev.menuView === MENU_VIEW.MAXIMIZE ? MENU_VIEW.MINIMIZE : MENU_VIEW.MAXIMIZE;
                localStorage.setItem("menuView", newMenuViewState);
                return { menuView: newMenuViewState };
            }),
    },
}));

export const useMenuActions = () => useMenuContext((store) => store.actions);
export const useMenuView = () => useMenuContext((store) => store.menuView);
