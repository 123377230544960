import { AxiosProgressEvent } from "axios";

import {
    IGetVideoListCaptionResponse,
    IGetVideoCaptionResponse,
    ICreateVideoCaptionsResponse,
    IDeleteVideoCaptionsResponse,
    ICreateVideoCaptionsParams,
} from "../types/videoCaptions";

import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";

export const GetAllVideoCaptions = async (id: number) => {
    const result = await AxiosInstance.get<IGetVideoListCaptionResponse>(API_ROUTES.VIDEO_CAPTIONS.LIST(id));

    return result.data;
};

export const GetVideoCaption = async (id: number, lang: string) => {
    const result = await AxiosInstance.get<IGetVideoCaptionResponse>(API_ROUTES.VIDEO_CAPTIONS.GET_BY_ID_AND_LANG(id, lang));

    return result.data;
};

export const DeleteVideoCaption = async (id: number, lang: string) => {
    const result = await AxiosInstance.delete<IDeleteVideoCaptionsResponse>(API_ROUTES.VIDEO_CAPTIONS.DELETE(id, lang));

    return result.data;
};

export const CreateVideoCaption = async (params: ICreateVideoCaptionsParams) => {
    const { id, lang, body, progress } = params;

    const config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            if (progress) progress(progressEvent);
        },
    };

    const result = await AxiosInstance.post<ICreateVideoCaptionsResponse>(
        API_ROUTES.VIDEO_CAPTIONS.CREATE(id, lang),
        body,
        config
    );

    return result.data;
};

export const SetDefaultVideoCaptions = async (id: number, lang: string) => {
    const result = await AxiosInstance.put<ICreateVideoCaptionsResponse>(API_ROUTES.VIDEO_CAPTIONS.DEFAULT(id, lang));

    return result.data;
};
