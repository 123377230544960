import { styled } from "@mui/material";

export const Container = styled("div")(() => ({
    margin: "1rem auto",
    textAlign: "center",
}));

export const DraggableListStyled = styled("ul")(() => ({
    listStyle: "none",
    padding: 0,
    margin: "1rem 0",
}));

export const DraggableItem = styled("li")(() => ({
    marginBottom: "0.5rem",
    borderRadius: "4px",
    fontSize: "1rem",
    fontFamily: "Arial, sans-serif",
    color: "#666666",
    transition: "box-shadow 0.2s ease, background-color 0.2s ease",

    "&:hover": {
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.1)",
    },

    "&.dragging": {
        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
}));
