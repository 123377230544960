import { API_ROUTES } from "@/constants/api-routes";
import AxiosInstance from "@/api";
import { IAxiosResponse } from "@/types/axios-response";
import { ITableParams, ITableResponse } from "@/types/table";
import { IHomeListResponse, IHomeUpdatePayload } from "@/api/types/home";

export const homeService = {
    list(params?: ITableParams) {
        return AxiosInstance.post<IAxiosResponse<ITableResponse<IHomeListResponse>>>(API_ROUTES.HOME.LIST, params);
    },
    create(data: IHomeUpdatePayload) {
        return AxiosInstance.post<IAxiosResponse>(API_ROUTES.HOME.CREATE, data);
    },
    update(id: number, data: IHomeUpdatePayload) {
        return AxiosInstance.put<IAxiosResponse>(API_ROUTES.HOME.UPDATE(id), data);
    },
    delete(id: number) {
        return AxiosInstance.delete<IAxiosResponse<ITableResponse<IHomeListResponse>>>(API_ROUTES.HOME.DELETE(id));
    },
    show(id: number) {
        return AxiosInstance.get<IAxiosResponse<IHomeListResponse>>(API_ROUTES.HOME.SHOW(id));
    },
};
