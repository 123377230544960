import ModalHeader from "./modalHeader";
import ModalFooter from "./modalFooter";
import ModalContent from "./modalContent";
import { Modal, ModalProps, styled } from "@mui/material";

export const ModalWrapperStyled = styled("div")<{ width?: string }>(
    ({ theme, width }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${width || "500px"};
    max-width: 100%;
    background: ${theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.common.white};
    box-shadow: ${theme.shadows[24]};
    border-radius: ${theme.spacing(2)};
`
);

export default function CompoundModal({ children, open, onClose, ...restProps }: ModalProps) {
    return (
        <Modal open={open} onClose={onClose} {...restProps}>
            <ModalWrapperStyled>{children}</ModalWrapperStyled>
        </Modal>
    );
}

CompoundModal.Header = ModalHeader;
CompoundModal.Content = ModalFooter;
CompoundModal.Footer = ModalContent;
