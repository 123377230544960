import { useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import { errorCallback } from "@/api/hooks/error";
import { contentService } from "@/api/services/content";
import { ILanguagePayload } from "@/types/language-payload";
import { IAgeRangeListResponse } from "@/api/types/content";

export function useAgeRange() {
    const [ageRanges, setAgeRange] = useState<IAgeRangeListResponse[]>();

    const { mutate, ...restProps } = useMutation({
        mutationFn: (body: ILanguagePayload) => contentService.ageRange(body),
        onSuccess: (result) => setAgeRange(result.data.data.items),
        onError: errorCallback,
    });

    useEffect(() => mutate({} as ILanguagePayload, {}), [mutate]);

    return { ageRanges, mutate, ...restProps };
}
