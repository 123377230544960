import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { CreatePublisherStyled } from "../style";

import { useCreatePublisher } from "@/api/hooks/publisher";

export default function CreatePublisher({ onCreated }: { onCreated: () => void }) {
    const [input, setInput] = useState<string>("");

    const { mutate, isPending } = useCreatePublisher();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

    const handleAdd = () => mutate({ lang: "en", body: { title: input } }, { onSuccess: onCreated });

    return (
        <CreatePublisherStyled>
            <Typography className="title" component="h2">
                Add Publishers
            </Typography>
            <Box className="add-box">
                <TextField fullWidth value={input} onChange={handleChange} />

                <LoadingButton
                    className="success"
                    disabled={isPending}
                    loading={isPending}
                    type="submit"
                    variant="contained"
                    onClick={handleAdd}
                >
                    Create
                </LoadingButton>
            </Box>
        </CreatePublisherStyled>
    );
}
