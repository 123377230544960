import React from "react";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import {
    HeaderLogoutStyled,
    HeaderProfileStyled,
    HeaderStyled,
    HeaderThemeStyled,
    HeaderThemeSwitchStyled,
} from "./styled";

import { useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/enums/menu";
import { useAuth } from "@/hooks/useAuth";
import { useUser } from "@/context/authContext";
import { useThemeStore } from "@/context/theme";

const Header: React.FC = () => {
    const user = useUser();
    const { logOut } = useAuth();
    const menuView = useMenuView();
    const { theme, setTheme } = useThemeStore();

    const isMinimizedMenu = menuView === MENU_VIEW.MINIMIZE;
    const minimizedClassName = isMinimizedMenu ? "minimize" : "";

    const toggleDarkMode = () => setTheme(theme === "dark" ? "light" : "dark");

    return (
        <HeaderStyled className={minimizedClassName}>
            <HeaderThemeStyled>
                <HeaderThemeSwitchStyled checked={theme === "dark"} onClick={toggleDarkMode} />
            </HeaderThemeStyled>
            <HeaderProfileStyled>
                <span className="ml-2">{user?.firstName}</span>
            </HeaderProfileStyled>
            <HeaderLogoutStyled>
                <PowerSettingsNewIcon onClick={logOut}>Logout</PowerSettingsNewIcon>
            </HeaderLogoutStyled>
        </HeaderStyled>
    );
};

export default Header;
