import { authService } from "@/api/services/auth";
import { userService } from "@/api/services/user";
import { ACCESS_REFRESH_TOKEN } from "@/constants/access-refresh-token";
import { ACCESS_TOKEN } from "@/constants/access-token";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useGlobalActions } from "@/context/global-context";
import { useAuthActions } from "@/context/authContext";
import { IUserForgetPasswordPayload, IUserLoginPayload } from "@/api/types/auth";
import { getAxiosError } from "@/utils/get-axios-error";
import { setAuthorization } from "@/utils/storageFactory";
import { setRefreshToken } from "@/utils/set-refresh-token";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
    const { setUser, clearUser, setUserPermissions, clearUserPermissions } = useAuthActions();
    const { setGeneralLoading } = useGlobalActions();
    const navigate = useNavigate();

    const login = async (data: IUserLoginPayload) => {
        try {
            const response = await authService.login(data);
            const { token, user, refreshToken } = response.data.data;
            setUser(user);
            setAuthorization(token);
            setRefreshToken(refreshToken);
            getPermissions();
            navigate(ROUTE_CONSTANTS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        } finally {
            setGeneralLoading(false);
        }
    };

    const resetPassword = async (token: string, newPassword: string) => {
        try {
            await authService.resetPassword({ token, newPassword });
            enqueueSnackbar("The new password has been set successfully", { variant: "success" });
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        }
    };

    const forgetPassword = async (data: IUserForgetPasswordPayload) => {
        try {
            await authService.forgetPassword(data);
            enqueueSnackbar("Email send successfully", { variant: "success" });
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        }
    };

    const getProfile = async () => {
        try {
            const response = await userService.profile();
            setUser(response.data.data);
            getPermissions();
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        } finally {
            setGeneralLoading(false);
        }
    };

    const getPermissions = async () => {
        try {
            const response = await authService.permissions();
            setUserPermissions(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message || "", { variant: "error" });
        }
    };

    const logOut = () => {
        try {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(ACCESS_REFRESH_TOKEN);
            clearUserPermissions();
            clearUser();
            navigate(ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE);
        } catch (err) {
            console.error(err);
        }
    };

    return { login, getPermissions, getProfile, forgetPassword, resetPassword, logOut };
};
