import { useMemo } from "react";

import { PERMISSIONS } from "@/enums/permissions";
import { USER_ROLE } from "@/enums/user-role";
import { useUserPermissions, useUser } from "@/context/authContext";

export const useCheckPermissionAccess = () => {
    const userPermissions = useUserPermissions();
    const user = useUser();

    const checkPermission = useMemo(
        () => ({
            checkPermissionAccess: (permission: PERMISSIONS): boolean => {
                // check if user is super admin (bypass super admin)
                if (user?.role === USER_ROLE.ADMINISTRATOR) return true;

                // check if user has permission
                if (userPermissions?.findIndex((userPermission) => userPermission === permission) !== -1) return true;

                return false;
            },
        }),
        [userPermissions, user]
    );

    return checkPermission;
};
