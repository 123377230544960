import { styled } from "@mui/material";

export default styled("div")`

    .page-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .page-title {
            font-size: 25px;
            font-weight: bold;
        }
    }

.tab-panel {
    height: 100%;
}

`;
