import { Box } from "@mui/material";
// import { useNavigate } from "react-router-dom";

import PlanCard from "../partials/planCard";

import { IPlan } from "@/api/types/plans";
// import ROUTE_CONSTANTS from "@/constants/route-constants";

export default function PlansList({ planList }: { planList: IPlan[] }) {
    // const navigate = useNavigate();
    const locale = "en";

    // const handlePlan = (plan: IPlan) => navigate(ROUTE_CONSTANTS.SETTINGS.PLANS.DETAILS.ROOT(Number(plan.id)).ABSOLUTE);

    return (
        <Box className="show-plan-list">
            {planList.map((plan) => {
                const { id, ml_title, ml_description, price, ml_features, isActive, currency } = plan;

                return (
                    <PlanCard
                        key={id}
                        checkIcon
                        active={isActive}
                        currency={currency}
                        features={ml_features.map((item) => item[locale])}
                        hasSubscribeButton={false}
                        id={Number(id)}
                        salePrice={price}
                        subTitle={ml_description[locale]}
                        title={ml_title[locale]}
                    />
                );
            })}
        </Box>
    );
}
