import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

import { createGenreValidationSchema } from "./validation";
import { IGenreCreateFormType } from "./form-type";
import { genreSchema } from "./schema";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { slugify } from "@/utils/slugify";
import { CreateGenre, UpdateGenre, GetGenre } from "@/api/services/genre";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const GenreForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const hasEditId = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(hasEditId);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.GENRE_LIST);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm<IGenreCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(genreSchema),
        mode: "all",
        resolver: yupResolver(createGenreValidationSchema),
    });

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await GetGenre({ id: Number(id) });
            const { ml_title, ml_description, isEntertainment, slug } = response;

            reset({
                en_title: ml_title.en,
                ar_title: ml_title.ar,
                en_description: ml_description.en,
                ar_description: ml_description.ar,
                category: isEntertainment ? 2 : 1,
                slug,
            });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (_val: IGenreCreateFormType) => {
        try {
            setSubmitLoading(true);
            const { ar_description, ar_title, en_description, en_title, category, ...payloadValues } = _val;
            const payload = {
                ...payloadValues,
                isEducation: category === 1,
                isEntertainment: category === 2,
                ml_title: { en: en_title, ar: ar_title },
                ml_description: { en: en_description, ar: ar_description },
            };

            if (hasEditId) {
                await UpdateGenre({ id: Number(id), body: payload });
            } else {
                await CreateGenre({ body: payload });
            }
            enqueueSnackbar(`${hasEditId ? "Genre updated" : "New genre created"} successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        hasEditId && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasEditId]);

    useEffect(() => {
        const subscription = watch((values, event) => {
            if (event.name === "en_title") {
                setValue("slug", slugify(values.en_title || ""));
            }
        });

        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const footer = (
        <Stack direction={"row"} paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                {hasEditId ? "Update" : "Create"} Genre
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${hasEditId ? "Update" : "Create New"} Genre`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.GENRES.ROOT.ABSOLUTE}>
                                  Back To Genre List
                              </Button>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={genreSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default GenreForm;
