import { CircularProgress } from "@mui/material";
import { LoadingStyled } from "./styled";

const InitLoading = ({ height = "50vh" }) => {
    return (
        <LoadingStyled style={{ height }}>
            <CircularProgress />
        </LoadingStyled>
    );
};
export default InitLoading;
