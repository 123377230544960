import { CONTENT_TYPE } from "@/enums/content-type";
import { IContentListResponse } from "@/api/types/content";
import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";

export const contentListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 0.1,
        sortable: false,
    },
    {
        field: "thumbnailPortrait.id",
        headerName: "Thumbnail",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 0.6,
        display: "flex",
        sortable: false,
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            return params.row.thumbnailPortrait ? <img src={params.row.thumbnailPortrait.url} width={40} height={40} /> : <>No Image</>;
        },
    },
    {
        field: "ml_name.en",
        headerName: "English Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            return params.row.ml_name.en;
        },
    },
    {
        field: "ml_name.ar",
        headerName: "Arabic Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            return params.row.ml_name.ar;
        },
    },
    {
        field: "typeId",
        headerName: "Type",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        // renderCell(params: GridRenderCellParams<IContentListResponse>) {
        //     return params.row.typeId === CONTENT_TYPE.SERIES ? 'Series' : 'Movie';
        // },
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            return (
                <Chip
                    label={params.row.typeId === CONTENT_TYPE.SERIES ? "Series" : "Movie"}
                    color={params.row.typeId === CONTENT_TYPE.SERIES ? "info" : "secondary"}
                    size="small"
                    variant="outlined"
                />
            );
        },
    },
    {
        field: "category",
        headerName: "Category",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            if (params.row.isEducation) return <Chip label="Education" color="success" size="small" variant="outlined" />;
            if (params.row.isEntertainment) return <Chip label="Entertainment" color="info" size="small" variant="outlined" />;
        },
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: true,
        renderCell(params: GridRenderCellParams<IContentListResponse>) {
            return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
    },
];
