import { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Button, CircularProgress, InputAdornment, OutlinedInput, Pagination, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
    SelectImageModalFooterButtonsStyled,
    SelectImageModalFooterPaginationStyled,
    SelectImageModalFooterStyled,
    SelectImageModalItemContentStyled,
    SelectImageModalItemHeaderStyled,
    SelectImageModalItemStyled,
    SelectImageModalListBodyLoadingStyled,
    SelectImageModalListBodyStyled,
    SelectImageModalListHeaderStyled,
    SelectImageModalListWrapperStyled,
    SelectImageModalPreviewStyled,
    SelectImageModalStyled,
} from "./styled";

import { IModalProps } from "@/types/modals";
import { imageService } from "@/api/services/image";
import { IImageListResponse } from "@/api/types/image";
import { getAxiosError } from "@/utils/get-axios-error";
import { ITablePaginate, ITableParams } from "@/types/table";
import { useDebounce } from "@/hooks/useDebounce";
import { useIsMount } from "@/hooks/useIsMount";
import { IMAGE_MODAL_PER_PAGE } from "@/constants/image-modal-configs";
import { stringLimiter } from "@/utils/string-limiter";

export interface SelectImageModalProps {
    preview?: boolean;
    // eslint-disable-next-line no-unused-vars
    onChange: (...event: any[]) => void;
}
const SelectImageModal: React.FC<IModalProps<SelectImageModalProps>> = ({ data: { onChange }, closeModal }) => {
    const isMount = useIsMount();
    const [listLoading, setListLoading] = useState(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [data, setData] = useState<IImageListResponse[]>();
    const [pagination, setPagination] = useState<ITablePaginate>();
    const [queryParams, setQueryParams] = useState<ITableParams>();
    const [selectedImage, setSelectedImage] = useState<IImageListResponse>();
    const [search, setSearch] = useState("");

    const fetchImageList = useCallback(async () => {
        try {
            setListLoading(true);
            const response = await imageService.list(queryParams);

            setData(response.data.data.items);
            setPagination(response.data.data.pagination);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setListLoading(false);
            setSearchLoading(false);
        }
    }, [queryParams]);

    const handlePaginationModelChange = useCallback((page: number) => {
        setQueryParams((prev) => ({ ...prev, take: IMAGE_MODAL_PER_PAGE, skip: (page - 1) * IMAGE_MODAL_PER_PAGE }));
    }, []);

    const fetchDataDebounce = useDebounce(() => {
        setQueryParams((prev) => ({ ...prev, search }));
    });

    const onSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setSearchLoading(true);
    };

    const clearSearch = () => {
        setSearch("");
        setSearchLoading(true);
    };

    const handleSelectImage = (imageIndexId: number) => {
        const image = data?.find((item) => item.id === imageIndexId);

        setSelectedImage(image);
    };
    const handleSelectImageForm = () => {
        onChange(selectedImage);
        closeModal();
    };

    useEffect(() => {
        if (!isMount) fetchDataDebounce();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        fetchImageList();
    }, [fetchImageList]);

    return (
        <>
            <SelectImageModalStyled>
                <SelectImageModalListWrapperStyled>
                    <SelectImageModalListHeaderStyled>
                        <OutlinedInput
                            endAdornment={
                                <InputAdornment position="end">
                                    {searchLoading ? (
                                        <CircularProgress size={24} />
                                    ) : search.length ? (
                                        <CloseIcon sx={{ cursor: "pointer" }} onClick={clearSearch} />
                                    ) : null}
                                </InputAdornment>
                            }
                            placeholder="Image Search ..."
                            size="small"
                            value={search}
                            onChange={onSearch}
                        />
                    </SelectImageModalListHeaderStyled>
                    {listLoading ? (
                        <SelectImageModalListBodyLoadingStyled>
                            <CircularProgress size={24} />
                        </SelectImageModalListBodyLoadingStyled>
                    ) : (
                        <SelectImageModalListBodyStyled>
                            {data?.map((item) => {
                                return (
                                    <SelectImageModalItemStyled
                                        key={item.id}
                                        active={selectedImage?.id === item.id}
                                        onClick={() => handleSelectImage(item.id)}
                                    >
                                        <SelectImageModalItemHeaderStyled>
                                            {item.url ? <img alt={item.originalName} src={item.url} /> : <>No Image</>}
                                        </SelectImageModalItemHeaderStyled>
                                        <SelectImageModalItemContentStyled>
                                            <Typography>
                                                <span className="label">File Name: </span>
                                                <span className="value">
                                                    {stringLimiter(
                                                        item.originalName.length ? item.originalName : item.originalName,
                                                        40
                                                    )}
                                                </span>
                                            </Typography>
                                            <Typography>
                                                <span className="label">Title:</span>
                                                <span className="value">
                                                    {stringLimiter(item.title.length ? item.title : item.title, 40)}
                                                </span>
                                            </Typography>
                                        </SelectImageModalItemContentStyled>
                                    </SelectImageModalItemStyled>
                                );
                            })}
                        </SelectImageModalListBodyStyled>
                    )}
                </SelectImageModalListWrapperStyled>
                <SelectImageModalPreviewStyled>
                    {selectedImage ? (
                        <img alt={selectedImage.originalName} src={selectedImage.url} />
                    ) : (
                        <span>No image has been selected yet</span>
                    )}
                </SelectImageModalPreviewStyled>
            </SelectImageModalStyled>
            <SelectImageModalFooterStyled>
                {pagination && pagination?.totalItems > IMAGE_MODAL_PER_PAGE ? (
                    <SelectImageModalFooterPaginationStyled>
                        <Pagination
                            count={pagination.totalPages}
                            disabled={listLoading}
                            page={pagination.currentPage}
                            onChange={(e, page) => handlePaginationModelChange(page)}
                        />
                    </SelectImageModalFooterPaginationStyled>
                ) : null}
                <SelectImageModalFooterButtonsStyled>
                    <Button disabled={!selectedImage} variant="contained" onClick={handleSelectImageForm}>
                        Select Image
                    </Button>
                </SelectImageModalFooterButtonsStyled>
            </SelectImageModalFooterStyled>
        </>
    );
};

export default SelectImageModal;
