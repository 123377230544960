import { object, string } from "yup";

export const createUserValidationSchema = object().shape({
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    email: string().email().required("Email is required"),
    phoneNumber: string()
        .nullable()
        .notRequired()
        .transform((o, c) => (o === "" ? null : c))
        .min(12, "Phone Number must be at least 8 digits"),
    role: string().required("Role is required"),
});
