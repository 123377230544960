import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type LoginFormSchemaFields = "email" | "password";

export type ILoginFormSchema = Record<LoginFormSchemaFields, IFormGeneratorSchemaType>;

export const loginSchema: ILoginFormSchema = {
    email: {
        label: "Email",
        placeholder: "Email",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
            type: "email",
            autoFocus: true,
        },
    },
    password: {
        label: "Password",
        placeholder: "Password",
        type: FORM_INPUTS.PASSWORD,
        props: {
            required: true,
        },
    },
};
