import { styled } from "@mui/material";

export default styled("div")`
    .content {
        margin: 0 auto;
        max-width: 800px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2em;
        justify-content: space-between;

        .col  {
            width: 100%;
        }

        &.grid {
            display: grid;
            grid-gap: 1em;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 1em;
        }

        .left-sec {
            max-width: 320px;

            .portrait-box {
                width: 100%;
                aspect-ratio: 2/3;

                .portrait-thumb {
                    aspect-ratio: 16/9;
                    width: 100%;
                    height: 100%;
                    & > div {
                        height: inherit;
                    }
                }
            }
        }

        .right-sec {
            .landscape-box {
                width: 100%;
                aspect-ratio: 16/9;
                .landscape-thumb {
                    max-height: 320px;

                    width: 100%;
                    height: 100%;
                    & > div {
                        height: inherit;
                    }
                }
            }
            
            .video-section {
                display: flex;
                flex-direction: row;
                gap: 2em;
                justify-content: space-between;

                .select-trailer, .select-video {
                    width: 100%;
                }
            }
        }
    }
`;
