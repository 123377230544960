import { styled } from "@mui/material/styles";

export const EditorContainer = styled("div")<{ direction?: string }>(({ theme, direction }) => ({
    borderRadius: "10px",
    minHeight: "600px",
    marginTop: "10px",

    ".ql-toolbar": {
        backgroundColor: theme.palette.mode === "dark" ? "#d3e0ea" : "#d3e0ea",
        borderRadius: "20px 20px 0 0",
    },

    ".ql-container": {
        borderRadius: "0 0 20px 20px",
        minHeight: "600px",
        backgroundColor: theme.palette.mode === "dark" ? "#2b2b2b" : "#ffffff",
        color: theme.palette.mode === "dark" ? "#f5f5f5" : "#333333",
    },

    ".ql-editor.ql-blank::before": {
        color: theme.palette.mode === "dark" ? "#bbbbbb" : "#999999",
        fontSize: "16px",
    },

    ".ql-editor": {
        textAlign: direction === "rtl" ? "right" : "left",
    },

    ".ql-picker": {
        borderRadius: "8px",
        width: "50px",
        transition: "box-shadow 0.2s ease, border-color 0.2s ease",
        "&:hover": {
            borderColor: theme.palette.mode === "dark" ? "#f0f0f0" : "#298EF4",
        },
    },

    ".ql-snow .ql-picker.ql-header": {
        width: "110px",
    },

    ".ql-picker-label": {
        padding: "0px 12px",
        fontSize: "16px",
        color: theme.palette.mode === "dark" ? "#333333" : "#333333",
        cursor: "pointer",
    },

    ".ql-toolbar.ql-snow .ql-picker-label": {
        border: "none",
    },

    ".ql-picker-item": {
        padding: "8px 12px",
        fontSize: "14px",
        color: theme.palette.mode === "dark" ? "#dddddd" : "#666666",
        "&:hover": {
            backgroundColor: theme.palette.mode === "dark" ? "#555555" : "#f5f5f5",
            color: theme.palette.mode === "dark" ? "inherit" : "inherit",
            borderRadius: "6px",
        },
        "&.ql-selected": {
            backgroundColor: theme.palette.mode === "dark" ? "#3f3f3f" : "#e0e0e0",
            color: theme.palette.mode === "dark" ? "#ffffff !important" : "#000000 !important",
            borderRadius: "6px",
        },
    },

    ".ql-picker-options": {
        backgroundColor: theme.palette.mode === "dark" ? "#2b2b2b" : "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        maxHeight: "270px",
        overflowY: "auto",
        borderColor: "none !important",

        "& .ql-picker-item:hover": {
            backgroundColor: theme.palette.mode === "dark" ? "#555555" : "#f5f5f5",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
        },
    },

    ".ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options": {
        borderColor: "none !important",
    },

    ".ql-toolbar.ql-snow .ql-picker-options": {
        border: "none",
        boxShadow: "rgba(0, 0, 0, 0.1) 0 7px 12px",
    },
}));
