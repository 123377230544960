import { IModalProps } from "@/types/modals";
import { useEffect, useState } from "react";
import { VideoPlayerModalLoadingStyled, VideoPlayerModalStyled } from "./styled";
import { videoService } from "@/api/services/video";
import { IVideoShowResponse } from "@/api/types/video";
import { getAxiosError } from "@/utils/get-axios-error";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

export interface VideoPlayerModalProps {
    videoId: number;
}
const VideoPlayerModal: React.FC<IModalProps<VideoPlayerModalProps>> = ({ data: { videoId }, closeModal }) => {
    const [loading, setLoading] = useState(true);
    const [video, setVideo] = useState<IVideoShowResponse>();

    const getVideoById = async () => {
        try {
            setLoading(true);
            const response = await videoService.show(videoId);
            setVideo(response.data.data);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getVideoById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoId]);

    return (
        <VideoPlayerModalStyled>
            {loading ? (
                <VideoPlayerModalLoadingStyled>
                    <CircularProgress size={24} />
                </VideoPlayerModalLoadingStyled>
            ) : video ? (
                <video controls preload="false" src={video.playableUrl} poster={video.thumbnail?.url} />
            ) : null}
        </VideoPlayerModalStyled>
    );
};

export default VideoPlayerModal;
