import { Components, createTheme } from "@mui/material/styles";
import { formLabelClasses } from "@mui/material";
import { Theme } from "@emotion/react";

const componentsConfig: Components<Omit<Theme, "components">> = {
    MuiPaper: {
        variants: [
            {
                props: {},
                style: {
                    borderRadius: "12px",
                },
            },
        ],
    },
    MuiInputLabel: {
        variants: [
            {
                props: { size: "small" }, // When size is 'small'
                style: {
                    transform: "translate(20px, 9px) scale(1) !important",
                    [`&.${formLabelClasses.focused}`]: {
                        transform: "translate(22px, -9px) scale(0.75) !important",
                    },
                    [`&.${formLabelClasses.filled}`]: {
                        transform: "translate(22px, -9px) scale(0.75) !important",
                    },
                },
            },
            {
                props: {},
                style: {
                    transform: "translate(22px, 17px) scale(1)",
                    [`&.${formLabelClasses.focused}`]: {
                        transform: "translate(22px, -9px) scale(0.75)",
                    },
                    [`&.${formLabelClasses.filled}`]: {
                        transform: "translate(22px, -9px) scale(0.75)",
                    },
                },
            },
        ],
    },
    MuiSelect: {
        defaultProps: {
            classes: {
                filled: "selectBox-filled",
                icon: "selectBox-icon",
                iconFilled: "selectBox-iconFilled",
                iconOpen: "selectBox-iconOpen",
                iconOutlined: "selectBox-iconOutlined",
                iconStandard: "selectBox-iconStandard",
                multiple: "selectBox-multiple",
                nativeInput: "selectBox-nativeInput",
                outlined: "selectBox-outlined",
                root: "selectBox-root",
                select: "selectBox-select",
                standard: "selectBox-standard",
            },
        },
    },
    MuiOutlinedInput: {
        variants: [
            {
                props: {},
                style: {
                    borderRadius: 28,
                    padding: "0 8px",
                    legend: {
                        marginLeft: "6px",
                    },
                    textarea: {
                        padding: "16px 8px",
                    },
                },
            },
        ],
    },
    MuiButton: {
        variants: [
            {
                props: { variant: "contained", color: "primary", size: "medium" },
                style: {
                    borderRadius: 22,
                    background: "linear-gradient(232.87deg, #0F62F3 -9.82%, #09398D 106.44%)",
                    fontWeight: 600,
                    boxShadow: "0px 0px 32px 0px rgba(15, 98, 243, 0.3)",
                    lineHeight: "44px",
                    padding: "0 32px",
                    "&:hover": {
                        boxShadow: "0px 0px 16px 0px rgba(15, 98, 243, 0.3)",
                    },
                    "&:disabled": {
                        background: "rgba(231, 231, 231, 1)",
                        color: "rgba(153, 153, 153, 1)",
                        boxShadow: "unset",
                    },
                },
            },
            {
                props: { variant: "contained", color: "primary", size: "small" },
                style: {
                    borderRadius: 22,
                    background: "linear-gradient(232.87deg, #0F62F3 -9.82%, #09398D 106.44%)",
                    fontWeight: 600,
                    boxShadow: "0px 0px 32px 0px rgba(15, 98, 243, 0.3)",
                    lineHeight: "24px",
                    padding: "0 12px",
                    "&:hover": {
                        boxShadow: "0px 0px 16px 0px rgba(15, 98, 243, 0.3)",
                    },
                    "&:disabled": {
                        background: "rgba(231, 231, 231, 1)",
                        color: "rgba(153, 153, 153, 1)",
                        boxShadow: "unset",
                    },
                },
            },
            {
                props: { variant: "contained", color: "error", size: "small" },
                style: {
                    borderRadius: 22,
                    background: "linear-gradient(232.87deg, #f30f0f -9.82%, #8d0909 106.44%)",
                    fontWeight: 600,
                    boxShadow: "0px 0px 32px 0px rgba(243, 15, 15, 0.3)",
                    lineHeight: "24px",
                    padding: "0 12px",
                    "&:hover": {
                        boxShadow: "0px 0px 16px 0px rgba(243, 15, 15, 0.3)",
                    },
                    "&:disabled": {
                        background: "rgba(231, 231, 231, 1)",
                        color: "rgba(153, 153, 153, 1)",
                        boxShadow: "unset",
                    },
                },
            },
            {
                props: { variant: "contained", color: "error", size: "medium" },
                style: {
                    borderRadius: 22,
                    background: "linear-gradient(232.87deg, #f30f0f -9.82%, #8d0909 106.44%)",
                    fontWeight: 600,
                    boxShadow: "0px 0px 32px 0px rgba(243, 15, 15, 0.3)",
                    lineHeight: "44px",
                    padding: "0 32px",
                    "&:hover": {
                        boxShadow: "0px 0px 16px 0px rgba(243, 15, 15, 0.3)",
                    },
                    "&:disabled": {
                        background: "rgba(231, 231, 231, 1)",
                        color: "rgba(153, 153, 153, 1)",
                        boxShadow: "unset",
                    },
                },
            },
            {
                props: { variant: "text" },
                style: {
                    borderRadius: 22,
                    background: "#fff",
                    fontWeight: 600,
                    lineHeight: "42px",
                    padding: "0 32px",
                    color: "rgba(33, 33, 33, 1)",
                    border: "1px solid rgba(213, 213, 213, 1)",
                    "&:hover": {
                        background: "#fff",
                    },
                },
            },
        ],
    },
    MuiTableContainer: {
        defaultProps: {
            classes: {
                root: "table-container",
            },
        },
    },
};

export const lightTheme = createTheme({
    components: componentsConfig,
    typography: {
        fontFamily: ["Raleway", "Noto", "sans-serif"].join(","),
    },
    palette: {
        mode: "light",
        primary: {
            main: "#298EF4",
        },
        secondary: {
            main: "#F3A012",
        },
        error: {
            main: "#FF515D",
        },
    },
});

export const darkTheme = createTheme({
    components: componentsConfig,
    typography: {
        fontFamily: ["Raleway", "Noto", "sans-serif"].join(","),
    },
    palette: {
        mode: "dark",
        background: {
            default: "#212121",
        },
        primary: {
            main: "#298EF4",
        },
        secondary: {
            main: "#EE750D",
        },
        error: {
            main: "#FF515D",
        },
    },
});
