/* eslint-disable react/display-name */
import { Navigate, Outlet } from "react-router-dom";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import { PERMISSIONS } from "@/enums/permissions";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";

// First we need to add a type to let us extend the incoming component.
interface IProtectedRouteProps {
    permissions: PERMISSIONS[];
    children: JSX.Element;
    redirectPath?: string;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
    permissions,
    children,
    redirectPath = ROUTE_CONSTANTS.FORBIDDEN.ABSOLUTE,
}) => {
    const { checkPermissionAccess } = useCheckPermissionAccess();

    if (!permissions.length) return children || <Outlet />;

    const allPermissionsAreValid = permissions.every((permission) => checkPermissionAccess(permission));

    if (!allPermissionsAreValid) return <Navigate replace to={redirectPath} />;

    return children || <Outlet />;
};

export default ProtectedRoute;

export function withProtectedRoutes<P>(WrappedComponent: React.ComponentType<P>, permissions: PERMISSIONS[]) {
    return (props: P & {}) => {
        const { checkPermissionAccess } = useCheckPermissionAccess();
        const allPermissionsAreValid = permissions.every((permission) => checkPermissionAccess(permission));

        if (!permissions.length) return <Outlet />;

        if (!allPermissionsAreValid) return <Navigate replace to={ROUTE_CONSTANTS.FORBIDDEN.ABSOLUTE} />;

        return <WrappedComponent {...props} />;
    };
}
