import { styled } from "@mui/material";

export const SidebarStyled = styled("aside")(
    ({ theme }) => `
    position: fixed;
    width: 300px;
    left: ${theme.spacing(3)};
    top: ${theme.spacing(3)};
    bottom: ${theme.spacing(3)};
    background: ${theme.palette.mode === "dark" ? "linear-gradient(to bottom, #174597, #134294)" : "linear-gradient(to bottom, #09398D, #0F62F3)"};
    z-index: 510;
    border-radius: ${theme.spacing(2)};
    padding: ${theme.spacing(4)} ${theme.spacing(2)};
    transition: 0.15s;
    box-shadow: 0px 0px 16px 0px rgba(15, 98, 243, 0.3);
    
    &.minimize {
        width: 80px;
        padding: ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(2)};
    }
`
);

export const SidebarLogoStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    flex-direction: column;

    svg {
        width: 140px;
        margin: 0 auto;
        transition: 0.15s;
    }
    &.minimize {
        svg {
            width: 48px;
        }
    }
`
);

export const SidebarMenuStyled = styled("div")(
    ({ theme }) => `
    overflow: auto;
    scrollbar-width: none;
    height: calc(100% - 138px);
    margin-top: ${theme.spacing(3)};

    &::-webkit-scrollbar {
        display: none;
    }

    &.minimize {
        overflow: unset;
        scrollbar-width: unset;
        height: calc(100% - 100px);
    }
`
);

export const SidebarToggleStyled = styled("div")(
    ({ theme }) => `
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: ${theme.palette.mode === "dark" ? "#212121" : theme.palette.common.white};
    display: flex;
    justify-content: center;
    align-items: center;
    right: -${theme.spacing(2)};
    bottom: ${theme.spacing(8)};
    cursor: pointer;
    box-shadow: 0px 0px 16px 0px ${theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.15)"};

    svg {
        display: block;
    }
`
);

export const SidebarNizekLogoStyled = styled("div")(
    ({ theme }) => `
    margin-top: auto;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    gap: ${theme.spacing(1)};
    a {
        color: rgba(255,255,255,0.8);
        transition: 0.15s;
        &:hover {
            color: #fff
        }
    }

    &.minimize {
        text-align: center;
        span {
            font-size: 8px
        }
    }
`
);
