import { Button, Switch } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import { homeListColumnSchema } from "./column-schema";

import { getAxiosError } from "@/utils/get-axios-error";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import DashboardHeader from "@/components/app/DashboardHeader";
import DashboardContent from "@/components/app/DashboardContent";
import { ITableParams } from "@/types/table";
import Table from "@/components/kit/Table";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import { homeService } from "@/api/services/home";
import { IHomeListResponse } from "@/api/types/home";

const service = async (queryParams?: ITableParams) => homeService.list(queryParams);

const HomePage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const createAccess = checkPermissionAccess(PERMISSIONS.TAG_CREATE);
    const editAccess = checkPermissionAccess(PERMISSIONS.TAG_UPDATE);
    const deleteAccess = checkPermissionAccess(PERMISSIONS.TAG_DELETE);

    const suffixCols = (
        tableData: IHomeListResponse[],
        setTableData: Dispatch<SetStateAction<IHomeListResponse[]>>
    ): GridColDef[] => {
        const handleDelete = (id: number) => {
            const handleAction = async () => {
                try {
                    setLoading(true);
                    await homeService.delete(id);
                    enqueueSnackbar(`Home page UI deleted successfully`, { variant: "success" });
                    setTableData((prev) => (prev ? prev.filter((item) => item.id !== id) : prev));
                } catch (err) {
                    const error = getAxiosError(err);
                    const message = error?.meta.message || "Server Error";

                    enqueueSnackbar(message, { variant: "error" });
                } finally {
                    setLoading(false);
                }
            };

            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Delete Confirmation",
                text: "Are you sure to delete this UI?",
                handleAction,
            });
        };

        const handleActive = async (
            id: IHomeListResponse["id"],
            checked: IHomeListResponse["isActive"],
            category: IHomeListResponse["category"]
        ) => {
            try {
                setLoading(true);
                let updatedItems = [...tableData];

                if (checked) {
                    const activeItem = tableData.find(
                        (item) => item.category === category && item.isActive && item.name !== "default"
                    );

                    if (activeItem && activeItem.id !== id) {
                        await homeService.update(activeItem.id, { isActive: false });
                        updatedItems = updatedItems.map((item) =>
                            item.id === activeItem.id ? { ...item, isActive: false } : item
                        );
                    }

                    await homeService.update(id, { isActive: true });
                    enqueueSnackbar("Item activated successfully", { variant: "success" });
                } else {
                    await homeService.update(id, { isActive: false });
                    enqueueSnackbar("Item deactivated successfully", { variant: "success" });
                }
                updatedItems = updatedItems.map((item) => (item.id === id ? { ...item, isActive: checked } : item));
                setTableData(updatedItems);
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";

                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setLoading(false);
            }
        };

        return [
            {
                field: "active",
                headerName: "Is Active",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                headerAlign: "center",
                sortable: false,
                display: "flex",
                renderCell(params: GridRenderCellParams<IHomeListResponse>) {
                    const isDisabled = params.row.name === "default";

                    return (
                        <Switch
                            checked={params.row.isActive}
                            color="success"
                            disabled={isDisabled}
                            size="small"
                            onChange={(e) => handleActive(params.row.id, e.currentTarget.checked, params.row.category)}
                        />
                    );
                },
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                filterable: false,
                hideable: false,
                resizable: false,
                flex: 1,
                align: "center",
                sortable: false,
                getActions: (params: GridRowParams<IHomeListResponse>) => {
                    const isDisabled = params.row.name === "default";

                    return [
                        ...(editAccess && !isDisabled
                            ? [
                                  <Link
                                      key={params.row.id}
                                      to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.UPDATE.EDIT_BY_ID(params.row.id).ABSOLUTE}
                                  >
                                      <GridActionsCellItem
                                          color="inherit"
                                          disabled={isDisabled}
                                          icon={<EditIcon color="action" />}
                                          label="Edit"
                                      />
                                  </Link>,
                              ]
                            : [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      disabled
                                      color="inherit"
                                      icon={<EditIcon color="disabled" />}
                                      label="Edit"
                                  />,
                              ]),
                        ...(deleteAccess && !isDisabled
                            ? [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      color="inherit"
                                      icon={<DeleteIcon color="action" />}
                                      label="Delete"
                                      onClick={() => handleDelete(params.row.id)}
                                  />,
                              ]
                            : [
                                  <GridActionsCellItem
                                      key={params.row.id}
                                      disabled
                                      color="inherit"
                                      icon={<DeleteIcon color="disabled" />}
                                      label="Edit"
                                  />,
                              ]),
                    ];
                },
            },
        ];
    };

    return (
        <>
            <DashboardHeader
                title="Home Page UI List"
                titleSuffix={
                    createAccess ? (
                        <Button
                            color="primary"
                            component={Link}
                            to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW.ROOT.ABSOLUTE}
                            variant="contained"
                        >
                            Add New UI
                        </Button>
                    ) : null
                }
            />
            <DashboardContent>
                <Table
                    columnsSchema={homeListColumnSchema}
                    loading={loading}
                    service={service}
                    suffixCols={editAccess || deleteAccess ? suffixCols : undefined}
                />
            </DashboardContent>
        </>
    );
};

export default HomePage;
