import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EditorContainer } from "./styled";
import { TextFieldSchema } from "@/types/form-generator-schema-type";
import { FormHelperText } from "@mui/material";
import { FieldError } from "react-hook-form";

interface IProps {
    field: any;
    errorMessage: FieldError;
    label: string;
    placeholder: string;
    props?: TextFieldSchema["props"];
}

const FormRichTextEditor = ({ field, errorMessage, placeholder, props = {} }: IProps) => {
    const defaultDirection = props?.dir || "ltr";
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ direction: defaultDirection }],
        ],
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "align",
        "direction",
        "link",
        "image",
        "video",
    ];

    return (
        <div>
            <EditorContainer direction={defaultDirection}>
                <ReactQuill
                    value={field.value || ""}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    placeholder={placeholder}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                />
            </EditorContainer>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </div>
    );
};

export default FormRichTextEditor;
