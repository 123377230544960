import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import AddIcon from "@mui/icons-material/Add";

import SectionCard from "../Section/Card";

import { createHomeUiValidationSchema } from "./validation";
import { homeSchema } from "./schema";
import { IHomeCreateFormType } from "./form-type";

import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { IParams } from "@/types/params";
import { IHomeListResponse, Section } from "@/api/types/home";
import { homeService } from "@/api/services/home";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { CATEGORY } from "@/enums/home-page-ui";
import { handleError } from "@/utils/fetch-error";
import MemoizedDraggableList, { ItemType } from "@/components/kit/DnD";
import { useUiSectionStore } from "@/context/ui-section";

const setSpotlightAtFisrt = (array: ItemType<Section>[]) => {
    const tempArray = [...array];
    const indexSpotLight = tempArray.findIndex((item) => item.data.tag === 1);

    tempArray.splice(indexSpotLight, 1);
    tempArray.unshift(array[indexSpotLight]);

    return tempArray;
};

const HomeUiForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditing = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditing);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [homePageUi, setHomePageUi] = useState<IHomeListResponse>();
    const [sampleData, setSampleData] = useState<ItemType<Section>[]>([]);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.HOME_LIST);
    const { uiSections, resetUiSections, setAllUiSections } = useUiSectionStore();

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
    } = useForm<IHomeCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(homeSchema),
        mode: "all",
        resolver: yupResolver(createHomeUiValidationSchema),
    });

    const fetchEditData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await homeService.show(Number(id));
            const { name, category, sections } = response.data.data;

            setHomePageUi(response.data.data);
            reset({
                category: category === CATEGORY.ENTERTAINMENT ? 2 : 1,
                name,
            });
            if (uiSections.length < 1) {
                setAllUiSections(sections || []);
            }
        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [id, reset, setAllUiSections, uiSections.length]);

    useEffect(() => {
        if (isEditing) {
            fetchEditData();
        }
    }, [isEditing, fetchEditData]);

    const onSubmit = async (formValues: IHomeCreateFormType) => {
        try {
            setSubmitLoading(true);
            const payload = {
                name: formValues.name,
                category: formValues.category === 2 ? CATEGORY.ENTERTAINMENT : CATEGORY.EDUCATION,
                sections: uiSections,
            };

            if (isEditing) {
                await homeService.update(Number(id), payload);
            } else {
                await homeService.create(payload);
            }
            enqueueSnackbar(`${isEditing ? "App UI updated" : "New App UI created"} successfully`, { variant: "success" });
            resetUiSections();
            navigate(ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE);
        } catch (err) {
            handleError(err);
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleDeleteSection = useCallback(
        async (index: number) => {
            const updatedSections = uiSections.filter((_, i) => i !== index);

            setAllUiSections(updatedSections);
            const payload = {
                name: homePageUi?.name,
                category: homePageUi?.category,
                sections: updatedSections,
            };

            if (isEditing) {
                await homeService.update(Number(id), payload);
            }
            enqueueSnackbar(`${isEditing ? "App UI section deleted" : "New App UI created"} successfully`, {
                variant: "success",
            });
        },
        [homePageUi?.category, homePageUi?.name, isEditing, id, uiSections, setAllUiSections]
    );

    useEffect(() => {
        if (uiSections.length > 0) {
            setSampleData(
                uiSections.map((section, index) => ({
                    id: `${index}`,
                    content: (
                        <SectionCard key={index} handleDeleteSection={handleDeleteSection} index={index} section={section} />
                    ),
                    data: section,
                }))
            );
        } else {
            setSampleData([]);
        }
    }, [handleDeleteSection, uiSections]);

    const handleOrderChange = async (newOrder: ItemType<Section>[]) => {
        const orders = setSpotlightAtFisrt(newOrder);
        const updatedSections = orders.map((item) => item.data);

        setAllUiSections(updatedSections);
        const payload = {
            name: homePageUi?.name,
            category: homePageUi?.category,
            sections: updatedSections,
        };

        if (isEditing) {
            await homeService.update(Number(id), payload);
        }
        enqueueSnackbar(`${"App UI Section updated"} successfully`, { variant: "success" });
    };

    const footer = (
        <Stack direction="row" paddingBottom={10} spacing={2}>
            <LoadingButton disabled={!isValid || submitLoading} loading={submitLoading} type="submit" variant="contained">
                {isEditing ? "Update" : "Create"} Home UI
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    const SortedSampleData = setSpotlightAtFisrt(sampleData);

    return (
        <>
            <DashboardHeader
                title={`${isEditing ? "Update" : "Create New"} Home page UI`}
                titleSuffix={
                    listAccess && (
                        <Button component={Link} to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ROOT.ABSOLUTE}>
                            Back To Home List
                        </Button>
                    )
                }
            />
            <DashboardContent>
                <Grid container px={10} spacing={2} sx={{ padding: "16px", fontWeight: "bold" }}>
                    <Grid item textAlign={"center"} xs={4}>
                        <Typography>Module</Typography>
                    </Grid>
                    <Grid item textAlign={"center"} xs={4}>
                        <Typography>Tag</Typography>
                    </Grid>
                    <Grid item textAlign={"center"} xs={4}>
                        <Typography>Actions</Typography>
                    </Grid>
                </Grid>
                <MemoizedDraggableList initialItems={SortedSampleData} onOrderChanged={handleOrderChange} />
                <Container sx={{ textAlign: "center" }}>
                    <Card>
                        <CardContent sx={{ paddingBottom: "12px !important" }}>
                            <Typography
                                color="text.secondary"
                                component={Link}
                                mb={0}
                                sx={{ cursor: "pointer" }}
                                to={ROUTE_CONSTANTS.APP_UI_MANAGEMENT.HOME.ADD_NEW_SECTION.ROOT.ABSOLUTE}
                                variant="body2"
                            >
                                <AddIcon />
                            </Typography>
                        </CardContent>
                    </Card>
                </Container>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={homeSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default HomeUiForm;
