"use client";
import { styled } from "@mui/material";

export const PlanCardWrapperStyled = styled("div")`
   display: flex;
   flex-direction: column;
`;

export const PlanCardStyled = styled("div")(
    ({ theme }) => `
   max-width: 326px;
   min-height: 454px;
   gap: ${theme.spacing(4)};
   border-radius: ${theme.spacing(8)};
   position: relative;
   transition: 0.15s;
   background: linear-gradient(87.51deg, rgba(255, 255, 255, 0) 0.9%, rgba(231, 231, 231, 0.018) 53.81%, rgba(231, 231, 231, 0.018) 98.87%);
   border: 1.5px solid transparent;
   text-align: center;

   &:before {
      content: '';
      position: absolute;
      left: -1px; 
      top: -1px; 
      bottom: -1px; 
      right: -1px;
      border-radius: ${theme.spacing(8)};
      z-index: -2;
      transition: 0.15s;
      background: linear-gradient(211.42deg, rgba(231, 231, 231, 0.18) 3.32%, rgba(255, 255, 255, 0.1) 43.59%, rgba(231, 231, 231, 0.06) 83.86%);
      pointer-events: none;
   }
   &:after {
      content: '';
      position: absolute;
      left: 0; 
      top: 0; 
      bottom: 0; 
      right: 0;
      border-radius: ${theme.spacing(8)};
      z-index: -1;
      background: ${theme.palette.background.default};
      pointer-events: none;
   }

   .shadow {
      position: absolute;
      left: 0; 
      top: 0; 
      bottom: 0; 
      right: 0;
      border-radius: ${theme.spacing(8)};
      z-index: 10;
      overflow: hidden;
      pointer-events: none;
      
      &:before {
         content: '';
         position: absolute;
         width: 136px;
         height: 145px;
         border-radius: 50%;
         background: #0F62F31A;
         filter: blur(65px);
         z-index: 10;
         left: 8px;
         top: -47px;
      }

      &:after {
         content: '';
         position: absolute;
         width: 136px;
         height: 145px;
         border-radius: 50%;
         background: #FFFFFF80;
         filter: blur(65px);
         z-index: 20;
         left: 12px;
         top: -11px;
      }
   }

   &.active {
      background: linear-gradient(87.51deg, rgba(15, 98, 243, 0.009) 0.9%, rgba(15, 98, 243, 0.0704374) 53.81%, rgba(0, 38, 155, 0.09) 98.87%);
      &:before {
         background: linear-gradient(211.42deg, rgba(15, 98, 243, 0.5) 3.32%, rgba(101, 119, 151, 0) 43.59%, rgba(15, 98, 243, 0.1) 83.86%); 
      }

      .shadow {
         &:before {
            background: #298EF4;
         }
         &:after {
            background: #9747FFB2;
         }
      }
   }

   &.clickable {
      cursor: pointer;
   }

   @media(max-width: 768px) {
      max-width: unset;
      
   }
   @media(max-width: 576px) {
      border-radius: ${theme.spacing(4)};

      &:before, &:after, .shadow {
         border-radius: ${theme.spacing(4)};
      }
      
      &:after {
         background: #111111;
      }
   }
`
);

export const PlanCardContentStyled = styled("div")(
    ({ theme }) => `
   position: relative;
   padding: 2em 0;
   box-sizing: border-box;
   z-index: 20;
   min-width: 300px;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   text-align: center;


   .content-header {
      display: flex;
      padding: 0 1em;
      justify-content: space-between;
      flex-direction: row-reverse;
   }

   .starBigImg {
      position: absolute;
      animation: scale-linear 2.5s linear infinite;
      left: 43px;
      top: 15px;
   }

   .starSmallImg {
      position: absolute;
      animation: scale-linear 2.5s 0.5s linear infinite;
      ${theme.direction === "ltr" ? `right: 49px;` : `left: 49px;`};
      top: 50px;
   }

   @media(max-width: 1200px) {
      padding: ${theme.spacing(5)} ${theme.spacing(2)} ${theme.spacing(2)};
   }

   @media(max-width: 576px) {
      padding: ${theme.spacing(3)} ${theme.spacing(5)};
   }
`
);

export const PlanCardHeaderStyled = styled("div")(
    ({ theme }) => `
   display: flex;
   flex-direction: column;
   text-align: center;
   position: relative;
   margin-bottom: ${theme.spacing(4)};

   &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      left: 15px;
      height: 1px;
      background: linear-gradient(90deg, rgba(33, 33, 33, 0) 0%, #EEEEEE 50%, rgba(33, 33, 33, 0) 100%);
   }

   > small {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: ${theme.spacing(1)};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing(1)};
      line-height: 34px;

      span {
         border: 1.5px solid transparent;
         text-align: center;
         position: relative;
         display: inline-block;
         font-size: 14px;
         font-weight: 400;
         line-height: 32px;
         padding: 0 ${theme.spacing(3)};
         background: linear-gradient(87.51deg, rgba(15, 98, 243, 0.009) 0.9%, rgba(15, 98, 243, 0.0704374) 53.81%, rgba(0, 38, 155, 0.09) 98.87%);

         &:before {
            content: '';
            position: absolute;
            left: -1px; 
            top: -1px; 
            bottom: -1px; 
            right: -1px;
            border-radius: ${theme.spacing(8)};
            z-index: -2;
            transition: 0.15s;
            background: linear-gradient(211.42deg, rgba(15, 98, 243, 0.5) 3.32%, rgba(101, 119, 151, 0) 43.59%, rgba(15, 98, 243, 0.1) 83.86%); 
            pointer-events: none;
         }
         &:after {
            content: '';
            position: absolute;
            left: 0; 
            top: 0; 
            bottom: 0; 
            right: 0;
            border-radius: ${theme.spacing(8)};
            z-index: -1;
            background: linear-gradient(to right, #36437d, #1f2535);
            pointer-events: none;
         }
      }
   }
   div {
      > span {
         font-size: 32px;
         font-weight: 700;
         line-height: 48px;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: ${theme.spacing(1)};

         small {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            color: #FFFFFF80;
            text-decoration: line-through;
         }
      }
      p {
         font-size: 14px;
         font-weight: 300;
         line-height: 21px;
         margin-top: unset;
      }
   }

   &.active {
      &:after {
         background: linear-gradient(90deg, rgba(33, 33, 33, 0) 0%, #0F62F3 50%, rgba(33, 33, 33, 0) 100%);
      }
   }

   @media(max-width: 1300px) {
      padding: 0 ${theme.spacing(3)} ${theme.spacing(8)};
   }

   @media(max-width: 1200px) {
      padding: 0 0 ${theme.spacing(8)};
      > span {
         font-size: 26px;
      }
   }

   @media(max-width: 1100px) {
      > small {
         flex-direction: column;
      }
   }

   @media(max-width: 576px) {
      flex-direction: row;
      padding-bottom: ${theme.spacing(3)};
      flex-wrap: wrap;
      > small {
         margin: unset;
         font-size: 14px;
         font-weight: 400;
         line-height: 16.8px;
         span {
            display: none;
         }
      }
      div {
         ${theme.direction === "ltr" ? `margin-left: auto;` : `margin-right: auto;`};
         align-items: flex-end;
         display: flex;
         flex-direction: column;
         text-align: right;
         span {
            font-size: 20px;
            line-height: 30px;
            small {
               display: none;
            }
         }
         p {
            margin: unset;
            font-size: 12px;
            line-height: 18px;
         }
      }
      button, a {
         margin-top: ${theme.spacing(4)};
         width: 100%;
      }
   }
`
);
export const PlanCardBodyStyled = styled("div")(
    ({ theme }) => `

      padding: 0 1em;

      .title {
         margin: 10px 0;
      }

   > span {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      display: block;
      margin-bottom: ${theme.spacing(2)};
   }
   ul {
      text-align: ${theme.direction === "ltr" ? `left` : `right`};
      li {
         font-size: 14px;
         font-weight: 400;
         line-height: 21px;
         margin-bottom: ${theme.spacing(2)};
         ${theme.direction === "ltr" ? `padding-left: 36px;` : `padding-right: 36px;`};
         position: relative;
         &:last-of-type {
            margin-bottom: unset
         }
         img {
            position: absolute;
            ${theme.direction === "ltr" ? `left: 0;` : `right: 0;`};
         }
      }
   }
   @media(max-width: 576px) {
      > span {
         display: none;
      }
   }
`
);

export const PlanCardCheckIconStyled = styled("div")(
    ({ theme }) => `
   margin-top: ${theme.spacing(6)};
   display: flex;
   align-items: center;
   justify-content: center;
   width: 32px;
   height: 32px;
   border: 1px solid #444444;
   color: #fff;
   border-radius: 50%;
   align-self: center;
   cursor: pointer;
   transition: 0.15s;

   &.active {
      border-color: #0F62F3;
      background-color: #0F62F3;
   }
`
);
