import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

import { ICustomerCreateFormType } from "./form-type";
import { createCustomerValidationSchema } from "./validation";
import { customerSchema } from "./schema";

import ROUTE_CONSTANTS from "@/constants/route-constants";
import { getAxiosError } from "@/utils/get-axios-error";
import { customerService } from "@/api/services/customer";
import FormGenerator from "@/components/kit/FormGenerator";
import DashboardHeader from "@/components/app/DashboardHeader";
import DashboardContent from "@/components/app/DashboardContent";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { modalActions } from "@/context/modals";
import { MODAL_TYPES } from "@/types/modals";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";

const CustomerForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const hasEditId = Boolean(id);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const [customerState, setCustomerState] = useState<"Enable" | "Disable">();
    const [customerStateLoading, setCustomerStateLoading] = useState<boolean>(false);
    const isBlocked = customerState === "Disable";
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.CUSTOMER_LIST);

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<ICustomerCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(customerSchema),
        mode: "all",
        resolver: yupResolver(createCustomerValidationSchema),
    });

    const getEditData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await customerService.show(Number(id));
            const { state, firstName, lastName, email, phoneNumber } = response.data.data;

            reset({ firstName, lastName, email, phoneNumber });
            setCustomerState(state);
            setLoading(false);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
            setLoading(false);
        }
    }, [id, reset]);

    const onSubmit = async (_val: ICustomerCreateFormType) => {
        try {
            setSubmitLoading(true);
            const { phoneNumber, ...payload } = _val;

            await customerService.update(Number(id), payload);

            enqueueSnackbar("Customer updated successfully", { variant: "success" });
            navigate(ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";

            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleBlock = async (id: number, checked: boolean) => {
        const handleAction = async () => {
            try {
                setCustomerStateLoading(true);
                await customerService.block(id, { active: !checked });
                setCustomerState(checked ? "Disable" : "Enable");
                enqueueSnackbar(`Customer ${checked ? "blocked" : "unblocked"} successfully`, { variant: "success" });
            } catch (err) {
                const error = getAxiosError(err);
                const message = error?.meta.message || "Server Error";

                enqueueSnackbar(message, { variant: "error" });
            } finally {
                setCustomerStateLoading(false);
            }
        };

        if (checked) {
            modalActions.addModal(MODAL_TYPES.CONFIRMATION_MODAL, {
                header: "Block Confirmation",
                text: "Are you sure to block this customer?",
                handleAction,
            });
        } else {
            handleAction();
        }
    };

    useEffect(() => {
        hasEditId && getEditData();
    }, [hasEditId, getEditData]);

    const footer = (
        <Stack direction={"row"} paddingBottom={10} spacing={2}>
            <LoadingButton disabled={submitLoading} loading={submitLoading} type="submit" variant="contained">
                Update Customer
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title="Update Customer"
                titleSuffix={
                    <Stack direction={"row"} spacing={2}>
                        {!loading ? (
                            <LoadingButton
                                color={isBlocked ? "primary" : "error"}
                                disabled={customerStateLoading}
                                loading={customerStateLoading}
                                variant="contained"
                                onClick={() => handleBlock(Number(id), !isBlocked)}
                            >
                                {isBlocked ? "Unblock" : "Block"} Customer
                            </LoadingButton>
                        ) : null}
                        {listAccess ? (
                            <Button component={Link} to={ROUTE_CONSTANTS.USER_MANAGEMENT.CUSTOMERS.ROOT.ABSOLUTE}>
                                Back To Customer List
                            </Button>
                        ) : null}
                    </Stack>
                }
            />
            <DashboardContent>
                <FormGenerator
                    control={control}
                    errors={errors}
                    footer={footer}
                    loading={loading}
                    schema={customerSchema}
                    onSubmit={handleSubmit(onSubmit)}
                />
            </DashboardContent>
        </>
    );
};

export default CustomerForm;
