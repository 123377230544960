import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import GeneralLayoutWrapper from "../GeneralLayoutWrapper/index";
import { useUser } from "@/context/authContext";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { AuthFormStyled, AuthImageStyled, AuthLogoStyled, AuthNizekLogoStyled } from "./styled";
import LogoWhite from "@/components/app/LogoWhite";
import LogoBlue from "@/components/app/LogoBlue";
import { useThemeStore } from "@/context/theme";
import NizekLogo from "@/assets/svg/nizek-logo";

const AuthLayoutContent: React.FC = () => {
    const user = useUser();
    const { theme } = useThemeStore();

    if (user) {
        return <Navigate to={ROUTE_CONSTANTS.DASHBOARD.ROOT.ABSOLUTE} />;
    }

    return (
        <>
            <AuthImageStyled>
                <img
                    src="https://images.unsplash.com/photo-1615961766467-198457a6c72f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt="login"
                />
            </AuthImageStyled>
            <AuthFormStyled>
                <AuthLogoStyled>{theme === "dark" ? <LogoWhite /> : <LogoBlue />}</AuthLogoStyled>
                <Outlet />
                <AuthNizekLogoStyled>
                    <span>With ❤️ By</span>
                    <a href="https://nizek.com" target="_blank">
                        <NizekLogo height={12} />
                    </a>
                </AuthNizekLogoStyled>
            </AuthFormStyled>
        </>
    );
};

const AuthLayout: React.FC = () => {
    return (
        <GeneralLayoutWrapper>
            <AuthLayoutContent />
        </GeneralLayoutWrapper>
    );
};

export default AuthLayout;
