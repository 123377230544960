import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type VideoFormSchemaFields = "videoId" | "thumbnailId" | "title" | "description";

export type IVideoFormSchema = Record<VideoFormSchemaFields, IFormGeneratorSchemaType>;

export const videoSchema: IVideoFormSchema = {
    videoId: {
        label: "Video File",
        placeholder: "Video File",
        type: FORM_INPUTS.VIDEO,
        props: {
            required: true,
            accept: "video/*",
        },
    },
    thumbnailId: {
        label: "Thumbnail",
        placeholder: "(Max Size: 3Mb)",
        type: FORM_INPUTS.SELECT_IMAGE,
        props: {
            required: true,
            accept: "image/*",
        },
    },
    title: {
        label: "Title",
        placeholder: "Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
    description: {
        label: "Description",
        placeholder: "Description",
        type: FORM_INPUTS.TEXTAREA,
        props: {
            required: true,
        },
    },
};
