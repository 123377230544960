import { styled } from "@mui/material";

export default styled("div")`

    display: flex;
    flex-direction: column;
    width: 100%;

    .plans-header {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1em;

        .main-title {
            font-weight: bold;
            font-size: 2em;
        }

        .sub-title {
            font-weight: bold;
            font-size: 1em;
        }
    }

    .form {
        margin-top:1em;
    }
`;
