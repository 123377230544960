import { IUserListResponse } from "@/api/types/user";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const userListColumnSchema: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "firstName",
        headerName: "First Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
    },
    {
        field: "emailVerified",
        headerName: "Email Verified",
        width: 200,
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        headerAlign: "center",
        align: "center",
        sortable: false,
        display: "flex",
        renderCell(params: GridRenderCellParams<IUserListResponse>) {
            return (
                <Chip
                    label={params.row.emailVerified ? "Verified" : "Unverified"}
                    color={params.row.emailVerified ? "success" : "error"}
                    icon={params.row.emailVerified ? <CheckCircleIcon /> : <CancelIcon />}
                    size="small"
                    variant="outlined"
                />
            );
        },
    },
    {
        field: "role",
        headerName: "Role",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IUserListResponse>) {
            return <Chip label={params.row.role} color="default" size="small" variant="outlined" />;
        },
    },
    {
        field: "updatedAt",
        headerName: "Updated At",
        filterable: false,
        hideable: false,
        resizable: false,
        flex: 1,
        sortable: false,
        renderCell(params: GridRenderCellParams<IUserListResponse>) {
            return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
    },
];
