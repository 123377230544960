import { styled } from "@mui/material";

export const ModalWrapperStyled = styled("div")<{ width?: string }>(
    ({ theme, width }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${width || "500px"};
    max-width: 100%;
    background: ${theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.common.white};
    box-shadow: ${theme.shadows[24]};
    border-radius: ${theme.spacing(2)};
`
);

export const ModalHeaderStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.palette.mode === "dark" ? "#494949" : "#eee"};
    h5 {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
    }
    svg {
        margin-left: auto;
        cursor: pointer
    }
`
);
export const ModalContentStyled = styled("div")(
    ({ theme }) => `
    padding: ${theme.spacing(2)};
    max-height: calc(100vh - 200px);
    overflow: auto;
    > p {
        margin-top: 0;
    }
`
);
