import React, { useCallback, useEffect, useState } from "react";
import { getAxiosError } from "@/utils/get-axios-error";
import { ISortAndFiltersContext, ISortAndFiltersContextProps } from "./types";
import { enqueueSnackbar } from "notistack";
import { getListQueryItems } from "@/utils/get-list-query-item";
import { baseService } from "@/api/services/base";
import { contextFactory } from "@/utils/context-factory";
import { FilterType, IListItem, ISortOrFilter } from "@/api/types/base-data";

export const [SortAndFiltersContext, useSortAndFilters] = contextFactory<ISortAndFiltersContext>("SortAndFiltersContext", "useSortAndFilters");

export const SortAndFiltersProvider: React.FC<ISortAndFiltersContextProps> = ({ children, listName, onChange, ignoreFilterItem }) => {
    const [activeSortItem, setActiveSortItem] = useState<IListItem>();
    const [filtersIsOpen, setFiltersIsOpen] = useState<boolean>(false);
    const [sortItems, setSortItems] = useState<IListItem[]>([]);
    const [filterItems, setFilterItems] = useState<ISortOrFilter[]>([]);
    const [filters, setFilters] = useState<Record<string, FilterType>>();
    const [searchQuery, setSearchQuery] = useState("");

    const getData = useCallback(async () => {
        try {
            if (!listName) return;
            const response = await baseService.getListInfoByEntity(listName);
            const result = response.data.data;
            if (result.info.filters) {
                const tempFilterItems = [...result.info.filters];
                setFilterItems(tempFilterItems);
            }
        } catch (err) {
            const error = getAxiosError(err);
            enqueueSnackbar(error.meta?.message!, { variant: "error" });
        }
    }, [listName, ignoreFilterItem]);

    useEffect(() => {
        getData();
    }, [getData]);

    const applyChanges = (incomingData?: Record<string, FilterType>) => {
        const tempFilters = !!incomingData ? incomingData : filters || {};
        const data = getListQueryItems(tempFilters);
        if (searchQuery) {
            data.search = searchQuery;
        }
        onChange(data);
    };

    const resetAll = () => {
        setFilters({});
        setFilterItems((prevItems) =>
            prevItems.map((item) => ({
                ...item,
                selectedValue: "",
            }))
        );
        const data = getListQueryItems({});
        onChange(data);
    };
    const resetSearch = () => {
        const tempFilters = filters || {};
        const data = getListQueryItems(tempFilters);
        onChange(data);
    };

    return (
        <SortAndFiltersContext.Provider
            value={{
                activeSortItem,
                setActiveSortItem,
                filtersIsOpen,
                setFiltersIsOpen,
                filterItems,
                setFilterItems,
                filters,
                setFilters,
                searchQuery,
                setSearchQuery,
                applyChanges,
                resetAll,
                resetSearch,
            }}
        >
            {children}
        </SortAndFiltersContext.Provider>
    );
};
