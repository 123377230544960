import ShowCountriesStyle from "./style";
import ShowCountriesTable from "./table";

import { useGetCountries } from "@/api/hooks/useCountries";

export default function ShowCountries() {
    const { countries } = useGetCountries();

    return (
        <ShowCountriesStyle className="countries-wrapper">
            <h1>Countries </h1>
            <ShowCountriesTable list={countries} />
        </ShowCountriesStyle>
    );
}
