import { FormControl } from "@mui/material";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";

interface IProps {
    placeholder: string;
    className?: string;
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    errorMessage: FieldError;
}
const FieldSet = ({ placeholder, className }: IProps) => (
    <FormControl fullWidth variant="outlined" className={className} margin="normal">
        <h4>{placeholder}</h4>
    </FormControl>
);

export default FieldSet;
