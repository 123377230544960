import { styled } from "@mui/material";

export default styled("div")(
    () => `
    width: 100%;
    display: flex;
    flex-direction: column;

    .page-header {
        display: flex;
        flex-direction: rtl;
        align-items:center;
        justify-content: space-between;

        .back {
            max-height: 45px;
        }
    }


    .page-content {
        max-width: 960px;
        margin: 0 auto;
        width: 100%;
        margin-bottom: 3em;
    }
`
);
