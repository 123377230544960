import { arabicCharactersRegex } from "@/constants/regexes";
import { number, object, string } from "yup";

export const createGenreValidationSchema = object().shape({
    en_title: string()
        .required("English title is required")
        .min(3, "English title must be at most 3 characters")
        .max(80, "English title must be at least 80 characters"),
    ar_title: string()
        .required("Arabic title is required")
        .min(3, "Arabic title must be at most 3 characters")
        .max(80, "Arabic title must be at least 80 characters")
        .matches(arabicCharactersRegex, "Arabic title must only accept Arabic characters"),
    en_description: string()
        .required("English description is required")
        .min(3, "English description must be at most 3 characters")
        .max(500, "English description must be at least 500 characters"),
    ar_description: string()
        .required("Arabic description is required")
        .min(3, "Arabic description must be at most 3 characters")
        .max(500, "Arabic description must be at least 500 characters"),
    category: number().required("Category is required"),
    slug: string().min(3, "Slug must be at most 3 characters").max(255, "Slug must be at least 255 characters").required("Slug is required"),
});
