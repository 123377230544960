import { Box, Typography, Button, Avatar } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import type { ReactNode } from "react";

interface ResultProps {
    status?: "success" | "error" | "info";
    title: string;
    subTitle?: string;
    extra?: ReactNode;
}

const ErrorPage = ({ status, title, subTitle, extra }: ResultProps) => {
    const iconProps = {
        success: { icon: <CheckCircleOutlineIcon fontSize="large" />, color: "success.main" },
        error: { icon: <ErrorOutlineIcon fontSize="large" />, color: "error.main" },
        info: { icon: <InfoOutlinedIcon fontSize="large" />, color: "info.main" },
    }[status || "info"];

    return (
        <Box textAlign="center" p={4}>
            <Avatar sx={{ bgcolor: iconProps.color, mx: "auto", mb: 2 }}>{iconProps.icon}</Avatar>
            <Typography variant="h5" gutterBottom>
                {title}
            </Typography>
            {subTitle && (
                <Typography variant="body1" color="text.secondary" gutterBottom>
                    {subTitle}
                </Typography>
            )}
            {extra && <Box mt={2}>{extra}</Box>}
        </Box>
    );
};

export default ErrorPage;
