import DashboardContent from "@/components/app/DashboardContent";
import DashboardHeader from "@/components/app/DashboardHeader";
import FormGenerator from "@/components/kit/FormGenerator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createTagValidationSchema } from "./validation";
import { ITagCreateFormType } from "./form-type";
import { tagService } from "@/api/services/tag";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAxiosError } from "@/utils/get-axios-error";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { enqueueSnackbar } from "notistack";
import { generateDefaultValueFromFormSchema } from "@/utils/generate-default-value-from-form-schema";
import { IParams } from "@/types/params";
import { tagSchema } from "./schema";
import { slugify } from "@/utils/slugify";
import { useCheckPermissionAccess } from "@/hooks/useCheckPermissionAccess";
import { PERMISSIONS } from "@/enums/permissions";
import { CATEGORY_TYPE } from "@/enums/category-type";

const TagForm: React.FC = () => {
    const { id } = useParams<IParams>();
    const isEditingRule = Boolean(id);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(isEditingRule);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const { checkPermissionAccess } = useCheckPermissionAccess();
    const listAccess = checkPermissionAccess(PERMISSIONS.TAG_LIST);

    const {
        control,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm<ITagCreateFormType>({
        defaultValues: generateDefaultValueFromFormSchema(tagSchema),
        mode: "all",
        resolver: yupResolver(createTagValidationSchema),
    });

    const getEditData = async () => {
        try {
            setLoading(true);
            const response = await tagService.show(Number(id));
            const { ml_title, slug, isEntertainment } = response.data.data;
            reset({ en_title: ml_title.en, ar_title: ml_title.ar, slug, category: isEntertainment ? CATEGORY_TYPE.ENTERTAINMENT : CATEGORY_TYPE.EDUCATION });
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async (_val: ITagCreateFormType) => {
        try {
            setSubmitLoading(true);
            const payload = {
                ml_title: { en: _val.en_title, ar: _val.ar_title },
                slug: _val.slug,
                ...(_val.category ? { isEducation: _val.category === CATEGORY_TYPE.EDUCATION } : undefined),
                ...(_val.category ? { isEntertainment: _val.category === CATEGORY_TYPE.ENTERTAINMENT } : undefined),
            };
            if (isEditingRule) {
                await tagService.update(Number(id), payload);
            } else {
                await tagService.create(payload);
            }
            enqueueSnackbar(`${isEditingRule ? "Tag updated" : "New tag created"} successfully`, { variant: "success" });
            navigate(ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ROOT.ABSOLUTE);
        } catch (err) {
            const error = getAxiosError(err);
            const message = error?.meta.message || "Server Error";
            enqueueSnackbar(message, { variant: "error" });
        } finally {
            setSubmitLoading(false);
        }
    };

    useEffect(() => {
        isEditingRule && getEditData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditingRule]);

    useEffect(() => {
        const subscription = watch((values, event) => {
            if (event.name === "en_title") {
                setValue("slug", slugify(values.en_title || ""));
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const footer = (
        <Stack spacing={2} direction={"row"} paddingBottom={10}>
            <LoadingButton type="submit" disabled={!isValid || submitLoading} loading={submitLoading} variant="contained">
                {isEditingRule ? "Update" : "Create"} Tag
            </LoadingButton>
            <Button type="button" onClick={() => navigate(-1)}>
                Cancel
            </Button>
        </Stack>
    );

    return (
        <>
            <DashboardHeader
                title={`${isEditingRule ? "Update" : "Create New"} Tag`}
                {...(listAccess
                    ? {
                          titleSuffix: (
                              <Button component={Link} to={ROUTE_CONSTANTS.CONTENT_MANAGEMENT.TAGS.ROOT.ABSOLUTE}>
                                  Back To Tag List
                              </Button>
                          ),
                      }
                    : undefined)}
            />
            <DashboardContent>
                <FormGenerator schema={tagSchema} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} loading={loading} footer={footer} />
            </DashboardContent>
        </>
    );
};

export default TagForm;
