import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { ImageFieldSchema } from "@/types/form-generator-schema-type";
import {
    DeleteIconStyled,
    FormImageInputStyled,
    FormImageStyled,
    FormImageUploadStyled,
    FormImageWrapperStyled,
} from "./styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useRef, useState } from "react";

interface IProps {
    field: ControllerRenderProps<FieldValues, string>;
    label?: string;
    placeholder: string;
    className?: string;
    errorMessage: FieldError;
    props?: ImageFieldSchema["props"];
}
const FormImage = ({ field, label, placeholder, className, errorMessage, props = {} }: IProps) => {
    const { previewImage, previewSize, previewName, ...inputProps } = props || {};
    const inputFileRef = useRef<HTMLInputElement>(null);

    const [imageSource, setImageSource] = useState(previewImage || "");
    const [fileName, setFileName] = useState<string>(previewName || "");
    const [fileSize, setFileSize] = useState<number | undefined>(previewSize || undefined);

    const isImageInput = props?.accept && props.accept === "image/*";

    const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setFileName(event.target.files[0].name);
            setFileSize(event.target.files[0].size);
            field.onChange(event.target.files[0]);
            isImageInput && setImageSource(URL.createObjectURL(event.target.files[0]));
        }
    };

    const handleRemove = () => {
        setFileName("");
        setFileSize(undefined);
        field.onChange(undefined);
        isImageInput && setImageSource("");
        if (inputFileRef.current) {
            inputFileRef.current.value = "";
        }
    };

    return (
        <>
            <FormImageStyled className={className}>
                <label>
                    {label} {props?.required ? "*" : ""}
                </label>
                <FormImageWrapperStyled>
                    {isImageInput && imageSource?.length ? <img src={imageSource} alt={label} /> : null}
                    {field.value ? <DeleteIconStyled onClick={handleRemove} /> : null}
                    {!fileName?.length ? (
                        <FormImageUploadStyled>
                            <CloudUploadIcon />
                            <span>Choose File ...</span>
                            {placeholder ? <small>{placeholder}</small> : null}
                        </FormImageUploadStyled>
                    ) : null}
                    <FormImageInputStyled type="file" ref={inputFileRef} onChange={handleOnChange} {...inputProps} />
                </FormImageWrapperStyled>
                {fileName || fileSize ? (
                    <span>
                        {fileName?.length ? fileName : null}
                        {fileSize ? <small>({Math.ceil(fileSize / 1024)} Kb)</small> : null}
                    </span>
                ) : null}
            </FormImageStyled>
            {errorMessage?.message ? <FormHelperText error>{errorMessage?.message}</FormHelperText> : null}
        </>
    );
};

export default FormImage;
