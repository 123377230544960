import { alpha, styled } from "@mui/material";

export const AuthImageStyled = styled("div")(
    ({ theme }) => `
    height: 100vh;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
    }
    @media (max-width: 900px) {
        display: none;
    }
`
);
export const AuthLogoStyled = styled("div")(
    ({ theme }) => `
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacing(12)};

    svg {
        width: 150px;
    }
`
);

export const AuthFormStyled = styled("div")(
    ({ theme }) => `
    max-width: 450px;
    width: 100%;
    position: absolute;
    right: ${theme.spacing(3)};
    top: ${theme.spacing(3)};
    bottom: ${theme.spacing(3)};
    padding: ${theme.spacing(4)};
    background: ${theme.palette.mode === "dark" ? alpha(theme.palette.common.black, 0.75) : alpha(theme.palette.common.white, 0.75)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: ${theme.spacing(2)};
    margin: 0 auto;
    box-shadow: ${theme.palette.mode === "dark" ? "0px 0px 32px 0px rgba(0, 0, 0, 0.3)" : "0px 0px 32px 0px rgba(255, 255, 255, 0.3)"};
    backdrop-filter: blur(5px);
    color: ${theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black};
    
    h1 {
        margin: 0 0 ${theme.spacing(1)};
        text-align: center;
    }
    >p {
        margin: 0 0 ${theme.spacing(6)};
        text-align: center;
    }
`
);

export const AuthNizekLogoStyled = styled("div")(
    ({ theme }) => `
    margin-top: auto;
    color: ${theme.palette.mode === "dark" ? "#fff" : "#000"};
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    gap: ${theme.spacing(1)};
    a {
        color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)"};
        transition: 0.15s;
        &:hover {
            color: ${theme.palette.mode === "dark" ? "#fff" : "#000"};
        }
    }

    &.minimize {
        text-align: center;
        span {
            font-size: 8px
        }
    }
`
);
