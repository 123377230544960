import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ForbiddenStyled } from "./styled";

const Forbidden = () => {
    const navigate = useNavigate();

    return (
        <ForbiddenStyled>
            <span>Oops!</span>
            <h1>403</h1>
            <h3>You Don't have access</h3>
            <Button onClick={() => navigate(-1)}>Go Back</Button>
        </ForbiddenStyled>
    );
};
export default Forbidden;
