import { IFormGeneratorSchemaType } from "@/types/form-generator-schema-type";
import { FORM_INPUTS } from "@/types/form-inputs";

export type AvatarFormSchemaFields = "avatar" | "title";

export type IAvatarFormSchema = Record<AvatarFormSchemaFields, IFormGeneratorSchemaType>;

export const avatarSchema: IAvatarFormSchema = {
    avatar: {
        label: "Avatar",
        placeholder: "(Max Size: 3Mb)",
        type: FORM_INPUTS.IMAGE,
        props: {
            required: true,
            accept: "image/*",
        },
    },
    title: {
        label: "Title",
        placeholder: "Title",
        type: FORM_INPUTS.TEXT,
        props: {
            required: true,
        },
    },
};
