export const globalStyle = {
    ul: {
        padding: 0,
        margin: 0,
        listStyle: "none",
    },
    a: {
        textDecoration: "none",
    },
};
