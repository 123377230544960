import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { errorCallback } from "./error";

import { IPublisherListReqBody, IPublisher } from "@/api/types/publisher";
import {
    GetPublishersList,
    GetPublisherById,
    UpdatePublisherById,
    DeletePublisherById,
    CreatePublisherById,
} from "@/api/services/publisher";

interface IMutationProps {
    lang: string;
    body: IPublisherListReqBody;
}

export function usePublisherList({ lang, body }: IMutationProps) {
    const [publishers, setPublisher] = useState<IPublisher[]>([]);
    const [hasMore, setHasMore] = useState(true);

    // fetch the lang from somewhere

    const { mutate, ...restProps } = useMutation({
        mutationFn: ({ lang, body }: IMutationProps) => GetPublishersList(lang, body),
        onError: errorCallback,
        onSuccess: (result) => {
            const { items } = result.data;

            setPublisher(items);

            // if (items.length === 0) setHasMore(false);

            // setPublisher((prev) => {
            //     if (items.length > 0) {
            //         return [...prev, ...items];
            //     } else {
            //         return prev;
            //     }
            // });
        },
    });

    useEffect(() => {
        hasMore && mutate({ lang, body } as IMutationProps, {});
    }, [body, lang, mutate, hasMore]);

    return { publishers, mutate, hasMore, ...restProps };
}

export function usePublisherById(id: number, lang: string) {
    return useQuery({
        enabled: !!id,
        queryKey: ["Publisher", id],
        queryFn: () => GetPublisherById(Number(id), lang),
    });
}

export function useUpdatePublisher() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, body, lang }: { id: number, lang: string, body: { title: string } }) =>
            UpdatePublisherById(id, lang, body),
        onError: errorCallback,
        onSuccess: (data, { id }) => {
            enqueueSnackbar(`${data.data.title} Updated Successfully`, { variant: "success" });
            queryClient.setQueryData(["Publisher", id], data);
        },
    });
}

export function useDeletePublisher() {
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ id, lang }: { id: number, lang: string }) => DeletePublisherById(id, lang),
        onError: errorCallback,
        onSuccess: (data, id) => {
            enqueueSnackbar(`${data.data.result} Updated Successfully`, { variant: "success" });
            queryClient.invalidateQueries({ queryKey: ["Publisher", id] });
        },
    });
}

export function useCreatePublisher() {
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: ({ body, lang }: { lang: string, body: { title: string } }) => CreatePublisherById(lang, body),
        onError: errorCallback,
        onSuccess: (data) => {
            enqueueSnackbar(`${data.data.title} Create Successfully`, { variant: "success" });
        },
    });
}
