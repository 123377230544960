import { Navigate, Outlet } from "react-router-dom";
import GeneralLayoutWrapper from "@/layouts/GeneralLayoutWrapper/index";
import ROUTE_CONSTANTS from "@/constants/route-constants";
import { useUser } from "@/context/authContext";
import ModalCreator from "@/components/app/Modal/ModalCreator";
import Sidebar from "@/layouts/parts/Sidebar";
import Header from "@/layouts/parts/Header";
import { ContentWrapperStyled, MainContentWrapperStyled, MainWrapperStyled } from "./styled";
import { useMenuView } from "@/context/menu";
import { MENU_VIEW } from "@/enums/menu";

const DashboardLayoutContent: React.FC = () => {
    const user = useUser();
    const menuView = useMenuView();

    if (!user) {
        return <Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.ABSOLUTE} />;
    }

    return (
        <MainWrapperStyled className={menuView === MENU_VIEW.MINIMIZE ? "minimizeMenu" : ""}>
            <Sidebar />
            <MainContentWrapperStyled>
                <Header />
                <ContentWrapperStyled>
                    <Outlet />
                </ContentWrapperStyled>
                <ModalCreator />
            </MainContentWrapperStyled>
        </MainWrapperStyled>
    );
};

const DashboardLayout: React.FC = () => {
    return (
        <GeneralLayoutWrapper>
            <DashboardLayoutContent />
        </GeneralLayoutWrapper>
    );
};

export default DashboardLayout;
