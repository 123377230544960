import React, { memo, useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "@hello-pangea/dnd";

import { Container, DraggableItem, DraggableListStyled } from "./style";

export interface ItemType<T> {
    id: string;
    content: React.ReactNode;
    data: T;
}

interface DraggableListProps<T> {
    initialItems: ItemType<T>[];
    // eslint-disable-next-line no-unused-vars
    onOrderChanged?: (newOrder: ItemType<T>[]) => void;
}

function DraggableList<T>(props: DraggableListProps<T>) {
    const { initialItems, onOrderChanged } = props;
    const [items, setItems] = useState<ItemType<T>[]>(initialItems);

    const handleOnDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);

        newItems.splice(result.destination.index, 0, removed);

        setItems(newItems);

        if (onOrderChanged) {
            onOrderChanged(newItems);
        }
    };

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    return (
        <Container>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppable-list">
                    {(provided) => (
                        <DraggableListStyled className="draggable-list" {...provided.droppableProps} ref={provided.innerRef}>
                            {items.map((itemData, index) => {
                                if (!itemData?.id) return null;

                                return (
                                    <Draggable key={itemData.id} draggableId={itemData.id} index={index}>
                                        {(provided, snapshot) => (
                                            <DraggableItem
                                                ref={provided.innerRef}
                                                className={`draggable-item ${snapshot.isDragging ? "dragging" : ""}`}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                {itemData.content}
                                            </DraggableItem>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </DraggableListStyled>
                    )}
                </Droppable>
            </DragDropContext>
        </Container>
    );
}

// eslint-disable-next-line no-unused-vars
const MemoizedDraggableList = memo(DraggableList) as <T>(props: DraggableListProps<T>) => JSX.Element;

export default MemoizedDraggableList;
